<template>
  <button
    type="button"
    @click="goBack()"
    class="flex items-center gap-4 my-6 cursor-pointer"
  >
    <div class="bg-indigo-600 px-5 py-3 text-white rounded-lg text-center">
      Zurück
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'GoBackButton',
  methods: {
    goBack() {
      if (this.$route.query.search || this.$route.query.series_id) {
        return this.$router.go(-2)
      }
      return this.$router.go(-1)
    }
  }
})
</script>

<style scoped></style>
