import axios from 'axios'
import { SiteDescription } from '@/interfaces/SiteDescription'

class SitesDescriptionService {
  async getSitesDescriptionData() {
    return await axios.get(`api/site_description`)
  }

  async getDescriptionData(id: number) {
    return await axios.get(`api/site_description/${id}`)
  }
  async createSiteDescription(sitesDescription: Array<SiteDescription>) {
    return await axios.post(`api/site_description`, sitesDescription)
  }

  async updateSiteDescription(
    id: number,
    sitesDescription: Array<SiteDescription>
  ) {
    console.log(sitesDescription)
    return await axios.put(`api/site_description/${id}`, sitesDescription)
  }

  async deleteSiteDescriptionData(id: number) {
    return await axios.delete(`api/site_description/${id}`)
  }
}

export const sitesDescriptionService = new SitesDescriptionService()
