<template>
  <div class="flex justify-center items-center h-screen bg-gray-200 px-6">
    <div class="container mx-auto px-4">
      <section class="py-8 px-4 text-center">
        <div class="max-w-auto mx-auto">
          <div class="md:max-w-lg mx-auto text-9xl font-black text-gray-400">
            403
          </div>
          <h2
            class="mt-8 uppercase text-xl lg:text-5xl font-black text-gray-400"
          >
            We are sorry, but you do not have access here!
          </h2>
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Forbidden'
})
</script>

<style scoped></style>
