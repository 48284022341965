<template>
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="bg-gray-50">
      <tr>
        <th
          v-for="(column, index) in columns"
          :key="index"
          scope="col"
          class=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:bg-indigo-200"
        >
          <div class="inline-flex flex-wrap w-full">
            <div class="inline-block text-right mr-3 mt-1 left-0">
              {{ column.label }}
            </div>
          </div>
        </th>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:bg-indigo-200"
        >
          <div class="inline-block text-right mr-3 mt-1 left-0">
            Edit
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <tr
        v-for="(row, index) in tableData"
        :key="index"
        class="hover:bg-indigo-200"
      >
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            <div class="w-45 rounded-md">
              <img v-if="row.logo" :src="row.logo" />
              <div v-else>
                <span
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-900 text-white"
                >
                  N.A</span
                >
              </div>
            </div>
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            <div class="w-45 rounded-md">
              <img v-if="row.preview_image" :src="row.preview_image" />
              <div v-else>
                <span
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-900 text-white"
                >
                  N.A</span
                >
              </div>
            </div>
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center truncate">
            {{ this.truncateTableString(row.title, 25) }} ...
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center truncate"></div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            {{ row.content_resolution }}
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            {{ row.sort_order }}
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            {{ row.type }}
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            <div v-if="row.series_descriptions.length > 0">
              <span
                v-for="(description, index) in row.series_descriptions"
                :key="index"
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
              >
                <router-link
                  :to="{
                    name: 'editSeriesDescription',
                    params: { series_description_id: description.id }
                  }"
                >
                  {{ description.language }}
                </router-link>
              </span>
            </div>
            <div v-else>
              <span
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-900 text-white"
              >
                N.A</span
              >
            </div>
          </div>
        </td>
        <td class="flex-col px-6 py-4 text-right text-sm font-medium">
          <div>
            <router-link
              :to="{ name: 'editSeries', params: { series_id: row.id } }"
              class="text-indigo-600 hover:text-indigo-900 mr-3"
              >Bearbeiten
            </router-link>
          </div>
          <div>
            <a
              href="#"
              class="text-red-600 hover:text-indigo-900 mr-3"
              @click.prevent="this.$parent.deleteSeries(row.id)"
              >Löschen</a
            >
          </div>
          <div>
            <router-link
              :to="{
                name: 'addSeriesDescription',
                params: { series_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900 mr-3"
              >Beschreibung</router-link
            >
          </div>
          <div>
            <router-link
              :to="{
                name: 'seriesLogoUpload',
                params: { series_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900 mr-3"
              >Logo upload</router-link
            >
          </div>
          <div>
            <router-link
              :to="{
                name: 'seriesPreviewUpload',
                params: { series_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900 mr-3"
              >Vorschaubild</router-link
            >
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import truncateTableString from '@/filters/truncateTableString'

export default defineComponent({
  name: 'SeriesTable',
  props: {
    tableData: Object,
    columns: Array
  },
  methods: {
    truncateTableString
  }
})
</script>

<style scoped></style>
