
import { defineComponent, ref, onBeforeMount, PropType } from 'vue'
import PaymentPackages from '@/interfaces/PaymentPackages'
export default defineComponent({
  name: 'PaymentPackagesTable',
  props: {
    paymentPackagesData: {
      type: Object as PropType<PaymentPackages>
    },
    columns: {
      type: Array
    }
  }
})
