
import { defineComponent } from 'vue'
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import AddModelForm from '@/components/AddModelForm.vue'
export default defineComponent({
  name: 'AddModel',
  components: { ConfirmDialogue, AddModelForm },
  data() {
    return {
      modelId: null
    }
  }
})
