<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <SeriesDataForm />
</template>

<script lang="ts">
import { defineComponent, watchEffect } from 'vue'

import LoadingSpinner from '../components/lib/LoadingSpinner.vue'

import { seriesService } from '@/services/Series.service'
import SeriesDataForm from '@/components/SeriesDataForm.vue'

export default defineComponent({
  name: 'SeriesData',
  components: {
    SeriesDataForm,
    LoadingSpinner
  },
  data() {
    return {
      series: null
    }
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    watchEffect(() => {
      this.getSeriesData()
    })
  },
  methods: {
    async getSeriesData(id: number) {
      await seriesService
        .getASeriesData(id)
        .then(response => {
          this.series = response.data
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
})
</script>

<style scoped></style>
