
import { defineComponent, ref } from 'vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import GoBackButton from './GoBackButton.vue'
import { ModelsData } from '@/interfaces/ModelsData'
import { modelsDataService } from '@/services/ModelsData.service'
import Alerts from '@/components/lib/Alerts.vue'
import Multiselect from '@vueform/multiselect'
import { ContentSetsSelect } from '@/interfaces/ContentSetsSelect'
import SaveButton from './SaveButton.vue'
import { ModelContentsSetsData } from '@/interfaces/ModelContentsSetsData'

export default defineComponent({
  name: 'ModelContentDataForm',
  components: {
    Multiselect,
    GoBackButton,
    SaveButton,
    LoadingSpinner,
    Alerts
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  data() {
    const model_id: number = null
    const imageSetsToSelect: ContentSetsSelect[] = []
    const videoSetsToSelect: ContentSetsSelect[] = []
    return {
      model_id,
      model_data: {} as ModelsData,
      alertType: null,
      imageSets: null,
      openTab: 1,
      imageSetsToSelect,
      videoSetsToSelect,
      modelContentSetsData: {} as ModelContentsSetsData
    }
  },
  created() {
    this.model_id = this.$route.params.model_id
    this.getModelData(this.model_id)
    this.getContentDataForModel()
  },
  methods: {
    toggleTabs(tabNumber: number) {
      this.openTab = tabNumber
    },
    async getModelData(model_id: number) {
      await modelsDataService
        .getModel(model_id)
        .then(response => {
          this.model_data = ref<ModelsData>({
            model_preview_images: response.data.data.actors_preview_images,
            slug: response.data.data.slug,
            alias: response.data.data.alias,
            firstname: response.data.data.firstname,
            lastname: response.data.data.lastname,
            gender: response.data.data.gender,
            age: response.data.data.age,
            nationality: response.data.data.nationality,
            height: response.data.data.height,
            cup_size: response.data.data.cup_size,
            weight: response.data.data.weight,
            zodiac: response.data.data.zodiac,
            type: response.data.data.type,
            genital_area: response.data.data.genital_area,
            preferences: response.data.data.preferences
          })
          const videoSetsExists: string[] = []
          const imageSetsExists: string[] = []
          for (const contentData of response.data.data.actors_content) {
            if (contentData.content_type === 'videos_sets') {
              videoSetsExists.push(contentData.content_id)
            }
            if (contentData.content_type === 'images_sets') {
              imageSetsExists.push(contentData.content_id)
            }
          }
          this.modelContentSetsData.videos_sets = videoSetsExists
          this.modelContentSetsData.images_sets = imageSetsExists
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },
    async getContentDataForModel() {
      await modelsDataService
        .getContentToActorData()
        .then(response => {
          for (const imageSet of response.data.data.image_sets) {
            this.imageSetsToSelect.push({
              value: imageSet.image_set_id.toString(),
              label: imageSet.set_name
            })
          }
          for (const videoSet of response.data.data.video_sets) {
            this.videoSetsToSelect.push({
              value: videoSet.videos_sets_id.toString(),
              label: videoSet.set_name
            })
          }
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
        })
    },
    async saveModelContentSets() {
      if (this.model_id) {
        this.$refs.loadingSpinner.open()
        await modelsDataService
          .createModelContentSets(this.model_id, this.modelContentSetsData)
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Model Imagesets erfolgreich gespeichert')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      }
    },

    async imagesSetDeselected(content_id: number) {
      if (content_id) {
        await modelsDataService
          .deleteModelContentSet(this.model_id, content_id, 'images_sets')
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Model Bilder Set erfolgreich entfrent')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      }
    },

    async videoSetDeselected(content_id: number) {
      if (content_id) {
        await modelsDataService
          .deleteModelContentSet(this.model_id, content_id, 'videos_sets')
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Model Video Set erfolgreich entfrent')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      }
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
