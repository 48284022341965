import axios from 'axios'

class ImagesSetsContentService {
  async getImagesSetsContent(imageSetId: number) {
    return await axios.get(`api/images_sets/content/${imageSetId}`)
  }

  async setIsPreviewImage(imagesSetsContent: number, is_preview: boolean) {
    return await axios.put(
      `api/images_sets/content/image/preview/${imagesSetsContent}?is_preview=${is_preview}`
    )
  }

  async deleteImage(imagesSetsContent: number) {
    return await axios.delete(
      `api/images_sets/content/image/${imagesSetsContent}`
    )
  }
}

export const imagesSetsContentService = new ImagesSetsContentService()
