import { Module } from 'vuex'
import axios from 'axios'
import UserState from '@/store/interfaces/UserState'
import UserGetters from '@/store/interfaces/UserGetters'

const auth: Module<UserState, UserGetters> = {
  namespaced: true,
  state: {
    authenticated: false,
    user: null
  },

  getters: {
    authenticated(state) {
      return state.authenticated
    },
    user(state) {
      return state.user
    }
  },

  mutations: {
    SET_AUTHENTICATED(state, value: boolean) {
      state.authenticated = value
    },
    SET_USER(state, value: string) {
      state.user = value
    }
  },

  actions: {
    async login({ dispatch }, credentials: Array<string>) {
      await axios.get('/sanctum/csrf-cookie')
      await axios.post('/api/login', credentials)
      return dispatch('getAuthentication')
    },
    async getAuthentication({ commit }) {
      return axios
        .get('/api/user')
        .then(response => {
          commit('SET_AUTHENTICATED', true)
          commit('SET_USER', response.data)
        })
        .catch(() => {
          commit('SET_AUTHENTICATED', false)
          commit('SET_USER', null)
        })
    },
    async logoutAction({ dispatch }) {
      await axios.post('/api/logout')
      dispatch('getAuthentication')
    }
  }
}

export default auth
