export const languageSelect = [
  {
    code: 'cn',
    name: 'China',
    nativeName: '汉语, 漢語'
  },
  {
    code: 'de',
    name: 'German',
    nativeName: 'Deutsch'
  },
  {
    code: 'en',
    name: 'English',
    nativeName: 'English'
  },
  {
    code: 'fr',
    name: 'French',
    nativeName: 'Français'
  },
  {
    code: 'es',
    name: 'Spanish',
    nativeName: 'español'
  },
  {
    code: 'pl',
    name: 'Polish',
    nativeName: 'polski'
  },
  {
    code: 'it',
    name: 'Italy',
    nativeName: 'Italiano'
  }
]
