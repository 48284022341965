import axios from 'axios'

class DashboardDataService {
  async getActivePayUser() {
    return await axios.get(`api/users_data/get_active_pay_user`)
  }

  async getActiveFreeUser() {
    return await axios.get(`api/users_data/get_active_free_user`)
  }
  async getInactiveUser() {
    return await axios.get(`api/users_data/get_inactive_user`)
  }
  async getCurrentMonthNewPayUser() {
    return await axios.get(`api/users_data/get_current_month_new_pay_user`)
  }
  async getCurrentMonthNewFreeUser() {
    return await axios.get(`api/users_data/get_current_month_new_free_user`)
  }

  async getLastMonthNewPayUser() {
    return await axios.get(`api/users_data/get_last_month_new_pay_user`)
  }

  async getLastMonthNewFreeUser() {
    return await axios.get(`api/users_data/get_last_month_new_free_user`)
  }
  async getLastSevenDaysSum() {
    return await axios.get(`api/transaction_last_seven_days_sum`)
  }

  async getLastMonthSum() {
    return await axios.get(`api/transaction_last_month_sum`)
  }
  async getCurrentMonthSum() {
    return await axios.get(`api/transaction_current_month_sum`)
  }
  async getCurrentDaySum() {
    return await axios.get(`api/transaction_current_day_sum`)
  }
}

export const dashboardDataService = new DashboardDataService()
