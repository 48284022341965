export const nationalitySelect = [
  'american',
  'bulgarian',
  'chinese',
  'czech',
  'german',
  'polish',
  'russian',
  'thai',
  'turkish',
  'ukrainian'
]
