
import { defineComponent } from 'vue'
import GoBackButton from '@/components/GoBackButton.vue'
import ModelPreviewUploader from '@/components/ModelPreviewUploader.vue'
export default defineComponent({
  name: 'ModelPreviewUpload',
  components: {
    GoBackButton,
    ModelPreviewUploader
  },
  data() {
    return {
      model_id: this.$route.params.model_id
    }
  }
})
