
import { defineComponent, watchEffect } from 'vue'

import LoadingSpinner from '../components/lib/LoadingSpinner.vue'
import ConfirmDialogue from '../components/ConfirmDialogue.vue'

import SiteDescriptionTable from '../components/SiteDescriptionTable.vue'
import SiteDescriptionTableHeader from '../components/SiteDescriptionTableHeader.vue'

import { sitesDescriptionService } from '@/services/SitesDescription.service'

export default defineComponent({
  name: 'SiteSettings',
  components: {
    LoadingSpinner,
    SiteDescriptionTableHeader,
    SiteDescriptionTable,
    ConfirmDialogue
  },
  data() {
    return {
      sitesDescription: null,
      columns: [
        {
          key: 'title',
          label: 'Tietel'
        },
        {
          key: 'description',
          label: 'Beschreibung'
        },
        {
          key: 'keywords',
          label: 'Keywords'
        },
        {
          key: 'language',
          label: 'Sprache'
        }
      ]
    }
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    watchEffect(() => {
      this.getSitesSettingsData()
    })
  },
  methods: {
    async getSitesSettingsData() {
      await sitesDescriptionService
        .getSitesDescriptionData()
        .then(response => {
          this.sitesDescription = response.data
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
        })
    },
    async deleteSiteDescription(id: number){
      if(id){
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Seiten Beschreibung löschen',
          message: 'Bist du sicher, dass du die Seiten Beschreibung löschen möchtest.',
          okButton: 'Löschen'
        })
        if (ok) {
          this.$refs.loadingSpinner.open()
          await sitesDescriptionService.deleteSiteDescriptionData(id)
          await this.getSitesSettingsData()
        }
      }
    }
  }
})
