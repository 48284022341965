
import { defineComponent, ref } from 'vue'

import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import { languageSelect } from '@/use/languageSelectData'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'
import { ModelDescription } from '@/interfaces/ModelDescription'
import { modelsDataService } from '@/services/ModelsData.service'
import useVuelidate from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'

export default defineComponent({
  name: 'ModelDescriptionForm',
  components: {
    LoadingSpinner,
    GoBackButton,
    SaveButton,
    Alerts
  },
  created() {
    this.model_id = this.$route.params.model_id
    this.model_description_id = this.$route.params.model_description_id
    if (this.model_description_id) {
      this.getModelDescriptionData(this.model_description_id)
    }
  },
  data() {
    const v$ = useVuelidate()
    const model_description_id: number = null
    const model_id: number = null
    return {
      model_description_id,
      model_id,
      languageSelect,
      alertType: null,
      modelDescriptionFormData: {} as ModelDescription,
      initialFormValues: {} as ModelDescription,
      v$
    }
  },
  methods: {
    async saveModelDescriptionData() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return
      }
      if (!this.model_description_id) {
        await modelsDataService
          .createModelDescription(this.model_id, this.modelDescriptionFormData)
          .then(response => {
            this.showSuccessAlert('Model Beschreibung erfolgreich gespeichert')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      } else {
        await modelsDataService
          .updateModelDescription(
            this.model_description_id,
            this.modelDescriptionFormData
          )
          .then(response => {
            this.showSuccessAlert('Model Beschreibung erfolgreich geändert')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      }
    },

    async getModelDescriptionData(model_description_id: number) {
      await modelsDataService
        .getModelDescription(model_description_id)
        .then(response => {
          this.modelDescriptionFormData = ref<ModelDescription>({
            description: response.data.data.description,
            model_keywords: response.data.data.model_keywords,
            language: response.data.data.language
          })
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  },
  validations() {
    return {
      modelDescriptionFormData: {
        description: { required },
        model_keywords: { required, maxLength: maxLength(254) },
        language: { required }
      }
    }
  }
})
