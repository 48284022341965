import axios from 'axios'
import PaymentPackages from '@/interfaces/PaymentPackages'

class PaymentPackagesService {
  async getPaymentPackages(
    perPage: number = null,
    page: number = null,
    search: string = null,
    sortBy: string = null,
    sortDirection: string = null
  ) {
    if (search) {
      return await axios.get(
        `api/payment_packages?per_page=${perPage}&page=${page}&search=${search}`
      )
    }
    if (sortBy && search) {
      return await axios.get(
        `api/payment_packages?per_page=${perPage}&page=${page}&search=${search}&sortBy=${sortBy}&sortDirection=${sortDirection}`
      )
    }
    if (sortBy && !search) {
      return await axios.get(
        `api/payment_packages?per_page=${perPage}&page=${page}&sortBy=${sortBy}&sortDirection=${sortDirection}`
      )
    }
    return axios.get(`api/payment_packages?per_page=${perPage}&page=${page}`)
  }
  async getPaymentPacketData(id: string | string[]) {
    return await axios.get(`api/payment_packages/${id}`)
  }
  async createPaymentPackage(data: PaymentPackages) {
    return await axios.post('api/payment_packages', data)
  }

  async updatePaymentPackage(id: string | string[], data: PaymentPackages) {
    return await axios.put(`api/payment_packages/${id}`, data)
  }

  async destroyPaymentPackage(id: number) {
    return await axios.delete(`api/payment_packages/${id}`)
  }
}

export const paymentPackagesService = new PaymentPackagesService()
