<template>
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="bg-gray-50">
      <tr>
        <th
          v-for="(column, index) in columns"
          :key="index"
          scope="col"
          class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider hover:bg-indigo-200"
        >
          <div class="inline-flex text-right flex-wrap w-full">
            {{ column.label }}
          </div>
        </th>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:bg-indigo-200"
        >
          <div class="text-right mr-3 mt-1 left-0">
            Edit
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <tr
        v-for="(row, index) in paymentPackagesData"
        :key="index"
        class="hover:bg-indigo-200"
      >
        <td class="px-6 py-4 break-all">
          <div class="flex items-center truncate">
            {{ row.id }}
          </div>
        </td>
        <td class="px-6 py-4 break-all">
          <div class="flex items-center truncate">
            {{ row.epoch_pi_code }}
          </div>
        </td>
        <td class="px-6 py-4">
          <div class="flex items-center">{{ row.amount }}</div>
        </td>
        <td class="px-6 py-4">
          <div class="flex items-center">
            {{ row.amount_month }}
          </div>
        </td>
        <td class="px-6 py-4">
          <div class="flex items-center">
            {{ row.amount_day }}
          </div>
        </td>
        <td class="px-6 py-4">
          <div class="flex items-center">
            {{ row.price_before }}
          </div>
        </td>
        <td class="px-6 py-4">
          <div class="flex items-center">
            {{ row.currency }}
          </div>
        </td>
        <td class="px-6 py-4">
          <div class="flex items-center">
            {{ row.period }}
          </div>
        </td>
        <td class="px-6 py-4">
          <div
            v-if="row.is_payment_site"
            class="h-6 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2 bg-green-100 text-green-800"
          >
            JA
          </div>
          <div
            v-if="!row.is_payment_site"
            class="h-6 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2 bg-gray-700 text-white"
          >
            NEIN
          </div>
        </td>
        <td class="px-6 py-4">
          <div
            v-if="row.is_recurring"
            class="h-6 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2 bg-green-100 text-green-800"
          >
            JA
          </div>
          <div
            v-if="!row.is_recurring"
            class="h-6 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2 bg-gray-700 text-white"
          >
            NEIN
          </div>
        </td>
        <td class="px-6 py-4">
          <div
            v-if="row.is_highlight"
            class="h-6 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2 bg-green-100 text-green-800"
          >
            JA
          </div>
          <div
            v-if="!row.is_highlight"
            class="h-6 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2 bg-gray-700 text-white"
          >
            NEIN
          </div>
        </td>
        <td class="px-6 py-4">
          <div
            v-if="row.is_active"
            class="h-6 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2 bg-green-100 text-green-800"
          >
            JA
          </div>
          <div
            v-if="!row.is_active"
            class="h-6 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2 bg-gray-700 text-white"
          >
            NEIN
          </div>
        </td>
        <td class="px-6 py-4">
          <div class="flex items-center">
            {{ row.order }}
          </div>
        </td>
        <td
          class="flex-col px-6 py-4 text-right text-sm font-medium whitespace-pre"
        >
          <div>
            <router-link
              :to="{
                name: 'updatePaymentPackages',
                params: { payment_package_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900 mr-3"
              >Bearbeiten</router-link
            >
          </div>
          <div>
            <a
              href="#"
              class="text-red-600 hover:text-indigo-900 mr-3"
              @click.prevent="this.$parent.deletePaymentPackage(row.id)"
              >Löschen</a
            >
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount, PropType } from 'vue'
import PaymentPackages from '@/interfaces/PaymentPackages'
export default defineComponent({
  name: 'PaymentPackagesTable',
  props: {
    paymentPackagesData: {
      type: Object as PropType<PaymentPackages>
    },
    columns: {
      type: Array
    }
  }
})
</script>

<style scoped></style>
