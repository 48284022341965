<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="flex items-center justify-center"><GoBackButton/> </div>

  <div class="flex items-center justify-center">

    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-2">
        <div v-for="(row, index) in imagesSetContent" :key="index">
          <v-lazy-image v-if="slug"
            :src="
              row.image_preview_url + '/' + slug + '/230x150/' + row.image_name
            "
            @click.prevent="
              this.openLightBox(row.image_preview_url, slug, row.image_name)
            "
            class="cursor-pointer"
          />
          <div v-else>Loading...</div>
          <div
            class="details flex text-sm items-center justify-center bg-gray-300 pt-1 pb-1"
            :class="{ 'bg-indigo-600': row.is_preview }"
          >
            <div
              class="mr-2 cursor-pointer"
              @click.prevent="this.moveToTrash(row.id)"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                ></path>
              </svg>
            </div>
            <div
              @click.prevent="this.isPreview(row.id, !row.is_preview)"
              class="cursor-pointer"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div  class="flex items-center justify-center"><GoBackButton/> </div>
  <LightBox ref="lightBox"></LightBox>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from 'vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import LightBox from '@/components/LightBox.vue'
import { imagesSetsContentService } from '@/services/ImagesSetsContent.service'
import { imagesSetsService } from '@/services/ImagesSets.service'

import GoBackButton from '@/components/GoBackButton.vue'

import VLazyImage from 'v-lazy-image'

export default defineComponent({
  name: 'ImageSetContent',
  components: {
    LoadingSpinner,
    LightBox,
    GoBackButton,
    VLazyImage
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    watchEffect(() => {
      this.getImageSetContent(parseInt(this.image_set_id))
    })
  },
  data() {
    return {
      imagesSetContent: null,
      image_set_id: this.$route.params.image_set_id,
      slug: null
    }
  },
  methods: {
    async getImageSetContent(image_set_id: number) {
      await imagesSetsContentService
        .getImagesSetsContent(image_set_id)
        .then(response => {
          console.log(response.data)
          this.imagesSetContent = response.data.data
          this.getImageSetDataForSlug(image_set_id)
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getImageSetDataForSlug(image_set_id: number) {
      await imagesSetsService.getImageSetData(image_set_id).then(response => {
        this.slug = response.data.data.slug
      })
    },
    async openLightBox(
      imagePreviewUrl: string,
      slug: string,
      imageName: string
    ) {
      await this.$refs.lightBox.show({ imagePreviewUrl, slug, imageName })
    },
    async isPreview(imagesSetsContent: number, is_preview: boolean) {
      this.$refs.loadingSpinner.open()
      await imagesSetsContentService
        .setIsPreviewImage(imagesSetsContent, is_preview)
        .then(response => {
          console.log(response.data)
          this.getImageSetContent(this.image_set_id)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async moveToTrash(imagesSetsContent: number) {
      this.$refs.loadingSpinner.open()
      await imagesSetsContentService
        .deleteImage(imagesSetsContent)
        .then(response => {
          console.log(response.data)
          this.getImageSetContent(this.image_set_id)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
})
</script>

<style>
.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
