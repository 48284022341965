
import { defineComponent } from 'vue'

import AddUserForm from '../components/AddUserForm.vue'
import ConfirmDialogue from '../components/ConfirmDialogue.vue'

export default defineComponent({
  name: 'UserData',
  components: { ConfirmDialogue, AddUserForm },
  data() {
    return {
      userId: null
    }
  }
})
