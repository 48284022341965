
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

import GoBackButton from './GoBackButton.vue'
import { languageSelect } from '@/use/languageSelectData'
import { imageSetDescriptionSchema } from '@/schemas/imageSetDescriptionSchema'
import { ImageSetDescription } from '@/interfaces/ImageSetDescription'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'
import { imagesSetsService } from '@/services/ImagesSets.service'

export default defineComponent({
  name: 'ImagesSetDescriptionForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    LoadingSpinner,
    GoBackButton,
    Alerts
  },
  created() {
    this.image_set_id = this.$route.params.image_set_id
    this.image_set_description_id = this.$route.params.image_set_description_id
    if (this.image_set_description_id) {
      //this.$refs.loadingSpinner.open()
      this.getImageSetDescriptionData(this.image_set_description_id)
    }
  },
  data() {
    const image_set_description_id: number = null
    const image_set_id: number = null
    return {
      image_set_description_id,
      image_set_id,
      languageSelect,
      imageSetDescriptionSchema,
      alertType: null,
      imageSetDescriptionFormData: {} as ImageSetDescription,
      initialFormValues: {} as ImageSetDescription
    }
  },
  methods: {
    async saveImageSetDescription(data) {
      if (!this.image_set_description_id) {
        await imagesSetsService
          .createImageSetDescription(this.image_set_id, data)
          .then(response => {
            this.showSuccessAlert(
              'Image Set Beschreibung erfolgreich gespeichert!'
            )
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      } else {
        await imagesSetsService
          .updateImageSetDescription(this.image_set_description_id, data)
          .then(response => {
            this.showSuccessAlert(
              'Image Set Beschreibung erfolgreich gespeichert!'
            )
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      }
    },
    async getImageSetDescriptionData(image_set_description_id: number) {
      await imagesSetsService
        .getImageSetDescription(image_set_description_id)
        .then(response => {
          this.initialFormValues = ref<ImageSetDescription>({
            title: response.data.data.title,
            description: response.data.data.description,
            keywords: response.data.data.keywords,
            language: response.data.data.language
          })
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
