
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'DataTableFilters',
  props: {
    perPageSelected: {
      type: Number,
      default: () => 25
    },
    perPageValues: {
      type: Array,
      default: () => [10, 25, 50]
    },
    placeholderSearch: {
      type: String,
      default: 'Search Table'
    },
    statusSelected:{
      type: String,
      default: ()=>'active'
    },
    statusValues: {
      type: Array,
      default: () => ['active', 'inactive', 'all']
    }
  },
  data() {
    return {
      search: null,
      selectedValue: null,
      selectedStatusValue: null
    }
  },
  watch: {
    async search(searchString) {
      let search = null
      if (searchString.length > 0 && searchString.trim()) {
        search = searchString
      }
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          search: search,
          page: 1
        }
      })
      await this.$parent.getUsersData(search)
    }
  },
  created() {
    console.log(this.statusSelected)
    this.setSelectedValue()
    this.setSelectedStatusValue()
  },
  methods: {
    ...mapActions({
      setPerPageAction: 'paginationSettings/setPerPage'
    }),
    setSelectedValue() {
      this.selectedValue = this.perPageSelected
    },
    setSelectedStatusValue(){
      this.selectedStatusValue = this.statusSelected
    },
    setPerPage(event) {
      this.setPerPageAction(event.target.value)
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          perPage: event.target.value,
          page: 1
        }
      })
      this.$parent.perPage = event.target.value
      this.$parent.getUsersData()
    },
    setStatusValue(event) {
      console.log(event.target.value)
      //this.setPerPageAction(event.target.value)
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          status: event.target.value
        }
      })
      this.$parent.status = event.target.value
      this.$parent.getUsersData()
    },

    onChangePerPage(event) {
      this.setPerPage(event)
    },
    onChangeStatus(event) {
      this.setStatusValue(event)
    }
  }
})
