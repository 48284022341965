
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import GoBackButton from '@/components/GoBackButton.vue'
import SaveButton from '@/components/SaveButton.vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import PaymentPackages from '@/interfaces/PaymentPackages'
import { paymentPackagesService } from '@/services/PaymentPackages.Service'

export default defineComponent({
  name: 'PaymentPackagesDataForm',
  components: {
    GoBackButton,
    SaveButton,
    LoadingSpinner,
    Alerts
  },
  setup() {
    const alertType = ref<string>(null)
    const alert = ref(null)
    const loadingSpinner = ref(null)
    const route = useRoute()
    const paymentPackageId = route.params.payment_package_id
    const form = ref<PaymentPackages>({
      epoch_pi_code: '',
      amount: 0,
      amount_month: 0,
      amount_day: 0,
      currency: 'EUR',
      period: 30,
      is_payment_site: false,
      is_recurring: false,
      is_highlight: false,
      is_active: false,
      price_before: 0,
      price_info: '',
      order: 0
    })
    const booleanSelect = [false, true]
    const state = reactive({
      form
    })
    const rules: any = computed(() => {
      return {
        form: {
          epoch_pi_code: { required, $autoDirty: true },
          amount: { required, $autoDirty: true },
          amount_month: { required, $autoDirty: true },
          amount_day: { required, $autoDirty: true },
          currency: { required, $autoDirty: true },
          period: { required, $autoDirty: true },
          is_payment_site: { required, $autoDirty: true },
          is_recurring: { required, $autoDirty: true },
          is_highlight: { required, $autoDirty: true },
          is_active: { required, $autoDirty: true },
          price_before: { required, $autoDirty: true },
          price_info: { required, $autoDirty: true },
          order: { required, $autoDirty: true }
        }
      }
    })
    const v$ = useVuelidate(rules, state)

    onMounted(async () => {
      if (paymentPackageId) {
        await getPaymentData()
      }
    })

    const savePaymentPackageData = async () => {
      if (!paymentPackageId) {
        loadingSpinner.value.open()
        form.value.price_info = JSON.parse(form.value.price_info)
        await paymentPackagesService
          .createPaymentPackage(form.value)
          .then(response => {
            loadingSpinner.value.close()
            showSuccessAlert('Payment Packet erfolgreich zugefügt!')
            return response.data
          })
          .catch(error => {
            console.log(error.response.data.data.message)
            loadingSpinner.value.close()
            showErrorAlert(
              error.message + ' - ' + error.response.data.data.message
            )
          })
      } else {
        loadingSpinner.value.open()
        await paymentPackagesService
          .updatePaymentPackage(paymentPackageId, form.value)
          .then(response => {
            loadingSpinner.value.close()
            showSuccessAlert('Payment Packet erfolgreich upgedatet!')
            return response.data
          })
          .catch(error => {
            console.log(error.response.data.data.message)
            loadingSpinner.value.close()
            showErrorAlert(
              error.message + ' - ' + error.response.data.data.message
            )
          })
      }
    }

    const getPaymentData = async () => {
      loadingSpinner.value.open()
      await paymentPackagesService
        .getPaymentPacketData(paymentPackageId)
        .then(response => {
          form.value = response.data.data
          form.value.is_payment_site = response.data.data.is_payment_site === 1
          form.value.is_recurring = response.data.data.is_recurring === 1
          form.value.is_highlight = response.data.data.is_highlight === 1
          form.value.is_active = response.data.data.is_active === 1
          loadingSpinner.value.close()
        })
        .catch(error => {
          showErrorAlert(error)
          console.log(error)
        })
    }

    const showSuccessAlert = (message: string) => {
      alertType.value = 'success'
      alert.value.open(message)
      setTimeout(() => {
        alert.value.close()
        alertType.value = null
      }, 5000)
    }

    const showErrorAlert = (message: string) => {
      alertType.value = 'error'
      alert.value.open(message)
      setTimeout(() => {
        alert.value.close()
        alertType.value = null
      }, 5000)
    }

    return {
      alertType,
      alert,
      loadingSpinner,
      form,
      booleanSelect,
      v$,
      savePaymentPackageData
    }
  }
})
