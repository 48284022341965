import axios from 'axios'
import { ImageSet } from '@/interfaces/ImageSet'
import { ImageSetDescription } from '@/interfaces/ImageSetDescription'

class ImagesSetsService {
  async getImagesSets(
    perPage: number = null,
    page: number = null,
    search: string = null,
    sortBy: string = null,
    sortDirection: string = null,
    seriesId: string = null
  ) {
    if (search && !sortBy && !seriesId) {
      return await axios.get(
        `api/images_sets?per_page=${perPage}&page=${page}&search=${search}`
      )
    }
    if (sortBy && search && !seriesId) {
      return await axios.get(
        `api/images_sets?per_page=${perPage}&page=${page}&search=${search}&sortBy=${sortBy}&sortDirection=${sortDirection}`
      )
    }
    if (sortBy && !search && !seriesId) {
      return await axios.get(
        `api/images_sets?per_page=${perPage}&page=${page}&sortBy=${sortBy}&sortDirection=${sortDirection}`
      )
    }

    if (sortBy && search && seriesId) {
      return await axios.get(
        `api/images_sets/filtered_by_series?per_page=${perPage}&page=${page}&search=${search}&series_id=${seriesId}&sortBy=${sortBy}&sortDirection=${sortDirection}`
      )
    }
    if (sortBy && !search && seriesId) {
      return await axios.get(
        `api/images_sets/filtered_by_series?per_page=${perPage}&page=${page}&series_id=${seriesId}&sortBy=${sortBy}&sortDirection=${sortDirection}`
      )
    }

    if (!sortBy && search && seriesId) {
      return await axios.get(
          `api/images_sets/filtered_by_series?per_page=${perPage}&page=${page}&search=${search}&series_id=${seriesId}&sortDirection=${sortDirection}`
      )
    }

    if (!sortBy && !search && seriesId) {
      return await axios.get(
          `api/images_sets/filtered_by_series?per_page=${perPage}&page=${page}&series_id=${seriesId}&sortDirection=${sortDirection}`
      )
    }

    return axios.get(`api/images_sets?per_page=${perPage}&page=${page}`)
  }

  async createImageSet(imageSet: Array<ImageSet>) {
    return await axios.post(`api/images_sets`, imageSet)
  }

  async updateImageSet(id: number, imageSet: Array<ImageSet>) {
    return await axios.put(`api/images_sets/${id}`, imageSet)
  }

  async deleteImageSet(id: number) {
    return await axios.delete(`api/images_sets/${id}`)
  }
  async getImageSetData(id: number) {
    return await axios.get(`api/images_sets/${id}`)
  }

  async createImageSetDescription(
    imageSetId: number,
    imageSetDescription: Array<ImageSetDescription>
  ) {
    return await axios.post(
      `api/images_sets_description/${imageSetId}`,
      imageSetDescription
    )
  }
  async updateImageSetDescription(
    imageSetDescriptionId: number,
    imageSetDescription: Array<ImageSetDescription>
  ) {
    return await axios.put(
      `api/images_sets_description/${imageSetDescriptionId}`,
      imageSetDescription
    )
  }

  async getImageSetDescription(id: number) {
    return await axios.get(`api/images_sets_description/${id}`)
  }
}

export const imagesSetsService = new ImagesSetsService()
