import axios from 'axios'
import { NewPassword } from '@/interfaces/NewPassword'

class NewPasswordService {
  async getResetPasswordEmail(data: Array<string>) {
    return axios.post('api/forgot-password', data)
  }
  async resetPasswordData(data: NewPassword) {
    return axios.post('api/reset-password', data)
  }
}

export const newPasswordService = new NewPasswordService()
