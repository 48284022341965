
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import { languageSelect } from '@/use/languageSelectData'
import { siteDescriptionSchema } from '@/schemas/siteDescriptionSchema'
import LoadingSpinner from './lib/LoadingSpinner.vue'
import Alerts from '../components/lib/Alerts.vue'
import { SiteDescription } from '@/interfaces/SiteDescription'
import { sitesDescriptionService } from '@/services/SitesDescription.service'
export default defineComponent({
  name: 'SiteDescriptionForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    LoadingSpinner,
    GoBackButton,
    SaveButton,
    Alerts
  },
  data() {
    const site_description_id: number = null
    return {
      site_description_id,
      siteDescriptionFormData: {} as SiteDescription,
      initialFormValues: {} as SiteDescription,
      languageSelect,
      siteDescriptionSchema,
      alertType: null
    }
  },
  mounted() {
    if (this.$route.params.site_description_id) {
      this.$refs.loadingSpinner.open()
    }
  },
  created() {
    this.site_description_id = this.$route.params.site_description_id
    if (this.site_description_id) {
      this.getSiteDescriptionData(this.site_description_id)
    }
  },
  methods: {
    async saveSiteDescriptionData(data) {
      if (!this.$route.params.site_description_id) {
        this.$refs.loadingSpinner.open()
        await sitesDescriptionService
          .createSiteDescription(data)
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert(
              'Seiten Beschreibung erfolgreich gespeichert!'
            )
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      } else {
        this.$refs.loadingSpinner.open()
        await sitesDescriptionService
          .updateSiteDescription(this.site_description_id, data)
          .then(response => {
            this.siteDescriptionFormData = ref<SiteDescription>({
              title: response.data.title,
              description: response.data.description,
              keywords: response.data.keywords,
              language: response.data.language
            })
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Seiten Beschreibung erfolgreich updated!')
            return this.siteDescriptionFormData
          })
          .catch(error => {
            this.showErrorAlert(error)
            console.log(error)
          })
      }
    },
    async getSiteDescriptionData(site_description_id: number) {
      await sitesDescriptionService
        .getDescriptionData(site_description_id)
        .then(response => {
          this.initialFormValues = ref<SiteDescription>({
            title: response.data.title,
            description: response.data.description,
            keywords: response.data.keywords,
            language: response.data.language
          })
          this.$refs.loadingSpinner.close()
          return this.initialFormValues
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
