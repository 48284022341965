<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="flex justify-center items-center h-screen bg-gray-200 px-6">
    <div class="p-6 max-w-sm w-full bg-white shadow-md rounded-md">
      <div class="flex justify-center items-center">
        <span class="text-gray-700 font-semibold text-2xl"
          >Forgot Password</span
        >
      </div>
      <div class="flex justify-center items-center" v-if="error">
        <span class="text-red-500 font-medium">{{ error }}</span>
      </div>
      <div v-if="!status">
        <form class="mt-4" @submit.prevent="forgotPassword">
          <div class="mb-6">
            <label for="email" class="inline-block text-sm mb-2">
              Email:
            </label>
            <input
              type="email"
              id="email"
              class="w-full border-2 border-gray-200 h-10 px-3 rounded-md"
              v-model="form.email"
            />
          </div>
          <div class="flex justify-between items-center mt-4">
            <div>
              <router-link
                class="block text-sm fontme text-indigo-700 hover:underline"
                :to="{ name: 'Login' }"
                >Sign in!
              </router-link>
            </div>
          </div>

          <div class="mt-6">
            <button
              type="submit"
              class="py-2 px-4 text-center bg-indigo-600 rounded-md w-full text-white text-sm hover:bg-indigo-500"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
      <div v-if="status">
        <div class="flex justify-center items-center pt-5">
          <span class="text-gray-700 font-normal text-lg">{{ status }}</span>
        </div>
        <div class="flex justify-between mt-6">
          <div></div>
          <GoBackButton />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from 'vue'
import { newPasswordService } from '@/services/NewPasswordService'

import LoadingSpinner from '../components/lib/LoadingSpinner.vue'
import GoBackButton from '../components/GoBackButton.vue'

export default defineComponent({
  name: 'forgotPassword',
  components: {
    LoadingSpinner,
    GoBackButton
  },
  data() {
    return {
      form: {
        email: ''
      },
      status: null
    }
  },
  methods: {
    async forgotPassword() {
      this.$refs.loadingSpinner.open()
      await newPasswordService
        .getResetPasswordEmail(this.form)
        .then(response => {
          this.status = response.data.status
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
})
</script>

<style scoped></style>
