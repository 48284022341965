
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import { languageSelect } from '@/use/languageSelectData'
import { videoSetDescriptionSchema } from '@/schemas/VideoSetDescriptionSchema'
import { VideoSetDescription } from '@/interfaces/VideoSetDescription'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'
import { videosSetsService } from '@/services/VideosSets.service'

export default defineComponent({
  name: 'VideoSetDescriptionForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    LoadingSpinner,
    GoBackButton,
    SaveButton,
    Alerts
  },
  created() {
    this.video_set_id = this.$route.params.video_set_id
    this.video_set_description_id = this.$route.params.video_set_description_id
    if (this.video_set_description_id) {
      this.getVideoSetDescriptionData(this.video_set_description_id)
    }
  },
  data() {
    const video_set_description_id: number = null
    const video_set_id: number = null
    return {
      video_set_description_id,
      video_set_id,
      languageSelect,
      videoSetDescriptionSchema,
      alertType: null,
      videoSetDescriptionFormData: {} as VideoSetDescription,
      initialFormValues: {} as VideoSetDescription
    }
  },
  methods: {
    async saveVideoSetDescription(data) {
      if (!this.video_set_description_id) {
        await videosSetsService
          .createVideoSetDescription(this.video_set_id, data)
          .then(response => {
            this.showSuccessAlert(
              'Video Set Beschreibung erfolgreich gespeichert!'
            )
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      } else {
        await videosSetsService
          .updateVideoSetDescription(this.video_set_description_id, data)
          .then(response => {
            this.showSuccessAlert(
              'Video Set Beschreibung erfolgreich geändert!'
            )
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      }
    },
    async getVideoSetDescriptionData(video_set_description_id: number) {
      await videosSetsService
        .getVideoSetsDescription(video_set_description_id)
        .then(response => {
          this.initialFormValues = ref<VideoSetDescription>({
            title: response.data.data.title,
            description: response.data.data.description,
            keywords: response.data.data.keywords,
            language: response.data.data.language
          })
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
