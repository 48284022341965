
import { defineComponent, ref } from 'vue'
import { useSidebar } from '@/hooks/useSidebar'
import { mapGetters, mapActions } from 'vuex'

export default defineComponent({
  name: 'Header',
  setup() {
    const dropdownOpen = ref(false)
    const { isOpen } = useSidebar()
    return {
      isOpen,
      dropdownOpen
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    })
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logoutAction'
    }),
    async logout() {
      await this.logoutAction().then(() => {
        this.$router.push({ name: 'Login' })
      })
    }
  }
})
