import axios from 'axios'
import { ModelsData } from '@/interfaces/ModelsData'
import { ModelsDataDescriptions } from '@/interfaces/ModelsDataDescriptions'

class ModelsDataService {
  async getModels(
    perPage: number = null,
    page: number = null,
    search: string = null,
    sortBy: string = null,
    sortDirection: string = null
  ) {
    if (search) {
      return await axios.get(
        `api/actors?per_page=${perPage}&page=${page}&search=${search}`
      )
    }
    if (sortBy && search) {
      return await axios.get(
        `api/actors?per_page=${perPage}&page=${page}&search=${search}&sortBy=${sortBy}&sortDirection=${sortDirection}`
      )
    }
    if (sortBy && !search) {
      return await axios.get(
        `api/actors?per_page=${perPage}&page=${page}&sortBy=${sortBy}&sortDirection=${sortDirection}`
      )
    }

    return axios.get(`api/actors?per_page=${perPage}&page=${page}`)
  }

  async getModel(id: number) {
    return await axios.get(`api/actors/${id}`)
  }

  async createModel(modelsData: Array<ModelsData>) {
    return await axios.post(`api/actors`, modelsData)
  }

  async updateModel(id: number, modelsData: Array<ModelsData>) {
    return await axios.put(`api/actors/${id}`, modelsData)
  }

  async deleteModel(id: number) {
    return await axios.delete(`api/actors/${id}`)
  }
  async createModelDescription(
    model_id: number,
    modelDescription: Array<ModelsDataDescriptions>
  ) {
    return await axios.post(
      `api/actors/add_description/${model_id}`,
      modelDescription
    )
  }
  async updateModelDescription(
    model_description_id: number,
    modelDescription: Array<ModelsDataDescriptions>
  ) {
    return await axios.put(
      `api/actors/description/${model_description_id}`,
      modelDescription
    )
  }
  async getModelDescription(model_description_id: number) {
    return await axios.get(`api/actors/description/${model_description_id}`)
  }

  async createModelContentSets(model_id: number, image_sets: number[]) {
    return await axios.post(`api/actors/add_content/${model_id}`, image_sets)
  }
  async deleteModelContentSet(
    model_id: number,
    content_id: number,
    content_type: string
  ) {
    return await axios.delete(
      `api/actors/content_sets/${model_id}?content_id=${content_id}&content_type=${content_type}`
    )
  }

  async getContentToActorData() {
    return axios.get('api/content_to_actor')
  }

  async getModelPreviewImages(model_id: number | string) {
    return await axios.get(`api/actors/preview_images/${model_id}`)
  }

  async deletePreviewImages(
    model_id: number | string,
    preview_id: number | string
  ) {
    return await axios.delete(
      `api/actors/preview_images/${model_id}?preview_id=${preview_id}`
    )
  }
}

export const modelsDataService = new ModelsDataService()
