<template>
  <LightBoxModal ref="popup">
    <!--Title-->
    <div class="flex justify-between items-center pb-3">
      <p class="text-2xl font-bold">{{ imageName }}</p>
      <div class="modal-close cursor-pointer z-50" @click="_cancel">
        <svg
          class="fill-current text-black"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
      </div>
    </div>

    <!--Body-->
    <p>
      <v-lazy-image
        :src="url"
        src-placeholder="https://source.unsplash.com/WLUHO9A_xik/50x20"
      />
    </p>

    <!--Footer-->
    <div class="flex justify-end pt-2"></div>
  </LightBoxModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LightBoxModal from './LightBoxModal.vue'

import VLazyImage from 'v-lazy-image'

export default defineComponent({
  name: 'LightBox',
  components: { LightBoxModal, VLazyImage },
  data() {
    const imageName = null
    const url = null
    return {
      url,
      imageName
    }
  },
  methods: {
    async show(opt: Record<string, string>) {
      this.url =
        opt.imagePreviewUrl + '/' + opt.slug + '/1024x0/' + opt.imageName
      this.imageName = opt.imageName
      console.log(opt.imageName)
      if (opt.cancelButton) {
        this.cancelButton = opt.cancelButton
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    _cancel() {
      this.$refs.popup.close()
      this.url = null
    }
  }
})
</script>

<style scoped>
.modal {
  transition: opacity 0.3s ease;
}
</style>
