<template>
  <div class="flex justify-center items-center h-screen bg-gray-200 px-6">
    <div class="p-6 max-w-sm w-full bg-white shadow-md rounded-md">
      <div class="flex justify-center items-center">
        <span class="text-gray-700 font-semibold text-2xl"
          >Primamedia CMS Login</span
        >
      </div>
      <div class="flex justify-center items-center" v-if="error">
        <span class="text-red-500 font-medium">{{ error }}</span>
      </div>
      <form class="mt-4" @submit.prevent="login">
        <div class="mb-6">
          <label for="email" class="inline-block text-sm mb-2">
            Email:
          </label>
          <input
            type="email"
            id="email"
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md"
            v-model="form.email"
          />
        </div>
        <div class="mb-6">
          <label for="password" class="inline-block text-sm mb-2">
            Password
          </label>
          <input
            type="password"
            id="password"
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md"
            v-model="form.password"
          />
        </div>
        <div class="flex justify-between items-center mt-4">
          <div>
            <label class="inline-flex items-center">
              <input type="checkbox" class="form-checkbox text-indigo-600" />
              <span class="mx-2 text-gray-600 text-sm">Remember me</span>
            </label>
          </div>

          <div>
            <router-link
              class="block text-sm fontme text-indigo-700 hover:underline"
              :to="{ name: 'forgotPassword' }"
              >Forgot your password?</router-link
            >
          </div>
        </div>

        <div class="mt-6">
          <button
            type="submit"
            class="py-2 px-4 text-center bg-indigo-600 rounded-md w-full text-white text-sm hover:bg-indigo-500"
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  name: 'Login',
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      error: null
    }
  },
  methods: {
    ...mapActions({
      loginAction: 'auth/login'
    }),
    ...mapGetters({
      loggedUserGetter: 'auth/user'
    }),
    async login() {
      await this.loginAction(this.form)
        .then(() => {
          console.log(this.loggedUserGetter().role)
          const allowedRoles = ['admin', 'super_admin']
          if (!allowedRoles.includes(this.loggedUserGetter().role)) {
            this.$router.push({ name: 'forbidden' })
          } else {
            this.$router.push({ name: 'dashboard' })
          }
        })
        .catch(error => {
          this.error = error.response.data.message
        })
    }
  }
})
</script>
