
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import { seriesService } from '@/services/Series.service'
import { videosSetsService } from '@/services/VideosSets.service'
import { videoSetSchema } from '@/schemas/videoSetSchema'
import { VideoSets } from '@/interfaces/VideoSets'

import LoadingSpinner from './lib/LoadingSpinner.vue'
import Alerts from '../components/lib/Alerts.vue'
import dateTimeFormatterForDateInput from '@/filters/dateTimeFormatterForDateInput'

export default defineComponent({
  name: 'VideosSetsDataForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    GoBackButton,
    SaveButton,
    LoadingSpinner,
    Alerts
  },
  data() {
    const seriesDataArray = []
    const video_set_id: number = null
    return {
      video_set_id,
      seriesDataArray,
      videosSetFormData: {} as VideoSets,
      initialFormValues: {} as VideoSets,
      videoSetSchema,
      alertType: null
    }
  },
  created() {
    this.video_set_id = this.$route.params.video_set_id
    this.getSeriesDataForSelect().then(() => {
      if (this.video_set_id) {
        this.$refs.loadingSpinner.open()
        this.getVideoSetData(this.video_set_id)
      }
    })
  },
  methods: {
    async getSeriesDataForSelect() {
      await seriesService
        .getSeriesData()
        .then(response => {
          this.seriesDataArray = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async saveVideoSetData(data) {
      if (!this.$route.params.video_set_id) {
        this.$refs.loadingSpinner.open()
        await videosSetsService
          .createVideoSet(data)
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Videos Set erfolgreich erstellt!')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      } else {
        this.$refs.loadingSpinner.open()
        await videosSetsService
          .updateVideoSet(this.video_set_id, data)
          .then(response => {
            this.videosSetFormData = ref<VideoSets>({
              set_name: response.data.set_name,
              description: response.data.description,
              series_id: response.data.series_id,
              publish_on: dateTimeFormatterForDateInput(
                response.data.publish_on
              )
            })
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Video Set  erfolgreich updated!')
            return this.videosSetFormData
          })
          .catch(error => {
            this.showErrorAlert(error)
            console.log(error)
          })
      }
    },
    async getVideoSetData(video_set_id: number) {
      await videosSetsService
        .getVideoSetData(video_set_id)
        .then(response => {
          this.initialFormValues = ref<VideoSets>({
            set_name: response.data.data.set_name,
            description: response.data.data.description,
            series_id: response.data.data.series.id,
            publish_on: dateTimeFormatterForDateInput(
              response.data.data.publish_on
            )
          })
          this.$refs.loadingSpinner.close()
          return this.videoSetFormData
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
