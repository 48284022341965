
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

import GoBackButton from './GoBackButton.vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'
import { ImageSettings } from '@/interfaces/ImageSettings'
import { imageSettingsService } from '@/services/ImageSettings.service'
import { imageSettingsSchema } from '@/schemas/imageSettingsSchema'

export default defineComponent({
  name: 'ImageSettingsForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    GoBackButton,
    LoadingSpinner,
    Alerts
  },
  data() {
    const image_setting_id: number = null
    const crop_thumbnail_exact_value = false
    return {
      image_setting_id,
      imageSettingsFormData: {} as ImageSettings,
      imageSettingsFormInitialData: {} as ImageSettings,
      imageSettingsSchema,
      alertType: null,
      crop_thumbnail_exact_value
    }
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    this.getImageSettingsData()
  },
  methods: {
    async getImageSettingsData() {
      await imageSettingsService
        .getImageSettings()
        .then(response => {
          if (response.data.data[0].crop_thumbnail_exact === true) {
            this.crop_thumbnail_exact_value = true
          } else {
            this.crop_thumbnail_exact_value = false
          }
          this.image_setting_id = response.data.data[0].image_settings_id
          this.imageSettingsFormInitialData = ref<ImageSettings>({
            thumbnail_mobile_max_width:
              response.data.data[0].thumbnail_mobile_max_width,
            thumbnail_mobile_max_height:
              response.data.data[0].thumbnail_mobile_max_height,
            thumbnail_desktop_max_width:
              response.data.data[0].thumbnail_desktop_max_width,
            thumbnail_desktop_max_height:
              response.data.data[0].thumbnail_desktop_max_height,
            crop_thumbnail_exact: response.data.data[0].crop_thumbnail_exact,
            image_mobile_max_width:
              response.data.data[0].image_mobile_max_width,
            image_mobile_max_height:
              response.data.data[0].image_mobile_max_height,
            image_desktop_max_width:
              response.data.data[0].image_desktop_max_width,
            image_desktop_max_height:
              response.data.data[0].image_desktop_max_height
          })
          this.$refs.loadingSpinner.close()
          console.log(this.imageSettingsFormInitialData)
          return this.imageSettingsFormInitialData
        })
        .catch(error => {
          this.$refs.loadingSpinner.close()
          this.showErrorAlert(error.message)
        })
    },
    async updateSettingsData(data) {
      this.$refs.loadingSpinner.open()
      console.log(data.crop_thumbnail_exact)
      if (data.crop_thumbnail_exact === 'true') {
        data.crop_thumbnail_exact = true
      } else {
        data.crop_thumbnail_exact = false
      }
      console.log(data)
      await imageSettingsService
        .updateImageSettings(this.image_setting_id, data)
        .then(response => {
          this.showSuccessAlert('Bilder Settings erfolgreich gespeichert!')
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          this.$refs.loadingSpinner.close()
          this.showErrorAlert(error.message)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
