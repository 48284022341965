<template>
  <div
    class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
  >
    <dt>
      <div class="absolute rounded-md bg-green-600 p-3">
        <UsersIcon
          v-if="dashboardData.iconName === 'UsersIcon'"
          class="h-6 w-6 text-white"
          aria-hidden="true"
        />
        <EnvelopeOpenIcon
          v-if="dashboardData.iconName === 'EnvelopeOpenIcon'"
          class="h-6 w-6 text-white"
          aria-hidden="true"
        />
        <CursorArrowRaysIcon
          v-if="dashboardData.iconName === 'CursorArrowRaysIcon'"
          class="h-6 w-6 text-white"
          aria-hidden="true"
        />
        <MoneyIcon
          v-if="dashboardData.iconName === 'MoneyIcon'"
          class="h-6 w-6 text-white"
          aria-hidden="true"
        />
      </div>
      <p class="ml-16 truncate text-sm font-medium text-gray-500">
        {{ dashboardData.widgetName }}
      </p>
    </dt>
    <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
      <p class="text-2xl font-semibold text-gray-900">
        {{ dashboardData.stats }} €
      </p>
      <p
        v-if="dashboardData.changeType"
        :class="[
          dashboardData.changeType === 'increase'
            ? 'text-green-600'
            : 'text-red-600',
          'ml-2 flex items-baseline text-sm font-semibold'
        ]"
      >
        <ArrowUpIcon
          v-if="dashboardData.changeType === 'increase'"
          class="h-5 w-5 flex-shrink-0 self-center text-green-500"
          aria-hidden="true"
        />
        <ArrowDownIcon
          v-else
          class="h-5 w-5 flex-shrink-0 self-center text-red-500"
          aria-hidden="true"
        />
        <span class="sr-only">
          {{
            dashboardData.changeType === 'increase' ? 'Increased' : 'Decreased'
          }}
          by
        </span>
        {{ dashboardData.change }}
      </p>
    </dd>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import EnvelopeOpenIcon from '@/components/icons/EnvelopeOpenIcon.vue'
import ArrowDownIcon from '@/components/icons/ArrowDownIcon.vue'
import UsersIcon from '@/components/icons/UsersIcon.vue'
import ArrowUpIcon from '@/components/icons/ArrowUpIcon.vue'
import CursorArrowRaysIcon from '@/components/icons/CursorArrowRaysIcon.vue'
import DashboardData from '@/interfaces/DashboardData'
import MoneyIcon from '@/components/icons/MoneyIcon.vue'

export default defineComponent({
  name: 'DashboardRevenueDataComponent',
  components: {
    MoneyIcon,
    UsersIcon,
    EnvelopeOpenIcon,
    CursorArrowRaysIcon,
    ArrowUpIcon,
    ArrowDownIcon
  },
  props: {
    dashboardData: {
      type: Object as PropType<DashboardData>
    }
  }
})
</script>
