<template>
  <div
    :class="
      `modal ${!isVisible &&
        'opacity-0 pointer-events-none'} z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`
    "
  >
    <div
      class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
    ></div>

    <div
      class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"
    >
      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="modal-content py-4 text-left px-6">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Modal',

  data() {
    return {
      isVisible: false
    }
  },

  methods: {
    open() {
      this.isVisible = true
    },

    close() {
      this.isVisible = false
    }
  }
})
</script>
<style>
.modal {
  transition: opacity 0.3s ease;
}
</style>
