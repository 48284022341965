<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">
      Model Beschreibung
    </h2>
    <form @submit.prevent="saveModelDescriptionData" id="modelDescription">
      <div class="mt-2">
        <div class="mt-2">
          <label
            class="inline-block text-sm mb-2 text-gray-700"
            for="description"
            >Beschreibung</label
          >
          <input
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
              v$.modelDescriptionFormData.description.$error
                ? 'border-red-600'
                : 'border-gray-200'
            "
            type="textarea"
            id="description"
            name="description"
            v-model="modelDescriptionFormData.description"
          />
          <span
            v-if="v$.modelDescriptionFormData.description.$error"
            class="text-red-600"
            >{{
              v$.modelDescriptionFormData.description.$errors[0].$message
            }}</span
          >
        </div>
        <div class="mt-2">
          <label
            class="inline-block text-sm mb-2 text-gray-700"
            for="model_keywords"
            >Keywords</label
          >
          <input
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
              v$.modelDescriptionFormData.model_keywords.$error
                ? 'border-red-600'
                : 'border-gray-200'
            "
            type="text"
            id="model_keywords"
            name="model_keywords"
            min="0"
            max="254"
            v-model="modelDescriptionFormData.model_keywords"
          />
          <span
            v-if="v$.modelDescriptionFormData.model_keywords.$error"
            class="text-red-600"
            >{{
              v$.modelDescriptionFormData.model_keywords.$errors[0].$message
            }}</span
          >
        </div>
        <div class="mt-2">
          <label class="inline-block text-sm mb-2 text-gray-700" for="language"
            >Sprache</label
          >
          <select
            id="language"
            name="language"
            form="modelDescription"
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
              v$.modelDescriptionFormData.language.$error
                ? 'border-red-600'
                : 'border-gray-200'
            "
            v-model="modelDescriptionFormData.language"
          >
            <option value="">Wähle die Sprache</option>
            <option
              v-for="(language, index) in languageSelect"
              :key="index"
              :value="language.code"
              >{{ language.name }}
            </option>
          </select>
          <span
            v-if="v$.modelDescriptionFormData.language.$error"
            class="text-red-600"
            >{{
              v$.modelDescriptionFormData.language.$errors[0].$message
            }}</span
          >
        </div>
        <div class="flex justify-between mt-6">
          <GoBackButton />
          <SaveButton />
        </div>
      </div>
    </form>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import { languageSelect } from '@/use/languageSelectData'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'
import { ModelDescription } from '@/interfaces/ModelDescription'
import { modelsDataService } from '@/services/ModelsData.service'
import useVuelidate from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'

export default defineComponent({
  name: 'ModelDescriptionForm',
  components: {
    LoadingSpinner,
    GoBackButton,
    SaveButton,
    Alerts
  },
  created() {
    this.model_id = this.$route.params.model_id
    this.model_description_id = this.$route.params.model_description_id
    if (this.model_description_id) {
      this.getModelDescriptionData(this.model_description_id)
    }
  },
  data() {
    const v$ = useVuelidate()
    const model_description_id: number = null
    const model_id: number = null
    return {
      model_description_id,
      model_id,
      languageSelect,
      alertType: null,
      modelDescriptionFormData: {} as ModelDescription,
      initialFormValues: {} as ModelDescription,
      v$
    }
  },
  methods: {
    async saveModelDescriptionData() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return
      }
      if (!this.model_description_id) {
        await modelsDataService
          .createModelDescription(this.model_id, this.modelDescriptionFormData)
          .then(response => {
            this.showSuccessAlert('Model Beschreibung erfolgreich gespeichert')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      } else {
        await modelsDataService
          .updateModelDescription(
            this.model_description_id,
            this.modelDescriptionFormData
          )
          .then(response => {
            this.showSuccessAlert('Model Beschreibung erfolgreich geändert')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      }
    },

    async getModelDescriptionData(model_description_id: number) {
      await modelsDataService
        .getModelDescription(model_description_id)
        .then(response => {
          this.modelDescriptionFormData = ref<ModelDescription>({
            description: response.data.data.description,
            model_keywords: response.data.data.model_keywords,
            language: response.data.data.language
          })
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  },
  validations() {
    return {
      modelDescriptionFormData: {
        description: { required },
        model_keywords: { required, maxLength: maxLength(254) },
        language: { required }
      }
    }
  }
})
</script>

<style scoped></style>
