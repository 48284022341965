
import { defineComponent } from 'vue'
import GoBackButton from '@/components/GoBackButton.vue'
import VideosSetsContentUploader from '@/components/VideosSetsContentUploader.vue'

export default defineComponent({
  name: 'VideoSetsContentUpload',
  components: {
    GoBackButton,
    VideosSetsContentUploader
  },
  data() {
    return {
      video_set_id: this.$route.params.video_set_id
    }
  }
})
