<template>
  <div class="mb-3 flex flex-wrap">
    <div class="relative mt-3 mr-2">
      <button
        @click="$router.push({ name: 'siteDescriptionData' })"
        class="px-4 py-2 bg-indigo-600 hover:bg-indigo-500 text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
      >
        Seiten Beschreibung zufügen !
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SiteDescriptionTableHeader'
})
</script>

<style scoped></style>
