<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">
      Bilder Sets Beschreibung
    </h2>
    <Form
      @submit="saveImageSetDescription"
      :validation-schema="imageSetDescriptionSchema"
      :initial-values="initialFormValues"
    >
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="title"
          >Tietel</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="title"
          name="title"
          v-model="imageSetDescriptionFormData.title"
        />
        <ErrorMessage name="title" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="description"
          >Beschreibung</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="description"
          name="description"
          v-model="imageSetDescriptionFormData.description"
        />
        <ErrorMessage name="description" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="keywords"
          >Keywords</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="keywords"
          name="keywords"
          v-model="imageSetDescriptionFormData.keywords"
        />
        <ErrorMessage name="keywords" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="language"
          >Sprache</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="select"
          id="language"
          name="language"
          v-model="imageSetDescriptionFormData.language"
        >
          <option value="">Wähle die Sprache</option>
          <option
            v-for="(language, index) in languageSelect"
            :key="index"
            :value="language.code"
            >{{ language.name }}
          </option>
        </Field>
        <ErrorMessage name="language" class="text-red-600" />
      </div>
      <div class="flex justify-between mt-6">
        <GoBackButton />
        <button
          type="submit"
          class="px-4 py-2 bg-gray-800 text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
        >
          Speichern !
        </button>
      </div>
    </Form>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

import GoBackButton from './GoBackButton.vue'
import { languageSelect } from '@/use/languageSelectData'
import { imageSetDescriptionSchema } from '@/schemas/imageSetDescriptionSchema'
import { ImageSetDescription } from '@/interfaces/ImageSetDescription'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'
import { imagesSetsService } from '@/services/ImagesSets.service'

export default defineComponent({
  name: 'ImagesSetDescriptionForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    LoadingSpinner,
    GoBackButton,
    Alerts
  },
  created() {
    this.image_set_id = this.$route.params.image_set_id
    this.image_set_description_id = this.$route.params.image_set_description_id
    if (this.image_set_description_id) {
      //this.$refs.loadingSpinner.open()
      this.getImageSetDescriptionData(this.image_set_description_id)
    }
  },
  data() {
    const image_set_description_id: number = null
    const image_set_id: number = null
    return {
      image_set_description_id,
      image_set_id,
      languageSelect,
      imageSetDescriptionSchema,
      alertType: null,
      imageSetDescriptionFormData: {} as ImageSetDescription,
      initialFormValues: {} as ImageSetDescription
    }
  },
  methods: {
    async saveImageSetDescription(data) {
      if (!this.image_set_description_id) {
        await imagesSetsService
          .createImageSetDescription(this.image_set_id, data)
          .then(response => {
            this.showSuccessAlert(
              'Image Set Beschreibung erfolgreich gespeichert!'
            )
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      } else {
        await imagesSetsService
          .updateImageSetDescription(this.image_set_description_id, data)
          .then(response => {
            this.showSuccessAlert(
              'Image Set Beschreibung erfolgreich gespeichert!'
            )
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      }
    },
    async getImageSetDescriptionData(image_set_description_id: number) {
      await imagesSetsService
        .getImageSetDescription(image_set_description_id)
        .then(response => {
          this.initialFormValues = ref<ImageSetDescription>({
            title: response.data.data.title,
            description: response.data.data.description,
            keywords: response.data.data.keywords,
            language: response.data.data.language
          })
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>

<style scoped></style>
