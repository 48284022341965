
import {defineComponent, ref} from 'vue'

import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'

import {modelsDataService} from '@/services/ModelsData.service'
import LoadingSpinner from './lib/LoadingSpinner.vue'
import Alerts from '../components/lib/Alerts.vue'
import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import {ModelsData} from '@/interfaces/ModelsData'

import {genderSelect} from '@/use/genderSelect'
import {nationalitySelect} from '@/use/nationalitySelect'
import {cupSizeSelect} from '@/use/cupSizeSelect'
import {zodiacSelect} from '@/use/zodiacSelect'
import {typeSelect} from '@/use/typeSelect'
import {genitalAreaSelect} from '@/use/genitalAreaSelect'
import {preferencesSelect} from '@/use/preferencesSelect'
import Multiselect from '@vueform/multiselect'

export default defineComponent({
  name: 'AddModelForm',
  components: {
    Multiselect,
    GoBackButton,
    SaveButton,
    LoadingSpinner,
    Alerts
  },
  data() {
    const v$ = useVuelidate()
    const model_id: number = null
    return {
      model_id,
      modelFormData: {} as ModelsData,
      alertType: null,
      genderSelect,
      nationalitySelect,
      cupSizeSelect,
      zodiacSelect,
      typeSelect,
      genitalAreaSelect,
      preferencesSelect,
      v$
    }
  },
  created() {
    this.model_id = this.$route.params.model_id
    if (this.model_id) {
      this.getModelData(this.model_id)
    }
  },

  methods: {
    async saveModelData() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return
      }
      if (!this.model_id) {
        this.$refs.loadingSpinner.open()
        await modelsDataService
            .createModel(this.modelFormData)
            .then(response => {
              this.$refs.loadingSpinner.close()
              this.showSuccessAlert('Model erfolgreich zugefügt!')
              return response.data
            })
            .catch(error => {
              this.$refs.loadingSpinner.close()
              this.showErrorAlert(error.message)
            })
      } else {
        await modelsDataService
            .updateModel(this.model_id, this.modelFormData)
            .then(response => {
              this.$refs.loadingSpinner.close()
              this.showSuccessAlert('Model erfolgreich upgedatet!')
              return response.data
            })
            .catch(error => {
              this.$refs.loadingSpinner.close()
              this.showErrorAlert(error.message)
            })
      }
    },
    async getModelData(model_id: number) {
      await modelsDataService
          .getModel(model_id)
          .then(response => {
            this.modelFormData = ref<ModelsData>({
              alias: response.data.data.alias,
              slug: response.data.data.slug,
              firstname: response.data.data.firstname,
              lastname: response.data.data.lastname,
              gender: response.data.data.gender,
              age: response.data.data.age,
              nationality: response.data.data.nationality,
              height: response.data.data.height,
              cup_size: response.data.data.cup_size,
              weight: response.data.data.weight,
              zodiac: response.data.data.zodiac,
              type: response.data.data.type,
              genital_area: response.data.data.genital_area,
              preferences: JSON.parse(response.data.data.preferences)
            })
            this.$refs.loadingSpinner.close()
            return this.modelFormData
          })
          .catch(error => {
            this.showErrorAlert(error)
            console.log(error)
          })
    },
    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  },
  validations() {
    return {
      modelFormData: {
        alias: {required},
        slug: {},
        firstname: {required},
        lastname: {required},
        gender: {required},
        age: {required},
        nationality: {required},
        height: {required},
        cup_size: {required},
        weight: {required},
        zodiac: {required},
        type: {required},
        genital_area: {required},
        preferences: {required}
      }
    }
  }
})
