
import { defineComponent, ref } from 'vue'
import { useSidebar } from '@/hooks/useSidebar'

export default defineComponent({
  name: 'Sidebar',
  setup() {
    const { isOpen } = useSidebar()
    const activeClass = ref(
      'bg-gray-600 bg-opacity-25 text-gray-100 border-gray-100'
    )
    const inactiveClass = ref(
      'border-gray-900 text-gray-500 hover:bg-gray-600 hover:bg-opacity-25 hover:text-gray-100'
    )
    return {
      isOpen,
      activeClass,
      inactiveClass
    }
  }
})
