
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'ImagesSetsDataFilter',
  props: {
    perPageSelected: {
      type: Number,
      default: () => 25
    },
    perPageValues: {
      type: Array,
      default: () => [10, 25, 50]
    },
    placeholderSearch: {
      type: String,
      default: 'Search Table'
    }
  },
  data() {
    const search = this.$route.query.search ? this.$route.query.search : null
    return {
      search,
      selectedValue: null
    }
  },
  watch: {
    async search(searchString) {
      if (searchString.length > 0 && searchString.trim()) {
        this.search = searchString
      }
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          search: this.search,
          page: 1
        }
      })
      await this.$parent.getImagesSetsData(this.search)
    }
  },
  created() {
    this.setSelectedValue()
  },
  methods: {
    ...mapActions({
      setPerPageAction: 'paginationSettings/setPerPage'
    }),
    setSelectedValue() {
      this.selectedValue = this.perPageSelected
    },
    setPerPage(event) {
      this.setPerPageAction(event.target.value)
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          perPage: event.target.value,
          page: 1
        }
      })
      this.$parent.perPage = event.target.value
      this.$parent.getImagesSetsData()
    },

    onChangePerPage(event) {
      this.setPerPage(event)
    }
  }
})
