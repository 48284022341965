
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  name: 'Login',
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      error: null
    }
  },
  methods: {
    ...mapActions({
      loginAction: 'auth/login'
    }),
    ...mapGetters({
      loggedUserGetter: 'auth/user'
    }),
    async login() {
      await this.loginAction(this.form)
        .then(() => {
          console.log(this.loggedUserGetter().role)
          const allowedRoles = ['admin', 'super_admin']
          if (!allowedRoles.includes(this.loggedUserGetter().role)) {
            this.$router.push({ name: 'forbidden' })
          } else {
            this.$router.push({ name: 'dashboard' })
          }
        })
        .catch(error => {
          this.error = error.response.data.message
        })
    }
  }
})
