<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">
      Model Daten
    </h2>
    <form @submit.prevent="savePaymentPackageData">
      <div class="mt-2">
        <label
          class="inline-block text-sm mb-2 text-gray-700"
          for="epoch_pi_code"
          >Epoch PI Code</label
        >
        <input
          class="w-full border-2 h-10 px-3 rounded-md focus:border-indigo-600"
          :class="
            v$.form.epoch_pi_code.$error ? 'border-red-600' : 'border-gray-200'
          "
          type="text"
          id="epoch_pi_code"
          name="epoch_pi_code"
          v-model="form.epoch_pi_code"
        />
        <span v-if="v$.form.epoch_pi_code.$error" class="text-red-600">{{
          v$.form.epoch_pi_code.$errors[0].$message
        }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="amount"
          >Preis gesamt</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          :class="v$.form.amount.$error ? 'border-red-600' : 'border-gray-200'"
          type="number"
          step="0.01"
          id="amount"
          name="amount"
          v-model="form.amount"
        />
        <span v-if="v$.form.amount.$error" class="text-red-600">{{
          v$.form.amount.$errors[0].$message
        }}</span>
      </div>
      <div class="mt-2">
        <label
          class="inline-block text-sm mb-2 text-gray-700"
          for="amount_month"
          >Preis pro Monat</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          :class="
            v$.form.amount_month.$error ? 'border-red-600' : 'border-gray-200'
          "
          type="number"
          step="0.01"
          id="amount_month"
          name="amount_month"
          v-model="form.amount_month"
        />
        <span v-if="v$.form.amount_month.$error" class="text-red-600">{{
          v$.form.amount_month.$errors[0].$message
        }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="amount_day"
          >Preis pro Tag</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          :class="
            v$.form.amount_day.$error ? 'border-red-600' : 'border-gray-200'
          "
          type="number"
          step="0.01"
          id="amount_day"
          name="amount_day"
          v-model="form.amount_day"
        />
        <span v-if="v$.form.amount_day.$error" class="text-red-600">{{
          v$.form.amount_day.$errors[0].$message
        }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="amount_day"
          >Vorheriger Preis</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          :class="
            v$.form.price_before.$error ? 'border-red-600' : 'border-gray-200'
          "
          type="number"
          step="0.01"
          id="price_before"
          name="price_before"
          v-model="form.price_before"
        />
        <span v-if="v$.form.price_before.$error" class="text-red-600">{{
          v$.form.price_before.$errors[0].$message
        }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="currency"
          >Währung</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          :class="
            v$.form.currency.$error ? 'border-red-600' : 'border-gray-200'
          "
          type="text"
          id="currency"
          name="currency"
          v-model="form.currency"
        />
        <span v-if="v$.form.currency.$error" class="text-red-600">{{
          v$.form.currency.$errors[0].$message
        }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="period"
          >Laufzeit</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          :class="
            v$.form.currency.$error ? 'border-red-600' : 'border-gray-200'
          "
          type="number"
          id="period"
          name="period"
          v-model="form.period"
        />
        <span v-if="v$.form.period.$error" class="text-red-600">{{
          v$.form.period.$errors[0].$message
        }}</span>
      </div>
      <div class="mt-2">
        <label
          class="inline-block text-sm mb-2 text-gray-700"
          for="is_payment_site"
          >Ist auf der Payment Seite aktiv?</label
        >
        <select
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          :class="
            v$.form.is_payment_site.$error
              ? 'border-red-600'
              : 'border-gray-200'
          "
          id="is_payment_site"
          name="is_payment_site"
          v-model="form.is_payment_site"
        >
          <option value="">Payment Seite aktiv?</option>
          <option
            v-for="(valueVariable, index) in booleanSelect"
            :key="index"
            :value="valueVariable"
            >{{ valueVariable }}
          </option>
        </select>
        <span v-if="v$.form.is_payment_site.$error" class="text-red-600">{{
          v$.form.is_payment_site.$errors[0].$message
        }}</span>
      </div>
      <div class="mt-2">
        <label
          class="inline-block text-sm mb-2 text-gray-700"
          for="is_recurring"
          >Ist wiederkehrend?</label
        >
        <select
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          :class="
            v$.form.is_recurring.$error ? 'border-red-600' : 'border-gray-200'
          "
          id="is_recurring"
          name="is_recurring"
          v-model="form.is_recurring"
        >
          <option value="">Wiederkehrend?</option>
          <option
            v-for="(valueVariable, index) in booleanSelect"
            :key="index"
            :value="valueVariable"
            >{{ valueVariable }}
          </option>
        </select>
        <span v-if="v$.form.is_recurring.$error" class="text-red-600">{{
          v$.form.is_recurring.$errors[0].$message
        }}</span>
      </div>
      <div class="mt-2">
        <label
          class="inline-block text-sm mb-2 text-gray-700"
          for="is_highlight"
          >Ist highlight?</label
        >
        <select
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          :class="
            v$.form.is_highlight.$error ? 'border-red-600' : 'border-gray-200'
          "
          id="is_highlight"
          name="is_highlight"
          v-model="form.is_highlight"
        >
          <option value="">Highlight?</option>
          <option
            v-for="(valueVariable, index) in booleanSelect"
            :key="index"
            :value="valueVariable"
            >{{ valueVariable }}
          </option>
        </select>
        <span v-if="v$.form.is_highlight.$error" class="text-red-600">{{
          v$.form.is_highlight.$errors[0].$message
        }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="is_active"
          >Ist active?</label
        >
        <select
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          :class="
            v$.form.is_active.$error ? 'border-red-600' : 'border-gray-200'
          "
          id="is_active"
          name="is_active"
          v-model="form.is_active"
        >
          <option value="">Active?</option>
          <option
            v-for="(valueVariable, index) in booleanSelect"
            :key="index"
            :value="valueVariable"
            >{{ valueVariable }}
          </option>
        </select>
        <span v-if="v$.form.is_active.$error" class="text-red-600">{{
          v$.form.is_active.$errors[0].$message
        }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="price_info"
          >Preis info als JSON</label
        >
        <textarea
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          :class="
            v$.form.price_info.$error ? 'border-red-600' : 'border-gray-200'
          "
          id="price_info"
          name="price_info"
          v-model="form.price_info"
        />
        <span v-if="v$.form.price_info.$error" class="text-red-600">{{
          v$.form.price_info.$errors[0].$message
        }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="order"
          >Reihenfolge auf der Payment Seite</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          :class="v$.form.order.$error ? 'border-red-600' : 'border-gray-200'"
          type="number"
          id="order"
          name="order"
          v-model="form.order"
        />
        <span v-if="v$.form.order.$error" class="text-red-600">{{
          v$.form.order.$errors[0].$message
        }}</span>
      </div>
      <div class="flex justify-between mt-6">
        <GoBackButton />
        <SaveButton :disabled="v$.$invalid" />
      </div>
    </form>
  </div>
  <Alerts ref="alert" :alert-type="alertType" />
</template>

<script lang="ts">
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import GoBackButton from '@/components/GoBackButton.vue'
import SaveButton from '@/components/SaveButton.vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import PaymentPackages from '@/interfaces/PaymentPackages'
import { paymentPackagesService } from '@/services/PaymentPackages.Service'

export default defineComponent({
  name: 'PaymentPackagesDataForm',
  components: {
    GoBackButton,
    SaveButton,
    LoadingSpinner,
    Alerts
  },
  setup() {
    const alertType = ref<string>(null)
    const alert = ref(null)
    const loadingSpinner = ref(null)
    const route = useRoute()
    const paymentPackageId = route.params.payment_package_id
    const form = ref<PaymentPackages>({
      epoch_pi_code: '',
      amount: 0,
      amount_month: 0,
      amount_day: 0,
      currency: 'EUR',
      period: 30,
      is_payment_site: false,
      is_recurring: false,
      is_highlight: false,
      is_active: false,
      price_before: 0,
      price_info: '',
      order: 0
    })
    const booleanSelect = [false, true]
    const state = reactive({
      form
    })
    const rules: any = computed(() => {
      return {
        form: {
          epoch_pi_code: { required, $autoDirty: true },
          amount: { required, $autoDirty: true },
          amount_month: { required, $autoDirty: true },
          amount_day: { required, $autoDirty: true },
          currency: { required, $autoDirty: true },
          period: { required, $autoDirty: true },
          is_payment_site: { required, $autoDirty: true },
          is_recurring: { required, $autoDirty: true },
          is_highlight: { required, $autoDirty: true },
          is_active: { required, $autoDirty: true },
          price_before: { required, $autoDirty: true },
          price_info: { required, $autoDirty: true },
          order: { required, $autoDirty: true }
        }
      }
    })
    const v$ = useVuelidate(rules, state)

    onMounted(async () => {
      if (paymentPackageId) {
        await getPaymentData()
      }
    })

    const savePaymentPackageData = async () => {
      if (!paymentPackageId) {
        loadingSpinner.value.open()
        form.value.price_info = JSON.parse(form.value.price_info)
        await paymentPackagesService
          .createPaymentPackage(form.value)
          .then(response => {
            loadingSpinner.value.close()
            showSuccessAlert('Payment Packet erfolgreich zugefügt!')
            return response.data
          })
          .catch(error => {
            console.log(error.response.data.data.message)
            loadingSpinner.value.close()
            showErrorAlert(
              error.message + ' - ' + error.response.data.data.message
            )
          })
      } else {
        loadingSpinner.value.open()
        await paymentPackagesService
          .updatePaymentPackage(paymentPackageId, form.value)
          .then(response => {
            loadingSpinner.value.close()
            showSuccessAlert('Payment Packet erfolgreich upgedatet!')
            return response.data
          })
          .catch(error => {
            console.log(error.response.data.data.message)
            loadingSpinner.value.close()
            showErrorAlert(
              error.message + ' - ' + error.response.data.data.message
            )
          })
      }
    }

    const getPaymentData = async () => {
      loadingSpinner.value.open()
      await paymentPackagesService
        .getPaymentPacketData(paymentPackageId)
        .then(response => {
          form.value = response.data.data
          form.value.is_payment_site = response.data.data.is_payment_site === 1
          form.value.is_recurring = response.data.data.is_recurring === 1
          form.value.is_highlight = response.data.data.is_highlight === 1
          form.value.is_active = response.data.data.is_active === 1
          loadingSpinner.value.close()
        })
        .catch(error => {
          showErrorAlert(error)
          console.log(error)
        })
    }

    const showSuccessAlert = (message: string) => {
      alertType.value = 'success'
      alert.value.open(message)
      setTimeout(() => {
        alert.value.close()
        alertType.value = null
      }, 5000)
    }

    const showErrorAlert = (message: string) => {
      alertType.value = 'error'
      alert.value.open(message)
      setTimeout(() => {
        alert.value.close()
        alertType.value = null
      }, 5000)
    }

    return {
      alertType,
      alert,
      loadingSpinner,
      form,
      booleanSelect,
      v$,
      savePaymentPackageData
    }
  }
})
</script>

<style scoped></style>
