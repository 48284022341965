import axios from 'axios'
import { Series } from '@/interfaces/Series'
import { SeriesDescription } from '@/interfaces/SeriesDescrition'

class SeriesService {
  async getSeriesData() {
    return await axios.get(`api/series`)
  }

  async getASeriesData(id: number) {
    return axios.get(`api/series/${id}`)
  }

  async deleteSeries(id: number) {
    return await axios.delete(`api/series/${id}`)
  }

  async createSeries(series: Array<Series>) {
    return await axios.post('api/series', series)
  }

  async updateSeriesData(id: number, series: Array<Series>) {
    return await axios.put(`api/series/${id}`, series)
  }

  async createSeriesDescription(
    series_id: number,
    seriesDescription: Array<SeriesDescription>
  ) {
    return await axios.post(
      `api/series/series_description/${series_id}`,
      seriesDescription
    )
  }

  async getSeriesDescription(series_description_id: string | string[]) {
    return await axios.get(
      `api/series/series_description/${series_description_id}`
    )
  }

  async updateSeriesDescription(
    series_description_id: string | string[],
    series_description: SeriesDescription
  ) {
    return await axios.put(
      `api/series/series_description/${series_description_id}`,
      series_description
    )
  }
}
export const seriesService = new SeriesService()
