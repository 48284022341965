
import { defineComponent, watchEffect } from 'vue'
import LoadingSpinner from '../components/lib/LoadingSpinner.vue'
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import StaticSitesTableHeader from '../components/StaticSitesTableHeader.vue'
import StaticSitesTable from '../components/StaticSitesTable.vue'
import { staticSitesService } from '@/services/StaticSites.service'

export default defineComponent({
  name: 'StaticSites',
  components: {
    LoadingSpinner,
    ConfirmDialogue,
    StaticSitesTableHeader,
    StaticSitesTable
  },
  data() {
    return {
      staticSites: null,
      columns: [
        {
          key: 'title',
          label: 'Tietel'
        },
        {
          key: 'language',
          label: 'Sprache'
        }
      ]
    }
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    watchEffect(() => {
      this.getStaticSitesData()
    })
  },
  methods: {
    async getStaticSitesData() {
      await staticSitesService
        .getStaticSites()
        .then(response => {
          this.staticSites = response.data
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
        })
    },
    async deleteStaticSite(id: number) {
      if (id) {
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Statische Seite löschen',
          message:
            'Bist du sicher, dass du die Statische Seite löschen möchtest.',
          okButton: 'Löschen'
        })
        if (ok) {
          this.$refs.loadingSpinner.open()
          await staticSitesService.deleteStaticSite(id)
          await this.getStaticSitesData()
        }
      }
    }
  }
})
