
import { defineComponent } from 'vue'
import dateTimeFormatter from '../filters/dateTimeFormatter'

export default defineComponent({
  name: 'UserTransactionsTable',
  props: {
    transactionData: Array,
    columns: Array
  },
  data() {
    const sortBy = 'created_at'
    const sortDirection = 'ASC'
    return {
      sortBy: sortBy,
      sortDirection: sortDirection,
      transactionList: this.transactionData
    }
  },
  computed: {
    showTransactions() {
      const list = this.transactionList
      list.sort((a: Array<string>, b: Array<string>) => {
        return (
          (a[this.sortBy] === b[this.sortBy]
            ? 0
            : a[this.sortBy] > b[this.sortBy]
            ? 1
            : -1) * (this.sortDirection === 'ASC' ? 1 : -1)
        )
      })

      return list
    }
  },
  methods: {
    dateTimeFormatter,
    sort(sortBy) {
      if (sortBy === this.sortBy) {
        if (this.sortDirection == 'ASC') {
          this.sortDirection = 'DESC'
        } else {
          this.sortDirection = 'ASC'
        }
      }
      if (sortBy != this.sortBy) {
        this.sortDirection = 'ASC'
        this.sortBy = sortBy
      }
    }
  }
})
