
import { defineComponent, ref } from 'vue'
import { usersService } from '@/services/Users.service'
import { UserPassword } from '@/interfaces/UserPassword'

import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'

export default defineComponent({
  name: 'PasswordUpdateForm',
  components: {
    LoadingSpinner,
    SaveButton,
    GoBackButton,
    Alerts
  },
  created() {
    this.userId = this.$route.params.userId
  },
  data() {
    const userId: string = null
    return {
      userId,
      userPassword: {} as UserPassword,
      alertType: null,
      error_messages: null
    }
  },

  methods: {
    async userPasswordUpdate() {
      if (this.userId) {
        this.$refs.loadingSpinner.open()
        await usersService
          .updateUserPassword(this.userId, this.userPassword)
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('User Passwort updated')
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.error_messages = error.response.data
            this.showErrorAlert(error.message)
          })
      }
    },
    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
