<template>
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="bg-indigo-100">
      <tr>
        <th
          v-for="(column, index) in columns"
          :key="index"
          scope="col"
          class=" px-6 py-3 text-xs font-medium text-indigo-700 uppercase tracking-wider"
          :class="[
            column.sortable ? 'cursor-pointer hover:bg-indigo-200' : '',
            sortBy === column.key && column.sortable ? 'columnActive' : ''
          ]"
          @click="column.sortable ? sort(column.key) : ''"
        >
          <div class="inline-flex flex-wrap w-full">
            <div class="inline-block text-right mr-3 mt-1 left-0">
              {{ column.label }}
            </div>
            <div v-if="column.sortable" class="text-right right-0">
              <svg
                class="w-3 h-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                v-show="sortBy === column.key && sortDirection === 'ASC'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                ></path>
              </svg>
              <svg
                class="w-3 h-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                v-show="sortBy === column.key && sortDirection === 'DESC'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
          </div>
        </th>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium text-indigo-700 uppercase tracking-wider hover:bg-indigo-200"
        >
          <div class="inline-block text-right mr-3 mt-1 left-0">
            Edit
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <tr
        v-for="(row, index) in tableData"
        :key="index"
        class="hover:bg-indigo-200"
      >
        <td class="px-2 py-4 whitespace-nowrap">
          <div class="flex flex-nowrap items-center text-sm">
            {{ row.id }}
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center truncate text-sm">
            {{ this.truncateTableString(row.set_name, 25) }} ...
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center text-sm">
            {{ this.truncateTableString(row.description, 25) }} ...
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-cente text-sm">
            <div v-if="row.series">
              {{ row.series.title }}
            </div>
            <div v-else>
              <span
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-900 text-white text-sm"
              >
                N.A</span
              >
            </div>
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center text-sm">
            {{ this.dateTimeFormatter(row.publish_on) }}
          </div>
        </td>

        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            <div
              v-if="
                row.images_sets_descriptions &&
                  row.images_sets_descriptions.length > 0
              "
            >
              <span
                v-for="(description, index) in row.images_sets_descriptions"
                :key="index"
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
              >
                <router-link
                  :to="{
                    name: 'editImageSetDescription',
                    params: { image_set_description_id: description.id }
                  }"
                >
                  {{ description.language }}
                </router-link>
              </span>
            </div>
            <div v-else>
              <span
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-900 text-white"
              >
                N.A</span
              >
            </div>
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            {{ row.image_sets_count }}
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            <div v-if="row.images_sets_content">
              <router-link
                :to="{
                  name: 'imageSetsContent',
                  params: { image_set_id: row.id }
                }"
                class="text-indigo-600 hover:text-indigo-900 mr-3"
              >
                <img
                  :src="
                    row.images_sets_content.image_preview_url +
                      '/' +
                      row.slug +
                      '/230x150/' +
                      row.images_sets_content.image_name
                  "
                />
              </router-link>
            </div>
          </div>
        </td>
        <td class="flex-col px-6 py-4 text-right text-sm font-medium">
          <div class="w-full text-right">
            <router-link
              :to="{ name: 'editImageSet', params: { image_set_id: row.id } }"
              class="text-indigo-600 hover:text-indigo-900"
              >Bearbeiten
            </router-link>
          </div>
          <div class="w-full text-right">
            <a
              href="#"
              class="text-red-600 hover:text-indigo-900"
              @click.prevent="this.$parent.deleteImageSet(row.id)"
              >Löschen</a
            >
          </div>
          <div class="w-full text-right">
            <router-link
              :to="{
                name: 'addImageSetDescription',
                params: { image_set_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900"
              >Beschreibung
            </router-link>
          </div>
          <div class="w-full text-right">
            <router-link
              :to="{
                name: 'imageSetsContentUpload',
                params: { image_set_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900"
              >Bilder hochladen
            </router-link>
          </div>
          <div
            class="w-full text-right"
            v-if="row.gallery_url && row.image_sets_count > 0"
          >
            <a
              :href="row.gallery_url"
              target="_blank"
              class="text-indigo-600 hover:text-indigo-900"
              >Member Galerie Vorschau
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import truncateTableString from '@/filters/truncateTableString'
import dateTimeFormatter from '@/filters/dateTimeFormatter'

export default defineComponent({
  name: 'ImagesSetsTable',
  props: {
    tableData: Object,
    columns: Array
  },
  data() {
    const sortBy = 'id'
    const sortDirection = 'DESC'
    return {
      sortBy: sortBy,
      sortDirection: sortDirection
    }
  },
  methods: {
    truncateTableString,
    dateTimeFormatter,
    sort(column) {
      if (column === this.sortBy) {
        if (this.sortDirection == 'ASC') {
          this.sortDirection = 'DESC'
        } else {
          this.sortDirection = 'ASC'
        }
      }
      if (column != this.sortBy) {
        this.sortDirection = 'ASC'
        this.sortBy = column
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          sortDirection: this.sortDirection,
          sortBy: this.sortBy,
          page: 1
        }
      })
      this.$parent.sortBy = this.sortBy
      this.$parent.sortDirection = this.sortDirection
    }
  }
})
</script>

<style>
.columnActive {
  @apply bg-indigo-400;
  @apply text-white !important;
}
</style>
