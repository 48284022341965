
import { defineComponent, PropType } from 'vue'
import truncateTableString from '@/filters/truncateTableString'

export default defineComponent({
  name: 'SiteDescriptionTable',
  props: {
    tableData: {
      type: Object
    },
    columns: Array as PropType<Array<string>>
  },
  methods: {
    truncateTableString
  }
})
