
import { defineComponent, ref } from 'vue'
import * as yup from 'yup'

import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import { languageSelect } from '@/use/languageSelectData'
import Alerts from '@/components/lib/Alerts.vue'
import GoBackButton from '@/components/GoBackButton.vue'
import { seriesService } from '@/services/Series.service'
import { SeriesDescription } from '@/interfaces/SeriesDescrition'

export default defineComponent({
  name: 'EditSeriesDescription',
  components: {
    LoadingSpinner,
    Alerts,
    GoBackButton
  },

  data() {
    const series_description_id: string | string[] = null
    const schema = yup.object({
      language: yup
        .string()
        .required()
        .max(2),
      title: yup
        .string()
        .required()
        .min(3),
      description: yup
        .string()
        .required()
        .min(3),
      keywords: yup
        .string()
        .required()
        .min(3)
    })
    return {
      series_description_id,
      languageSelect,
      seriesDescriptionFormData: {} as SeriesDescription,
      alertType: null,
      schema
    }
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    this.series_description_id = this.$route.params.series_description_id
    this.getDescription(this.series_description_id)
  },
  methods: {
    async getDescription(series_description_id: string | string[]) {
      await seriesService
        .getSeriesDescription(series_description_id)
        .then(response => {
          this.seriesDescriptionFormData = ref<SeriesDescription>({
            language: response.data.language,
            title: response.data.title,
            description: response.data.description,
            keywords: response.data.keywords
          })
          this.$refs.loadingSpinner.close()
          return this.seriesDescriptionFormData
        })
        .catch(error => {
          this.$refs.loadingSpinner.close()
          this.showErrorAlert(error.message)
        })
    },
    async updateSeriesDescription() {
      this.$refs.loadingSpinner.open()
      await seriesService
        .updateSeriesDescription(
          this.series_description_id,
          this.seriesDescriptionFormData
        )
        .then(response => {
          this.seriesDescriptionFormData = ref<SeriesDescription>({
            language: response.data.language,
            title: response.data.title,
            description: response.data.description,
            keywords: response.data.keywords
          })
          this.$refs.loadingSpinner.close()
          this.showSuccessAlert('Serie wurde upgedatet')
          return this.seriesDescriptionFormData
        })
        .catch(error => {
          this.showErrorAlert(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
