import axios from 'axios'
import { VideoSets } from '@/interfaces/VideoSets'
import { VideosSetsDescriptions } from '@/interfaces/VideosSetsDescriptions'

class VideosSetsService {
  async getVideosSets(
    perPage: number = null,
    page: number = null,
    search: string = null,
    sortBy: string = null,
    sortDirection: string = null,
    seriesId: string = null
  ) {
    if (search && !sortBy && !seriesId) {
      return await axios.get(
        `api/videos_sets?per_page=${perPage}&page=${page}&search=${search}`
      )
    }
    if (sortBy && search && !seriesId) {
      return await axios.get(
        `api/videos_sets?per_page=${perPage}&page=${page}&search=${search}&sortBy=${sortBy}&sortDirection=${sortDirection}`
      )
    }
    if (sortBy && !search && !seriesId) {
      return await axios.get(
        `api/videos_sets?per_page=${perPage}&page=${page}&sortBy=${sortBy}&sortDirection=${sortDirection}`
      )
    }
    if (sortBy && search && seriesId) {
      return await axios.get(
        `api/videos_sets/filtered_by_series?per_page=${perPage}&page=${page}&search=${search}&series_id=${seriesId}&sortBy=${sortBy}&sortDirection=${sortDirection}`
      )
    }
    if (sortBy && !search && seriesId) {
      return await axios.get(
        `api/videos_sets/filtered_by_series?per_page=${perPage}&page=${page}&series_id=${seriesId}&sortBy=${sortBy}&sortDirection=${sortDirection}`
      )
    }
    if (!sortBy && search && seriesId) {
      return await axios.get(
        `api/videos_sets/filtered_by_series?per_page=${perPage}&page=${page}&search=${search}&series_id=${seriesId}&sortDirection=${sortDirection}`
      )
    }
    if (!sortBy && !search && seriesId) {
      return await axios.get(
        `api/videos_sets/filtered_by_series?per_page=${perPage}&page=${page}&series_id=${seriesId}`
      )
    }

    return axios.get(`api/videos_sets?per_page=${perPage}&page=${page}`)
  }
  async getVideoSetData(id: number) {
    return await axios.get(`api/videos_sets/${id}`)
  }

  async createVideoSet(videoSets: Array<VideoSets>) {
    return await axios.post('api/videos_sets', videoSets)
  }

  async updateVideoSet(id: number, videoSets: Array<VideoSets>) {
    return await axios.put(`api/videos_sets/${id}`, videoSets)
  }

  async deleteVideosSet(id: number) {
    return await axios.delete(`api/videos_sets/${id}`)
  }

  async createVideoSetDescription(
    videoSetId: number,
    imageSetDescription: Array<VideosSetsDescriptions>
  ) {
    return await axios.post(
      `api/videos_sets_description/${videoSetId}`,
      imageSetDescription
    )
  }
  async updateVideoSetDescription(
    imageSetDescriptionId: number,
    imageSetDescription: Array<VideosSetsDescriptions>
  ) {
    return await axios.put(
      `api/videos_sets_description/${imageSetDescriptionId}`,
      imageSetDescription
    )
  }
  async getVideoSetsDescription(id: number) {
    return await axios.get(`api/videos_sets_description/${id}`)
  }

  async deleteVideoSetPreview(id: number) {
    return await axios.delete(`api/videos_sets_preview/delete_preview/${id}`)
  }
}

export const videosSetsService = new VideosSetsService()
