<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="flex items-center justify-center">
    <GoBackButton />
  </div>
  <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-2">
      <div v-for="(row, index) in modelPreviewImages" :key="index">
        <v-lazy-image
          v-if="slug"
          :src="
            row.actor_preview_url + '/' + slug + '/230x150/' + row.image_name
          "
          @click.prevent="
            this.openLightBox(row.actor_preview_url, slug, row.image_name)
          "
          class="cursor-pointer"
        />
        <div v-else>Loading...</div>
        <div
          class="details flex text-sm items-center justify-center bg-gray-300 pt-1 pb-1"
        >
          <div
            class="mr-2 cursor-pointer"
            @click.prevent="this.moveToTrash(row.id)"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex items-center justify-center">
    <GoBackButton />
  </div>
  <LightBox ref="lightBox"></LightBox>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from 'vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import LightBox from '@/components/LightBox.vue'
import GoBackButton from '@/components/GoBackButton.vue'
import { modelsDataService } from '@/services/ModelsData.service'
import VLazyImage from 'v-lazy-image'
export default defineComponent({
  name: 'ModelPreviewImages',
  components: {
    LoadingSpinner,
    LightBox,
    GoBackButton,
    VLazyImage
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    watchEffect(() => {
      this.getPreviewImages(parseInt(this.model_id))
    })
  },
  data() {
    return {
      modelPreviewImages: null,
      model_id: this.$route.params.model_id,
      slug: null
    }
  },
  methods: {
    async getPreviewImages(model_id: number) {
      await modelsDataService
        .getModelPreviewImages(model_id)
        .then(response => {
          console.log(response.data)
          this.modelPreviewImages = response.data.data
          this.getModelDataForSlug(model_id)
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
          this.$refs.loadingSpinner.close()
        })
    },
    async getModelDataForSlug(model_id: number) {
      await modelsDataService
        .getModel(model_id)
        .then(response => {
          this.slug = response.data.data.slug
          console.log(this.slug)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async openLightBox(
      imagePreviewUrl: string,
      slug: string,
      imageName: string
    ) {
      await this.$refs.lightBox.show({ imagePreviewUrl, slug, imageName })
    },
    async moveToTrash(preview_id: number) {
      await modelsDataService
        .deletePreviewImages(this.model_id, preview_id)
        .then(response => {
          console.log(response.data)
          this.getPreviewImages(this.model_id)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
})
</script>

<style scoped></style>
