export const userRoles = [
  {
    name: 'Super Admin',
    value: 'super_admin'
  },
  {
    name: 'Admin',
    value: 'admin'
  },
  {
    name: 'Pay user',
    value: 'pay_user'
  },
  {
    name: 'Standard user',
    value: 'user'
  }
]
