<template>
  <div class="mb-3 flex flex-wrap justify-between">
    <div class="w-1/8 ml-2 mt-3">
      <select
        v-model="selectedValue"
        @change="onChangePerPage($event)"
        class="h-full rounded border block w-full bg-white border-gray-400 text-gray-700 py-2 px-2 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      >
        <option
          :key="index"
          :value="records"
          v-for="(records, index) in perPageValues"
        >
          {{ records }}
        </option>
      </select>
    </div>
    <div class="relative w-1/4 mt-3 mr-2">
      <span
        class="absolute inset-y-0 right-1 flex items-center pl-2 text-gray-400"
      >
        <button
          type="submit"
          class="p-1 focus:outline-none focus:shadow-outline"
        >
          <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            class="w-6 h-6"
          >
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </button>
      </span>
      <input
        v-model.lazy="searchString"
        class="w-full px-4 py-2 focus:bg-gray-300 max-w-full focus:outline-none border border-gray-200 rounded text-gray-700"
        name="search"
        :placeholder="placeholderSearch"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </div>
    <div class="relative w-1/8 mt-3 mr-2 content-end">
      <button
        @click="$router.push({ name: 'addPaymentPackages' })"
        class="px-4 py-2 bg-indigo-600 hover:bg-indigo-500 text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
      >
        Payment Packet zufügen !
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
export default defineComponent({
  name: 'PaymentPackagesFilter',
  props: {
    perPageSelected: {
      type: Number,
      default: () => 25
    },
    perPageValues: {
      type: Array,
      default: () => [10, 25, 50]
    },
    placeholderSearch: {
      type: String,
      default: 'Search Table'
    }
  },
  setup(props, { emit }) {
    //const instance = getCurrentInstance();
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const searchString = ref<string>(null)
    const selectedValue = ref<number>()
    const getPerPage = computed(
      () => store.getters['paginationSettings/getPerPage']
    )
    selectedValue.value = getPerPage.value ?? props.perPageSelected

    const onChangePerPage = event => {
      store.dispatch('paginationSettings/setPerPage', event.target.value)
    }
    watch(searchString, searchString => {
      let search = null
      if (searchString.length > 0 && searchString.trim()) {
        search = searchString
      }
      router.push({
        path: route.path,
        query: {
          ...route.query,
          search: search,
          page: 1
        }
      })
      emit('search')
    })

    return {
      searchString,
      selectedValue,
      onChangePerPage
    }
  }
})
</script>

<style scoped></style>
