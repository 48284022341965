
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Pagination',
  props: {
    routeName: null,
    pagination: null,
    searchString: null,
    sortBy: null,
    sortDirection: null,
    pageRange: {
      type: Number,
      default: 2
    },
    seriesId: null
  },

  methods: {
    hasPreview() {
      return this.pagination.current_page > 1
    },
    previewPage() {
      return this.pagination.current_page - 1
    },
    hasFirst() {
      return this.rangeStart() !== 1
    },
    hasLast() {
      return this.rangeEnd() < this.pagination.last_page
    },
    rangeStart() {
      const start = this.pagination.current_page - this.pageRange
      return start > 0 ? start : 1
    },
    rangeEnd() {
      const end = this.pagination.current_page + this.pageRange
      return end < this.pagination.last_page ? end : this.pagination.last_page
    },
    pages() {
      const pages = []
      for (let i = this.rangeStart(); i <= this.rangeEnd(); i++) {
        pages.push(i)
      }
      return pages
    }
  }
})
