import axios from 'axios'
import { ImageSettings } from '@/interfaces/ImageSettings'

class ImageSettingsService {
  async getImageSettings() {
    return await axios.get(`api/settings/image_settings`)
  }

  async updateImageSettings(id: number, imageSettings: Array<ImageSettings>) {
    return await axios.put(`api/settings/image_settings/${id}`, imageSettings)
  }
}

export const imageSettingsService = new ImageSettingsService()
