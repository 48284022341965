<template>
  <StaticSitesForm />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import StaticSitesForm from '../components/StaticSitesForm.vue'
export default defineComponent({
  name: 'StaticSitesData',
  components: {
    StaticSitesForm
  }
})
</script>

<style scoped></style>
