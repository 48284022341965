
import { defineComponent } from 'vue'
import VideosSetsPreviewUploader from '@/components/VideosSetsPreviewUploader.vue'
import GoBackButton from '@/components/GoBackButton.vue'

export default defineComponent({
  name: 'VideoSetsPreviewUpload',
  components: {
    GoBackButton,
    VideosSetsPreviewUploader
  },
  data() {
    return {
      video_set_id: this.$route.params.video_set_id
    }
  }
})
