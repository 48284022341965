export const userStatus = [
  {
    name: 'Active',
    value: 'active'
  },
  {
    name: 'Inactive',
    value: 'inactive'
  }
]
