
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'GoBackButton',
  methods: {
    goBack() {
      if (this.$route.query.search || this.$route.query.series_id) {
        return this.$router.go(-2)
      }
      return this.$router.go(-1)
    }
  }
})
