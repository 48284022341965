<template>
  <loading-spinner
      ref="loadingSpinner"
      :animation-duration="1000"
      color="#818CF8"
      :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">
      Model Daten
    </h2>
    <form @submit.prevent="saveModelData">
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="alias"
        >Alias</label
        >
        <input
            class="w-full border-2 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
            v$.modelFormData.alias.$error ? 'border-red-600' : 'border-gray-200'
          "
            type="text"
            id="alias"
            name="alias"
            v-model="modelFormData.alias"
        />
        <span v-if="v$.modelFormData.alias.$error" class="text-red-600">{{
            v$.modelFormData.alias.$errors[0].$message
          }}</span>
      </div>
      <div class="mt-2" v-if="$route.name=='editModelData'">
        <label class="inline-block text-sm mb-2 text-gray-700" for="alias"
        >Slug</label
        >
        <input
            class="w-full border-2 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
            v$.modelFormData.slug.$error ? 'border-red-600' : 'border-gray-200'
          "
            type="text"
            id="slug"
            name="slug"
            v-model="modelFormData.slug"
        />
        <span v-if="v$.modelFormData.slug.$error" class="text-red-600">{{
            v$.modelFormData.slug.$errors[0].$message
          }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="firstname"
        >Vorname</label
        >
        <input
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
            v$.modelFormData.firstname.$error
              ? 'border-red-600'
              : 'border-gray-200'
          "
            type="text"
            id="firstname"
            name="firstname"
            v-model="modelFormData.firstname"
        />
        <span v-if="v$.modelFormData.firstname.$error" class="text-red-600">{{
            v$.modelFormData.firstname.$errors[0].$message
          }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="lastname"
        >Nachname</label
        >
        <input
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
            v$.modelFormData.lastname.$error
              ? 'border-red-600'
              : 'border-gray-200'
          "
            type="text"
            id="lastname"
            name="lastname"
            v-model="modelFormData.lastname"
        />
        <span v-if="v$.modelFormData.lastname.$error" class="text-red-600">{{
            v$.modelFormData.lastname.$errors[0].$message
          }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="gender"
        >Geschlecht</label
        >
        <select
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
            v$.modelFormData.gender.$error
              ? 'border-red-600'
              : 'border-gray-200'
          "
            id="gender"
            name="gender"
            v-model="modelFormData.gender"
        >
          <option value="">Geschlecht</option>
          <option
              v-for="(gender, index) in genderSelect"
              :key="index"
              :value="gender"
          >{{ gender }}
          </option>
        </select>
        <span v-if="v$.modelFormData.gender.$error" class="text-red-600">{{
            v$.modelFormData.gender.$errors[0].$message
          }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="age"
        >Alter</label
        >
        <input
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
            v$.modelFormData.age.$error ? 'border-red-600' : 'border-gray-200'
          "
            type="number"
            id="age"
            name="age"
            v-model="modelFormData.age"
        />
        <span
            v-if="v$.modelFormData.age.$error"
            name="age"
            class="text-red-600"
        >{{ v$.modelFormData.age.$errors[0].$message }}</span
        >
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="nationality"
        >Nationalität</label
        >
        <select
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
            v$.modelFormData.nationality.$error
              ? 'border-red-600'
              : 'border-gray-200'
          "
            id="nationality"
            name="nationality"
            v-model="modelFormData.nationality"
        >
          <option value="">Nationalität</option>
          <option
              v-for="(nationality, index) in nationalitySelect"
              :key="index"
              :value="nationality"
          >{{ nationality }}
          </option>
        </select>
        <span v-if="v$.modelFormData.nationality.$error" class="text-red-600">{{
            v$.modelFormData.nationality.$errors[0].$message
          }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="height"
        >Größe</label
        >
        <input
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
            v$.modelFormData.height.$error
              ? 'border-red-600'
              : 'border-gray-200'
          "
            type="number"
            id="height"
            name="height"
            v-model="modelFormData.height"
        />
        <span v-if="v$.modelFormData.height.$error" class="text-red-600">{{
            v$.modelFormData.height.$errors[0].$message
          }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="cup_size"
        >Körpchen Größe</label
        >
        <select
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
            v$.modelFormData.cup_size.$error
              ? 'border-red-600'
              : 'border-gray-200'
          "
            id="cup_size"
            name="cup_size"
            v-model="modelFormData.cup_size"
        >
          <option value="">Körpchen Größe</option>
          <option
              v-for="(cup_size, index) in cupSizeSelect"
              :key="index"
              :value="cup_size"
          >{{ cup_size }}
          </option>
        </select>
        <span v-if="v$.modelFormData.cup_size.$error" class="text-red-600">{{
            v$.modelFormData.cup_size.$errors[0].$message
          }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="weight"
        >Gewicht</label
        >
        <input
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
            v$.modelFormData.weight.$error
              ? 'border-red-600'
              : 'border-gray-200'
          "
            type="number"
            id="weight"
            name="weight"
            v-model="modelFormData.weight"
        />
        <span v-if="v$.modelFormData.weight.$error" class="text-red-600">{{
            v$.modelFormData.weight.$errors[0].$message
          }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="zodiac"
        >Sternzeichen</label
        >
        <select
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
            v$.modelFormData.zodiac.$error
              ? 'border-red-600'
              : 'border-gray-200'
          "
            id="zodiac"
            v-model="modelFormData.zodiac"
        >
          <option value="">Sternzeichen</option>
          <option v-for="zodiac in zodiacSelect" :key="zodiac" :value="zodiac"
          >{{ zodiac }}
          </option>
        </select>
        <span v-if="v$.modelFormData.zodiac.$error" class="text-red-600">{{
            v$.modelFormData.zodiac.$errors[0].$message
          }}</span>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="type"
        >Type</label
        >
        <select
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
            v$.modelFormData.type.$error ? 'border-red-600' : 'border-gray-200'
          "
            id="type"
            name="type"
            v-model="modelFormData.type"
        >
          <option value="">Type</option>
          <option v-for="type in typeSelect" :key="type" :value="type"
          >{{ type }}
          </option>
        </select>
        <span v-if="v$.modelFormData.type.$error" class="text-red-600">{{
            v$.modelFormData.type.$errors[0].$message
          }}</span>
      </div>
      <div class="mt-2">
        <label
            class="inline-block text-sm mb-2 text-gray-700"
            for="genital_area"
        >Intimbereich</label
        >
        <select
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            :class="
            v$.modelFormData.genital_area.$error
              ? 'border-red-600'
              : 'border-gray-200'
          "
            id="genital_area"
            name="genital_area"
            v-model="modelFormData.genital_area"
        >
          <option value="">Intimbereich</option>
          <option
              v-for="genital_area in genitalAreaSelect"
              :key="genital_area"
              :value="genital_area"
          >{{ genital_area }}
          </option>
        </select>
        <span
            v-if="v$.modelFormData.genital_area.$error"
            class="text-red-600"
        >{{ v$.modelFormData.genital_area.$errors[0].$message }}</span
        >
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="preferences"
        >Vorlieben:
        </label>
        <Multiselect
            :class="
            v$.modelFormData.preferences.$error
              ? 'border-red-600'
              : 'border-gray-200'
          "
            name="preferences"
            v-model="modelFormData.preferences"
            mode="tags"
            placeholder="Wähle Vorlieben aus"
            :searchable="true"
            :options="preferencesSelect"
            :closeOnSelect="false"
            :createTag="true"
        >
        </Multiselect>
        <span v-if="v$.modelFormData.preferences.$error" class="text-red-600">{{
            v$.modelFormData.preferences.$errors[0].$message
          }}</span>
      </div>
      <div class="flex justify-between mt-6">
        <GoBackButton/>
        <SaveButton/>
      </div>
    </form>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType"/>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'

import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'

import {modelsDataService} from '@/services/ModelsData.service'
import LoadingSpinner from './lib/LoadingSpinner.vue'
import Alerts from '../components/lib/Alerts.vue'
import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import {ModelsData} from '@/interfaces/ModelsData'

import {genderSelect} from '@/use/genderSelect'
import {nationalitySelect} from '@/use/nationalitySelect'
import {cupSizeSelect} from '@/use/cupSizeSelect'
import {zodiacSelect} from '@/use/zodiacSelect'
import {typeSelect} from '@/use/typeSelect'
import {genitalAreaSelect} from '@/use/genitalAreaSelect'
import {preferencesSelect} from '@/use/preferencesSelect'
import Multiselect from '@vueform/multiselect'

export default defineComponent({
  name: 'AddModelForm',
  components: {
    Multiselect,
    GoBackButton,
    SaveButton,
    LoadingSpinner,
    Alerts
  },
  data() {
    const v$ = useVuelidate()
    const model_id: number = null
    return {
      model_id,
      modelFormData: {} as ModelsData,
      alertType: null,
      genderSelect,
      nationalitySelect,
      cupSizeSelect,
      zodiacSelect,
      typeSelect,
      genitalAreaSelect,
      preferencesSelect,
      v$
    }
  },
  created() {
    this.model_id = this.$route.params.model_id
    if (this.model_id) {
      this.getModelData(this.model_id)
    }
  },

  methods: {
    async saveModelData() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return
      }
      if (!this.model_id) {
        this.$refs.loadingSpinner.open()
        await modelsDataService
            .createModel(this.modelFormData)
            .then(response => {
              this.$refs.loadingSpinner.close()
              this.showSuccessAlert('Model erfolgreich zugefügt!')
              return response.data
            })
            .catch(error => {
              this.$refs.loadingSpinner.close()
              this.showErrorAlert(error.message)
            })
      } else {
        await modelsDataService
            .updateModel(this.model_id, this.modelFormData)
            .then(response => {
              this.$refs.loadingSpinner.close()
              this.showSuccessAlert('Model erfolgreich upgedatet!')
              return response.data
            })
            .catch(error => {
              this.$refs.loadingSpinner.close()
              this.showErrorAlert(error.message)
            })
      }
    },
    async getModelData(model_id: number) {
      await modelsDataService
          .getModel(model_id)
          .then(response => {
            this.modelFormData = ref<ModelsData>({
              alias: response.data.data.alias,
              slug: response.data.data.slug,
              firstname: response.data.data.firstname,
              lastname: response.data.data.lastname,
              gender: response.data.data.gender,
              age: response.data.data.age,
              nationality: response.data.data.nationality,
              height: response.data.data.height,
              cup_size: response.data.data.cup_size,
              weight: response.data.data.weight,
              zodiac: response.data.data.zodiac,
              type: response.data.data.type,
              genital_area: response.data.data.genital_area,
              preferences: JSON.parse(response.data.data.preferences)
            })
            this.$refs.loadingSpinner.close()
            return this.modelFormData
          })
          .catch(error => {
            this.showErrorAlert(error)
            console.log(error)
          })
    },
    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  },
  validations() {
    return {
      modelFormData: {
        alias: {required},
        slug: {},
        firstname: {required},
        lastname: {required},
        gender: {required},
        age: {required},
        nationality: {required},
        height: {required},
        cup_size: {required},
        weight: {required},
        zodiac: {required},
        type: {required},
        genital_area: {required},
        preferences: {required}
      }
    }
  }
})
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped></style>
