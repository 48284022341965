import * as yup from 'yup'

export const imageSetDescriptionSchema = yup.object({
  title: yup
    .string()
    .required()
    .min(3),
  description: yup
    .string()
    .required()
    .min(3),
  keywords: yup
    .string()
    .required()
    .min(3)
    .max(254),
  language: yup
    .string()
    .required()
    .min(2)
})
