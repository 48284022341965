export const preferencesSelect = [
  { value: 'anal sex', label: 'anal sex' },
  { value: 'BDSM', label: 'BDSM' },
  { value: 'picture exchange', label: 'picture exchange' },
  { value: 'blowjob', label: 'blowjob' },
  { value: 'bondage', label: 'bondage' },
  { value: 'lingerie', label: 'lingerie' },
  { value: 'dirty Talk', label: 'dirty Talk' },
  { value: 'threesome', label: 'threesome' },
  { value: 'erotic chat', label: 'erotic chat' },
  { value: 'exhibitionism', label: 'exhibitionism' },
  { value: 'fetters', label: 'fetters' },
  { value: 'fetish', label: 'fetish' },
  { value: 'FFM', label: 'FFM' },
  { value: 'fisting', label: 'fisting' },
  { value: 'naturism', label: 'naturism' },
  { value: 'photography', label: 'photography' },
  { value: 'foot fetish', label: 'foot fetish' },
  { value: 'gangbang', label: 'gangbang' },
  { value: 'facials', label: 'facials' },
  { value: 'group sex', label: 'group sex' },
  { value: 'handjob', label: 'handjob' },
  { value: 'hardcore', label: 'hardcore' },
  { value: 'rough sex', label: 'rough sex' },
  { value: 'high-heels', label: 'high-heels' },
  { value: 'shaven', label: 'shaven' },
  { value: 'intimate jewelry', label: 'intimate jewelry' },
  { value: 'kamasutra', label: 'kamasutra' },
  { value: 'caresses', label: 'caresses' },
  { value: 'kissing', label: 'kissing' },
  { value: 'rubber & leather', label: 'rubber & leather' },
  { value: 'latex', label: 'latex' },
  { value: 'massages', label: 'massages' },
  { value: 'pee', label: 'pee' },
  { value: 'nylons', label: 'nylons' },
  { value: 'nymphomaniac', label: 'nymphomaniac' },
  { value: 'outdoor', label: 'outdoor' },
  { value: 'paarsex', label: 'paarsex' },
  { value: 'piercings', label: 'piercings' },
  { value: 'pissing', label: 'pissing' },
  { value: 'porn', label: 'porn' },
  { value: 'role playing', label: 'role playing' },
  { value: 'masturbation', label: 'masturbation' },
  { value: 'sex', label: 'sex' },
  { value: 'sex toys', label: 'sex toys' },
  { value: 'SM', label: 'SM' },
  { value: 'cum swallow', label: 'cum swallow' },
  { value: 'cum swapping', label: 'cum swapping' },
  { value: 'squirting', label: 'squirting' },
  { value: 'tantra', label: 'tantra' },
  { value: 'tattoos', label: 'tattoos' },
  { value: 'phone sex', label: 'phone sex' },
  { value: 'toys', label: 'toys' },
  { value: 'vaginal', label: 'vaginal' },
  { value: 'webcam', label: 'webcam' },
  { value: 'watch', label: 'watch' }
]
