<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">User Data</h2>

    <Form @submit="createUser" :validation-schema="schema">
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="name"
          >Name</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="name"
          name="name"
          v-model="userData.name"
        />
        <ErrorMessage name="name" class="text-red-600" />
        <div
          v-if="error_messages && error_messages.errors.name"
          class="text-red-600"
        >
          {{ error_messages.errors.name[0] }}
        </div>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="street"
          >Strasse</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="street"
          name="street"
          v-model="userData.street"
        />
        <ErrorMessage name="street" />
        <div
          v-if="error_messages && error_messages.errors.street"
          class="text-red-600"
        >
          {{ error_messages.errors.street[0] }}
        </div>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="postcode"
          >Postleitzahl</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="postcode"
          name="postcode"
          v-model="userData.postcode"
        />
        <ErrorMessage name="postcode" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="city"
          >Stadt</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="city"
          name="city"
          v-model="userData.city"
        />
        <ErrorMessage name="city" />
        <div
          v-if="error_messages && error_messages.errors.city"
          class="text-red-600"
        >
          {{ error_messages.errors.city[0] }}
        </div>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="country_id"
          >Land</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="select"
          id="country_id"
          name="country_id"
        >
          <option value="">Land</option>
          <option
            v-for="(region, index) in countryRegions"
            :value="region.countryShortCode"
            :key="index"
            >{{ region.countryName }}
          </option>
        </Field>
        <ErrorMessage name="country_id" class="text-red-600" />
        <div
          v-if="error_messages && error_messages.errors.country_id"
          class="text-red-600"
        >
          {{ error_messages.errors.country_id[0] }}
        </div>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="language"
          >Sprache</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="select"
          id="language"
          name="language"
          v-model="userData.language"
        >
          <option value="">Sprache</option>
          <option
            v-for="(language, index) in languageSelect"
            :key="index"
            :value="language.code"
            >{{ language.name }}
          </option>
        </Field>
        <ErrorMessage name="language" class="text-red-600" />
        <div
          v-if="error_messages && error_messages.errors.language"
          class="text-red-600"
        >
          {{ error_messages.errors.language[0] }}
        </div>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="email"
          >E-Mail</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="email"
          id="email"
          name="email"
          v-model="userData.email"
        />
        <ErrorMessage name="email" class="text-red-600" />
        <div
          v-if="error_messages && error_messages.errors.email"
          class="text-red-600"
        >
          {{ error_messages.errors.email[0] }}
        </div>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="access_to"
          >Vollzugang bis</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="datetime-local"
          id="access_to"
          name="access_to"
          v-model="userData.access_to"
        />
        <ErrorMessage name="access_to" />
        <div
          v-if="error_messages && error_messages.errors.access_to"
          class="text-red-600"
        >
          {{ error_messages.errors.access_to[0] }}
        </div>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="status"
          >Status</label
        >

        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="select"
          id="status"
          name="status"
          v-model="userData.status"
        >
          <option value="">User Status</option>
          <option
            v-for="(status, index) in userStatus"
            :key="index"
            :value="status.value"
            >{{ status.name }}</option
          >
        </Field>
        <ErrorMessage name="status" class="text-red-600" />
        <div
          v-if="error_messages && error_messages.errors.status"
          class="text-red-600"
        >
          {{ error_messages.errors.status[0] }}
        </div>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="role"
          >Role</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="select"
          id="role"
          name="role"
          v-model="userData.role"
        >
          <option value="">User role</option>
          <option
            v-for="(role, index) in userRoles"
            :key="index"
            :value="role.value"
            >{{ role.name }}
          </option>
        </Field>
        <ErrorMessage name="role" class="text-red-600" />
        <div
          v-if="error_messages && error_messages.errors.role"
          class="text-red-600"
        >
          {{ error_messages.errors.role[0] }}
        </div>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="period"
          >Period</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="select"
          id="period"
          name="period"
          v-model="userData.period"
        >
          <option value="">Membership period</option>
          <option
            v-for="(membership, index) in memberShipPeriod"
            :key="index"
            :value="membership.value"
            >{{ membership.name }}
          </option>
        </Field>
        <ErrorMessage name="period" class="text-red-600" />
        <div
          v-if="error_messages && error_messages.errors.period"
          class="text-red-600"
        >
          {{ error_messages.errors.period[0] }}
        </div>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="password"
          >Password</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="password"
          id="password"
          name="password"
          v-model="userData.password"
        />
        <ErrorMessage name="password" class="text-red-600" />
        <div
          v-if="error_messages && error_messages.errors.password"
          class="text-red-600"
        >
          {{ error_messages.errors.password[0] }}
        </div>
      </div>
      <div class="flex justify-between mt-6">
        <GoBackButton />
        <SaveButton />
      </div>
    </Form>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as yup from 'yup'

import LoadingSpinner from './lib/LoadingSpinner.vue'
import Alerts from '../components/lib/Alerts.vue'
import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import { User } from '@/interfaces/User'
import { usersService } from '@/services/Users.service'
import { countryRegions } from '@/use/countryRegionSelectData'
import { languageSelect } from '@/use/languageSelectData'
import { userStatus } from '@/use/userStatus'
import { userRoles } from '@/use/userRoles'
import { memberShipPeriod } from '@/use/memberShipPeriod'

export default defineComponent({
  name: 'AddUserForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    Alerts,
    GoBackButton,
    SaveButton,
    LoadingSpinner
  },
  data() {
    const schema = yup.object({
      name: yup
        .string()
        .required()
        .min(3),
      email: yup
        .string()
        .required()
        .email(),
      country_id: yup.string().required(),
      language: yup.string().required(),
      status: yup.string().required(),
      role: yup.string().required(),
      period: yup.number().required(),
      password: yup
        .string()
        .required()
        .min(10)
    })
    return {
      countryRegions,
      languageSelect,
      userStatus,
      userRoles,
      memberShipPeriod,
      userData: {} as User,
      alertType: null,
      schema,
      error_messages: null
    }
  },
  methods: {
    async createUser(data) {
      this.$refs.loadingSpinner.open()
      await usersService
        .createUser(data)
        .then(response => {
          this.$refs.loadingSpinner.close()
          this.showSuccessAlert('User successfully saved!')
          console.log(response.data)
          return response.data
        })
        .catch(error => {
          this.$refs.loadingSpinner.close()
          this.error_messages = error.response.data
          this.showErrorAlert(error.message)
        })
    },
    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>

<style scoped></style>
