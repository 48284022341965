import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteMeta
} from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Users from '../views/Users.vue'

import store from '../store'
import auth from '../middleware/auth'
import UserData from '../views/UserData.vue'
import AddUser from '../views/AddUser.vue'
import Series from '../views/Series.vue'
import SeriesData from '../views/SeriesData.vue'
import AddSeries from '../views/AddSeries.vue'
import AddSeriesDescription from '../views/AddSeriesDescription.vue'
import EditSeriesDescription from '../views/EditSeriesDescription.vue'
import SeriesLogoUpload from '../views/SeriesLogoUpload.vue'
import SeriesPreviewUpload from '../views/SeriesPreviewUpload.vue'
import SiteDescription from '../views/SiteDescription.vue'
import SiteDescriptionData from '../views/SiteDescriptionData.vue'
import StaticSites from '../views/StaticSites.vue'
import StaticSitesData from '../views/StaticSitesData.vue'
import ImageSettings from '@/views/ImageSettings.vue'
import ImageSets from '@/views/ImageSets.vue'
import AddImageSet from '@/views/AddImageSet.vue'
import AddImageSetDescription from '@/views/AddImageSetDescription.vue'
import ImageSetsContentUpload from '@/views/ImageSetsContentUpload.vue'
import ImageSetContent from '@/views/ImageSetContent.vue'
import VideosSets from '@/views/VideosSets.vue'
import AddVideoSet from '@/views/AddVideoSet.vue'
import AddVideoSetDescription from '@/views/AddVideoSetDescription.vue'
import VideoSetsPreviewUpload from '@/views/VideoSetsPreviewUpload.vue'
import VideoSetsContentUpload from '@/views/VideoSetsContentUpload.vue'
import ModelsData from '@/views/ModelsData.vue'
import AddModel from '@/views/AddModel.vue'
import ModelPreviewUpload from '@/views/ModelPreviewUpload.vue'
import ModelDescription from '@/views/ModelDescription.vue'
import ModelContentData from '@/views/ModelContentData.vue'
import UserPassword from '@/views/UserPassword.vue'
import Forbidden from '@/views/Forbidden.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import PaymentPackages from '@/views/PaymentPackages.vue'
import PaymentPackagesData from '@/views/PaymentPackagesData.vue'
import ModelPreviewImages from "@/views/ModelPreviewImages.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword,
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    component: ResetPassword,
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: Forbidden,
    meta: {
      layout: 'empty'
    }
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/user_management',
    name: 'users',
    component: Users,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/user_management/user/:userId',
    name: 'editUser',
    component: UserData,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/user_management/user_password/:userId',
    name: 'editUserPassword',
    component: UserPassword,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/user_management/adduser',
    name: 'addUser',
    component: AddUser,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/series',
    name: 'series',
    component: Series,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/series/:series_id',
    name: 'editSeries',
    component: SeriesData,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/series/add_series',
    name: 'addSeries',
    component: AddSeries,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/series/add_series_description/:series_id',
    name: 'addSeriesDescription',
    component: AddSeriesDescription,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/series/edit_series_description/:series_description_id',
    name: 'editSeriesDescription',
    component: EditSeriesDescription,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/series/series_logo_upload/:series_id',
    name: 'seriesLogoUpload',
    component: SeriesLogoUpload,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/series/series_preview_upload/:series_id',
    name: 'seriesPreviewUpload',
    component: SeriesPreviewUpload,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/site/site_description',
    name: 'siteDescription',
    component: SiteDescription,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/site/site_description/add_description',
    name: 'siteDescriptionData',
    component: SiteDescriptionData,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/site/site_description/edit_description/:site_description_id',
    name: 'editSiteDescriptionData',
    component: SiteDescriptionData,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/site/static_sites',
    name: 'staticSites',
    component: StaticSites,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/site/static_sites/add_site',
    name: 'addStaticSites',
    component: StaticSitesData,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/site/static_sites/edit_site/:static_site_id',
    name: 'editStaticSites',
    component: StaticSitesData,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/settings/image_settings',
    name: 'imageSettings',
    component: ImageSettings,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/images_sets',
    name: 'images_sets',
    component: ImageSets,
    props: route => ({
      search: route.query.search,
      series_id: route.query.series_id
    }),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/images_sets/add_set',
    name: 'addImageSet',
    component: AddImageSet,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/images_sets/edit_set/:image_set_id',
    name: 'editImageSet',
    component: AddImageSet,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/images_sets/add_set_description/:image_set_id',
    name: 'addImageSetDescription',
    component: AddImageSetDescription,
    meta: {
      middleware: [auth]
    }
  },
  {
    path:
      '/images_sets/add_set_description/edit_description/:image_set_description_id',
    name: 'editImageSetDescription',
    component: AddImageSetDescription,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/images_sets/image_set_content_upload/:image_set_id',
    name: 'imageSetsContentUpload',
    component: ImageSetsContentUpload,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/images_sets/image_set_content/:image_set_id',
    name: 'imageSetsContent',
    component: ImageSetContent,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/videos_sets',
    name: 'videosSets',
    component: VideosSets,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/videos_sets/add_set',
    name: 'addVideoSet',
    component: AddVideoSet,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/videos_sets/edit_set/:video_set_id',
    name: 'editVideoSet',
    component: AddVideoSet,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/videos_sets/add_set_description/:video_set_id',
    name: 'addVideoSetDescription',
    component: AddVideoSetDescription,
    meta: {
      middleware: [auth]
    }
  },
  {
    path:
      '/videos_sets/add_set_description/edit_description/:video_set_description_id',
    name: 'editVideoSetDescription',
    component: AddVideoSetDescription,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/videos_sets/video_set_preview_upload/:video_set_id',
    name: 'videoSetsPreviewUpload',
    component: VideoSetsPreviewUpload,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/videos_sets/video_set_content_upload/:video_set_id',
    name: 'videoSetsContentUpload',
    component: VideoSetsContentUpload,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/models',
    name: 'modelsData',
    component: ModelsData,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/models/add_model',
    name: 'addModelData',
    component: AddModel,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/models/edit_model/:model_id',
    name: 'editModelData',
    component: AddModel,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/models/model_preview_images_upload/:model_id',
    name: 'uploadModelPreviewImages',
    component: ModelPreviewUpload,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/models/model_preview_images/:model_id',
    name: 'modelPreviewImages',
    component: ModelPreviewImages,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/models/add_model_description/:model_id',
    name: 'addModelDescription',
    component: ModelDescription,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/models/update_model_description/:model_description_id',
    name: 'editModelDescription',
    component: ModelDescription,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/models/model_content/:model_id',
    name: 'modelContent',
    component: ModelContentData,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/payment_packages',
    name: 'paymentPackages',
    component: PaymentPackages,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/payment_packages/add_payment_data',
    name: 'addPaymentPackages',
    component: PaymentPackagesData,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/payment_packages/add_payment_data/:payment_package_id',
    name: 'updatePaymentPackages',
    component: PaymentPackagesData,
    meta: {
      middleware: [auth]
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware: any = to.meta.middleware
  const context = {
    to,
    from,
    store,
    next
  }

  return middleware[0]({
    ...context,
    next
  })
})

export default router
