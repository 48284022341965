<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">
      Statische Seite
    </h2>
    <Form
      @submit="saveStaticSiteData"
      :validation-schema="staticSiteSchema"
      :initial-values="initialFormValues"
    >
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="title"
          >Tietel</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="title"
          name="title"
          v-model="staticSiteFormData.title"
        />
        <ErrorMessage name="title" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="content"
          >Content</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="textarea"
          id="content"
          name="content"
          v-model="staticSiteFormData.content"
        />
        <ErrorMessage name="content" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="language"
          >Sprache</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="select"
          id="language"
          name="language"
          v-model="staticSiteFormData.language"
        >
          <option value="">Orginal Sprache</option>
          <option
            v-for="(language, index) in languageSelect"
            :key="index"
            :value="language.code"
            >{{ language.name }}
          </option>
        </Field>
        <ErrorMessage name="language" class="text-red-600" />
      </div>
      <div class="flex justify-between mt-6">
        <GoBackButton />
        <SaveButton />
      </div>
    </Form>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import { languageSelect } from '@/use/languageSelectData'
import { staticSiteSchema } from '@/schemas/staticSiteSchema'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'

import { StaticSite } from '@/interfaces/StaticSite'
import { staticSitesService } from '@/services/StaticSites.service'

export default defineComponent({
  name: 'StaticSitesForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    LoadingSpinner,
    GoBackButton,
    SaveButton,
    Alerts
  },
  data() {
    const static_site_id: number = null
    return {
      static_site_id,
      staticSiteFormData: {} as StaticSite,
      initialFormValues: {} as StaticSite,
      languageSelect,
      staticSiteSchema,
      alertType: null
    }
  },
  mounted() {
    if (this.$route.params.static_site_id) {
      this.$refs.loadingSpinner.open()
    }
  },
  created() {
    this.static_site_id = this.$route.params.static_site_id
    if (this.static_site_id) {
      this.getStaticSiteData(this.static_site_id)
    }
  },
  methods: {
    async saveStaticSiteData(data) {
      console.log('saveStaticSiteData clicked')
      if (!this.$route.params.static_site_id) {
        this.$refs.loadingSpinner.open()
        await staticSitesService
          .createStaticSiteData(data)
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Statische Seite erfolgreich gespeichert!')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      } else {
        this.$refs.loadingSpinner.open()
        await staticSitesService
          .updateStaticSiteData(this.$route.params.static_site_id, data)
          .then(response => {
            this.staticSiteFormData = ref<StaticSite>({
              language: response.data.language,
              title: response.data.title,
              slug: response.data.slug,
              content: response.data.content
            })
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Statische Seite erfolgreich updated!')
            return this.staticSiteFormData
          })
          .catch(error => {
            this.showErrorAlert(error)
            console.log(error)
          })
      }
    },

    async getStaticSiteData(static_site_id: number) {
      await staticSitesService
        .getStaticSiteData(static_site_id)
        .then(response => {
          this.initialFormValues = ref<StaticSite>({
            language: response.data.language,
            title: response.data.title,
            slug: response.data.slug,
            content: response.data.content
          })
          this.$refs.loadingSpinner.close()
          return this.initialFormValues
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>

<style scoped></style>
