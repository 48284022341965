
import { defineComponent, watchEffect } from 'vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import LightBox from '@/components/LightBox.vue'
import GoBackButton from '@/components/GoBackButton.vue'
import { modelsDataService } from '@/services/ModelsData.service'
import VLazyImage from 'v-lazy-image'
export default defineComponent({
  name: 'ModelPreviewImages',
  components: {
    LoadingSpinner,
    LightBox,
    GoBackButton,
    VLazyImage
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    watchEffect(() => {
      this.getPreviewImages(parseInt(this.model_id))
    })
  },
  data() {
    return {
      modelPreviewImages: null,
      model_id: this.$route.params.model_id,
      slug: null
    }
  },
  methods: {
    async getPreviewImages(model_id: number) {
      await modelsDataService
        .getModelPreviewImages(model_id)
        .then(response => {
          console.log(response.data)
          this.modelPreviewImages = response.data.data
          this.getModelDataForSlug(model_id)
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
          this.$refs.loadingSpinner.close()
        })
    },
    async getModelDataForSlug(model_id: number) {
      await modelsDataService
        .getModel(model_id)
        .then(response => {
          this.slug = response.data.data.slug
          console.log(this.slug)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async openLightBox(
      imagePreviewUrl: string,
      slug: string,
      imageName: string
    ) {
      await this.$refs.lightBox.show({ imagePreviewUrl, slug, imageName })
    },
    async moveToTrash(preview_id: number) {
      await modelsDataService
        .deletePreviewImages(this.model_id, preview_id)
        .then(response => {
          console.log(response.data)
          this.getPreviewImages(this.model_id)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
})
