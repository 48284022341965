<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">
      Password Update
    </h2>
    <form @submit.prevent="userPasswordUpdate">
      <div
        v-if="error_messages && error_messages.errors.password"
        class="text-red-600"
      >
        {{ error_messages.errors.password[0] }}
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="password"
          >Passwort:</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="password"
          id="password"
          v-model="userPassword.password"
        />
      </div>
      <div class="mt-2">
        <label
          class="inline-block text-sm mb-2 text-gray-700"
          for="password_confirmation"
          >Passwort wiederholen:</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="password"
          id="password_confirmation"
          v-model="userPassword.password_confirmation"
        />
      </div>
      <div class="flex justify-between mt-6">
        <GoBackButton />
        <SaveButton />
      </div>
    </form>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { usersService } from '@/services/Users.service'
import { UserPassword } from '@/interfaces/UserPassword'

import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'

export default defineComponent({
  name: 'PasswordUpdateForm',
  components: {
    LoadingSpinner,
    SaveButton,
    GoBackButton,
    Alerts
  },
  created() {
    this.userId = this.$route.params.userId
  },
  data() {
    const userId: string = null
    return {
      userId,
      userPassword: {} as UserPassword,
      alertType: null,
      error_messages: null
    }
  },

  methods: {
    async userPasswordUpdate() {
      if (this.userId) {
        this.$refs.loadingSpinner.open()
        await usersService
          .updateUserPassword(this.userId, this.userPassword)
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('User Passwort updated')
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.error_messages = error.response.data
            this.showErrorAlert(error.message)
          })
      }
    },
    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>

<style scoped></style>
