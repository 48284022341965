<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">
      Seiten Beschreibung
    </h2>
    <Form
      @submit="saveSiteDescriptionData"
      :validation-schema="siteDescriptionSchema"
      :initial-values="initialFormValues"
    >
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="title"
          >Tietel</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="title"
          name="title"
          v-model="siteDescriptionFormData.title"
        />
        <ErrorMessage name="title" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="description"
          >Beschreibung</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="description"
          name="description"
          v-model="siteDescriptionFormData.description"
        />
        <ErrorMessage name="description" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="keywords"
          >Keywords</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="keywords"
          name="keywords"
          v-model="siteDescriptionFormData.keywords"
        />
        <ErrorMessage name="keywords" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="language"
          >Sprache</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="select"
          id="language"
          name="language"
          v-model="siteDescriptionFormData.language"
        >
          <option value="">Orginal Sprache</option>
          <option
            v-for="(language, index) in languageSelect"
            :key="index"
            :value="language.code"
            >{{ language.name }}
          </option>
        </Field>
        <ErrorMessage name="language" class="text-red-600" />
      </div>
      <div class="flex justify-between mt-6">
        <GoBackButton />
        <SaveButton />
      </div>
    </Form>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import { languageSelect } from '@/use/languageSelectData'
import { siteDescriptionSchema } from '@/schemas/siteDescriptionSchema'
import LoadingSpinner from './lib/LoadingSpinner.vue'
import Alerts from '../components/lib/Alerts.vue'
import { SiteDescription } from '@/interfaces/SiteDescription'
import { sitesDescriptionService } from '@/services/SitesDescription.service'
export default defineComponent({
  name: 'SiteDescriptionForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    LoadingSpinner,
    GoBackButton,
    SaveButton,
    Alerts
  },
  data() {
    const site_description_id: number = null
    return {
      site_description_id,
      siteDescriptionFormData: {} as SiteDescription,
      initialFormValues: {} as SiteDescription,
      languageSelect,
      siteDescriptionSchema,
      alertType: null
    }
  },
  mounted() {
    if (this.$route.params.site_description_id) {
      this.$refs.loadingSpinner.open()
    }
  },
  created() {
    this.site_description_id = this.$route.params.site_description_id
    if (this.site_description_id) {
      this.getSiteDescriptionData(this.site_description_id)
    }
  },
  methods: {
    async saveSiteDescriptionData(data) {
      if (!this.$route.params.site_description_id) {
        this.$refs.loadingSpinner.open()
        await sitesDescriptionService
          .createSiteDescription(data)
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert(
              'Seiten Beschreibung erfolgreich gespeichert!'
            )
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      } else {
        this.$refs.loadingSpinner.open()
        await sitesDescriptionService
          .updateSiteDescription(this.site_description_id, data)
          .then(response => {
            this.siteDescriptionFormData = ref<SiteDescription>({
              title: response.data.title,
              description: response.data.description,
              keywords: response.data.keywords,
              language: response.data.language
            })
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Seiten Beschreibung erfolgreich updated!')
            return this.siteDescriptionFormData
          })
          .catch(error => {
            this.showErrorAlert(error)
            console.log(error)
          })
      }
    },
    async getSiteDescriptionData(site_description_id: number) {
      await sitesDescriptionService
        .getDescriptionData(site_description_id)
        .then(response => {
          this.initialFormValues = ref<SiteDescription>({
            title: response.data.title,
            description: response.data.description,
            keywords: response.data.keywords,
            language: response.data.language
          })
          this.$refs.loadingSpinner.close()
          return this.initialFormValues
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>

<style scoped></style>
