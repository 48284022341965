import { createStore } from 'vuex'
import auth from './auth'
import paginationSettings from './paginationSettings'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    paginationSettings,
    auth
  }
})
