import * as yup from 'yup'
export const videoSetSchema = yup.object({
  set_name: yup
    .string()
    .required()
    .min(3),
  description: yup
    .string()
    .required()
    .min(3),
  series_id: yup
    .string()
    .required()
    .min(1)
})
