export const zodiacSelect = [
  'cancer',
  'taurus',
  'pisces',
  'aries',
  'libra',
  'aquarius',
  'capricorn',
  'scorpio',
  'virgo',
  'sagittarius',
  'gemini',
  'leo'
]
