<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">
      Bilder Einstellungen
    </h2>

    <Form
      @submit="updateSettingsData"
      :validation-schema="imageSettingsSchema"
      :initial-values="imageSettingsFormInitialData"
    >
      <fieldset
        class="flex flex-wrap -mx-3 mb-6 mt-4 border border-solid rounded-md border-gray-300 p-4"
      >
        <legend class="text-sm uppercase font-medium">Thumbnails</legend>
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            class="inline-block text-sm mb-2 text-gray-700"
            for="thumbnail_mobile_max_width"
            >Thumbnail Mobile Max Width</label
          >
          <Field
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            type="text"
            id="thumbnail_mobile_max_width"
            name="thumbnail_mobile_max_width"
            v-model="imageSettingsFormData.thumbnail_mobile_max_width"
          />
          <ErrorMessage
            name="thumbnail_mobile_max_width"
            class="text-red-600"
          />
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            class="inline-block text-sm mb-2 text-gray-700"
            for="thumbnail_mobile_max_width"
            >Thumbnail Mobile Max Height</label
          >
          <Field
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            type="text"
            id="thumbnail_mobile_max_height"
            name="thumbnail_mobile_max_height"
            v-model="imageSettingsFormData.thumbnail_mobile_max_height"
          />
          <ErrorMessage
            name="thumbnail_mobile_max_height"
            class="text-red-600"
          />
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6 mt-2 md:mb-0">
          <label
            class="inline-block text-sm mb-2 text-gray-700"
            for="thumbnail_mobile_max_width"
            >Thumbnail Desktop Max Width</label
          >
          <Field
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            type="text"
            id="thumbnail_desktop_max_width"
            name="thumbnail_desktop_max_width"
            v-model="imageSettingsFormData.thumbnail_desktop_max_width"
          />
          <ErrorMessage
            name="thumbnail_desktop_max_width"
            class="text-red-600"
          />
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6 mt-2 md:mb-0">
          <label
            class="inline-block text-sm mb-2 text-gray-700"
            for="thumbnail_mobile_max_width"
            >Thumbnail Desktop Max Height</label
          >
          <Field
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            type="text"
            id="thumbnail_desktop_max_height"
            name="thumbnail_desktop_max_height"
            v-model="imageSettingsFormData.thumbnail_desktop_max_height"
          />
          <ErrorMessage
            name="thumbnail_desktop_max_height"
            class="text-red-600"
          />
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6 mt-2 md:mb-0">
          <label
            class="w-full inline-block text-sm mb-2 text-gray-700"
            for="crop_thumbnail_exact"
            >Thumbnails exact cropen?</label
          >
          <Field
            class="w-1/4 border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            as="select"
            id="crop_thumbnail_exact"
            name="crop_thumbnail_exact"
            v-model="imageSettingsFormData.crop_thumbnail_exact"
          >
            <option value="true">Ja Cropen!</option>
            <option value="false">Nicht Cropen!</option>
          </Field>
          <ErrorMessage name="crop_thumbnail_exact" class="text-red-600" />
        </div>
      </fieldset>

      <fieldset
        class="flex flex-wrap -mx-3 mb-6 mt-2 border border-solid rounded-md border-gray-300 p-4"
      >
        <legend class="text-sm uppercase font-medium">Bilder</legend>
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            class="inline-block text-sm mb-2 text-gray-700"
            for="thumbnail_mobile_max_width"
            >Bilder Mobile Max Width</label
          >
          <Field
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            type="text"
            id="image_mobile_max_width"
            name="image_mobile_max_width"
            v-model="imageSettingsFormData.image_mobile_max_width"
          />
          <ErrorMessage name="image_mobile_max_width" class="text-red-600" />
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            class="inline-block text-sm mb-2 text-gray-700"
            for="image_mobile_max_height"
            >Bilder Mobile Max Height</label
          >
          <Field
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            type="text"
            id="image_mobile_max_height"
            name="image_mobile_max_height"
            v-model="imageSettingsFormData.image_mobile_max_height"
          />
          <ErrorMessage name="image_mobile_max_height" class="text-red-600" />
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6 mt-2 md:mb-0">
          <label
            class="inline-block text-sm mb-2 text-gray-700"
            for="image_desktop_max_width"
            >Bilder Desktop Max Width</label
          >
          <Field
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            type="text"
            id="image_desktop_max_width"
            name="image_desktop_max_width"
            v-model="imageSettingsFormData.image_desktop_max_width"
          />
          <ErrorMessage name="image_desktop_max_width" class="text-red-600" />
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6 mt-2 md:mb-0">
          <label
            class="inline-block text-sm mb-2 text-gray-700"
            for="image_desktop_max_height"
            >Bilder Desktop Max Height</label
          >
          <Field
            class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
            type="text"
            id="image_desktop_max_height"
            name="image_desktop_max_height"
            v-model="imageSettingsFormData.image_desktop_max_height"
          />
          <ErrorMessage name="image_desktop_max_height" class="text-red-600" />
        </div>
      </fieldset>
      <div class="flex justify-between mt-6">
        <GoBackButton />
        <button
          type="submit"
          class="px-4 py-2 bg-gray-800 text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
        >
          Speichern !
        </button>
      </div>
    </Form>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

import GoBackButton from './GoBackButton.vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'
import { ImageSettings } from '@/interfaces/ImageSettings'
import { imageSettingsService } from '@/services/ImageSettings.service'
import { imageSettingsSchema } from '@/schemas/imageSettingsSchema'

export default defineComponent({
  name: 'ImageSettingsForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    GoBackButton,
    LoadingSpinner,
    Alerts
  },
  data() {
    const image_setting_id: number = null
    const crop_thumbnail_exact_value = false
    return {
      image_setting_id,
      imageSettingsFormData: {} as ImageSettings,
      imageSettingsFormInitialData: {} as ImageSettings,
      imageSettingsSchema,
      alertType: null,
      crop_thumbnail_exact_value
    }
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    this.getImageSettingsData()
  },
  methods: {
    async getImageSettingsData() {
      await imageSettingsService
        .getImageSettings()
        .then(response => {
          if (response.data.data[0].crop_thumbnail_exact === true) {
            this.crop_thumbnail_exact_value = true
          } else {
            this.crop_thumbnail_exact_value = false
          }
          this.image_setting_id = response.data.data[0].image_settings_id
          this.imageSettingsFormInitialData = ref<ImageSettings>({
            thumbnail_mobile_max_width:
              response.data.data[0].thumbnail_mobile_max_width,
            thumbnail_mobile_max_height:
              response.data.data[0].thumbnail_mobile_max_height,
            thumbnail_desktop_max_width:
              response.data.data[0].thumbnail_desktop_max_width,
            thumbnail_desktop_max_height:
              response.data.data[0].thumbnail_desktop_max_height,
            crop_thumbnail_exact: response.data.data[0].crop_thumbnail_exact,
            image_mobile_max_width:
              response.data.data[0].image_mobile_max_width,
            image_mobile_max_height:
              response.data.data[0].image_mobile_max_height,
            image_desktop_max_width:
              response.data.data[0].image_desktop_max_width,
            image_desktop_max_height:
              response.data.data[0].image_desktop_max_height
          })
          this.$refs.loadingSpinner.close()
          console.log(this.imageSettingsFormInitialData)
          return this.imageSettingsFormInitialData
        })
        .catch(error => {
          this.$refs.loadingSpinner.close()
          this.showErrorAlert(error.message)
        })
    },
    async updateSettingsData(data) {
      this.$refs.loadingSpinner.open()
      console.log(data.crop_thumbnail_exact)
      if (data.crop_thumbnail_exact === 'true') {
        data.crop_thumbnail_exact = true
      } else {
        data.crop_thumbnail_exact = false
      }
      console.log(data)
      await imageSettingsService
        .updateImageSettings(this.image_setting_id, data)
        .then(response => {
          this.showSuccessAlert('Bilder Settings erfolgreich gespeichert!')
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          this.$refs.loadingSpinner.close()
          this.showErrorAlert(error.message)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>

<style scoped></style>
