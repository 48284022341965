<template>
  <table class="text-left w-full border-collapse">
    <thead class="border-b">
      <tr>
        <th
          v-for="(column, index) in columns"
          :key="index"
          scope="col"
          class="py-1 px-5 bg-indigo-800 font-light text-xs text-gray-100"
          :class="{
            columnActive: sortBy === column.key && column.sortable
          }"
          @click="column.sortable ? sort(column.key) : null"
        >
          <div class="inline-flex flex-wrap w-full">
            <div class="inline-block text-right mr-3 mt-1 left-0">
              {{ column.label }}
            </div>
            <div v-if="column.sortable" class="text-right right-0">
              <svg
                class="w-3 h-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                v-show="sortBy === column.key && sortDirection === 'ASC'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                ></path>
              </svg>
              <svg
                class="w-3 h-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                v-show="sortBy === column.key && sortDirection === 'DESC'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, index) in showTransactions"
        :key="index"
        class="hover:bg-indigo-200"
      >
        <td class="py-2 px-6 border-b text-gray-700 text-xs">
          {{ row.transaction_id }}
        </td>
        <td class="py-2 px-6 border-b text-gray-700 text-xs">
          {{ row.wm_id }}
        </td>
        <td class="py-2 px-6 border-b text-gray-700 text-xs">
          {{ row.amount }}
        </td>
        <td class="py-2 px-6 border-b text-gray-700 text-xs">
          {{ row.currency }}
        </td>
        <td class="py-2 px-6 border-b text-gray-700 text-xs">
          {{ row.currency_user }}
        </td>
        <td class="py-2 px-6 border-b text-gray-700 text-xs">
          {{ row.payment_type }}
        </td>
        <td class="py-2 px-6 border-b text-gray-700 text-xs">
          {{ row.transaction_date }}
        </td>
        <td class="py-2 px-6 border-b text-gray-700 text-xs">
          {{ row.action }}
        </td>
        <td class="py-2 px-6 border-b text-gray-700 text-xs">
          {{ row.status }}
        </td>
        <td class="py-2 px-6 border-b text-gray-700 text-xs">
          {{ row.trans_amount }}
        </td>
        <td class="py-2 px-6 border-b text-gray-700 text-xs">
          {{ row.trans_amount_usd }}
        </td>
        <td class="py-2 px-6 border-b text-gray-700 text-xs">
          {{ row.payment_subtype }}
        </td>
        <td class="py-2 px-6 border-b text-gray-700 text-xs">
          {{ this.dateTimeFormatter(row.created_at) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import dateTimeFormatter from '../filters/dateTimeFormatter'

export default defineComponent({
  name: 'UserTransactionsTable',
  props: {
    transactionData: Array,
    columns: Array
  },
  data() {
    const sortBy = 'created_at'
    const sortDirection = 'ASC'
    return {
      sortBy: sortBy,
      sortDirection: sortDirection,
      transactionList: this.transactionData
    }
  },
  computed: {
    showTransactions() {
      const list = this.transactionList
      list.sort((a: Array<string>, b: Array<string>) => {
        return (
          (a[this.sortBy] === b[this.sortBy]
            ? 0
            : a[this.sortBy] > b[this.sortBy]
            ? 1
            : -1) * (this.sortDirection === 'ASC' ? 1 : -1)
        )
      })

      return list
    }
  },
  methods: {
    dateTimeFormatter,
    sort(sortBy) {
      if (sortBy === this.sortBy) {
        if (this.sortDirection == 'ASC') {
          this.sortDirection = 'DESC'
        } else {
          this.sortDirection = 'ASC'
        }
      }
      if (sortBy != this.sortBy) {
        this.sortDirection = 'ASC'
        this.sortBy = sortBy
      }
    }
  }
})
</script>

<style scoped></style>
