<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full">
        <div
          class="shadow overflow-hidden bg-white border-b border-gray-200 sm:rounded-lg"
        >
          <VideosSetsDataFilter
            ref="tableFilter"
            :perPageSelected="perPageSelected"
          />
          <div class="py-2">
            <nav class="flex space-x-4" aria-label="Tabs">
              <router-link
                v-for="(serie, index) in series"
                :key="index"
                class=" px-3 py-2 font-medium text-sm rounded-md"
                :class="[
                  serie.id == $route.query.series_id
                    ? activeClass
                    : inactiveClass
                ]"
                :to="{
                  name: routeName,
                  query: {
                    ...this.$route.query,
                    series_id: serie.id
                  }
                }"
              >
                {{ serie.title }}
              </router-link>
            </nav>
          </div>
          <Pagination
            :routeName="routeName"
            :pagination="paginationData"
            :searchString="search"
            :sortBy="sortBy"
            :sortDirection="sortDirection"
            :seriesId="series_id"
          />
          <VideosSetsTable :tableData="videosSets" :columns="columns" />
          <Pagination
            :routeName="routeName"
            :pagination="paginationData"
            :searchString="search"
            :sortBy="sortBy"
            :sortDirection="sortDirection"
            :seriesId="series_id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from 'vue'

import LoadingSpinner from '../components/lib/LoadingSpinner.vue'
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import VideosSetsTable from '../components/VideosSetsTable.vue'

import Pagination from '../components/Pagination.vue'
import VideosSetsDataFilter from '../components/VideosSetsDataFilter.vue'
import { videosSetsService } from '@/services/VideosSets.service'
import { mapGetters } from 'vuex'
import { seriesService } from '@/services/Series.service'

export default defineComponent({
  name: 'VideosSets',
  components: {
    LoadingSpinner,
    ConfirmDialogue,
    VideosSetsTable,
    Pagination,
    VideosSetsDataFilter
  },
  mounted() {
    this.$refs.loadingSpinner.open()
    this.getSeriesData()
    if (!this.$route.query.series_id && this.$route.query.search) {
      this.getVideosSetsData(this.$route.query.search, this.series_id)
    }
    if (this.$route.query.series_id && !this.$route.query.search) {
      this.getVideosSetsData(this.search, this.$route.query.series_id)
    }
    if (this.$route.query.search && this.$route.query.series_id) {
      this.getVideosSetsData(
        this.$route.query.search,
        this.$route.query.series_id
      )
    }
    this.getVideosSetsData(this.search, this.series_id)
  },
  created() {
    watchEffect(() => {
      this.$nextTick(() => {
        this.$refs.loadingSpinner.open()
      })
      this.getSeriesData()
      if (this.$route.query.series_id && !this.$route.query.search) {
        this.getVideosSetsData(this.search, this.$route.query.series_id)
      }
      if (!this.$route.query.series_id && this.$route.query.search) {
        this.getVideosSetsData(this.$route.query.search, this.series_id)
      }
      if (this.$route.query.search && this.$route.query.series_id) {
        this.getVideosSetsData(
          this.$route.query.search,
          this.$route.query.series_id
        )
      }
      this.getVideosSetsData(this.search, this.series_id)
    })
  },
  data() {
    const perPage = 10
    const page = 1
    const search = null
    const series_id = null
    const sortBy = null
    const sortDirection = 'ASC'
    const activeClass = 'bg-indigo-100 text-indigo-700'
    const inactiveClass = 'text-gray-500 hover:text-gray-700'
    return {
      routeName: 'videosSets',
      perPage: perPage,
      page: page,
      search: search,
      series_id: series_id,
      sortBy: sortBy,
      sortDirection: sortDirection,
      paginationData: Object,
      perPageSelected: null,
      videosSets: null,
      activeClass,
      inactiveClass,
      columns: [
        {
          key: 'id',
          label: 'ID',
          sortable: true
        },
        {
          key: 'set_name',
          label: 'Set Name',
          sortable: true
        },
        {
          key: 'description',
          label: 'Beschreibung',
          sortable: false
        },
        {
          key: 'series',
          label: 'Serie',
          sortable: false
        },
        {
          key: 'publish_on',
          label: 'Veröffentlicht am',
          sortable: true
        },
        {
          key: 'videosSetsDescriptions',
          label: 'Set Beschreibungen',
          sortable: false
        },
        {
          key: 'previewImage',
          label: 'Vorschau Bild',
          sortable: false
        },
        {
          key: 'videosContent',
          label: 'Videos',
          sortable: false
        }
      ]
    }
  },
  methods: {
    ...mapGetters({
      perPageGetter: 'paginationSettings/getPerPage'
    }),

    async getSeriesData() {
      await seriesService
        .getSeriesData()
        .then(response => {
          this.series = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getVideosSetsData(search: string = null, series_id: string = null) {
      this.search = search
      this.series_id = series_id
      if (search) {
        this.$refs.loadingSpinner.open()
        await this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            search: search
          }
        })
      }
      if (series_id) {
        this.$refs.loadingSpinner.open()
        await this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            series_id: series_id
          }
        })
      }

      const perPageSettings = this.perPageGetter() ?? this.perPage
      const page = this.$route.query.page ?? this.page
      const sortBy = this.$route.query.sortBy ?? this.sortBy
      const sortDirection =
        this.$route.query.sortDirection ?? this.sortDirection

      this.perPageSelected = parseInt(perPageSettings)

      await videosSetsService
        .getVideosSets(
          parseInt(perPageSettings),
          page,
          search,
          sortBy,
          sortDirection,
          series_id
        )
        .then(response => {
          this.paginationData = response.data.meta
          this.videosSets = response.data.data
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
        })
    },
    async deleteVideoSet(id: number) {
      if (id) {
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Video Set löschen',
          message: 'Bist du sicher, dass du dieses Video Set löschen möchtest.',
          okButton: 'Löschen'
        })
        if (ok) {
          this.$refs.loadingSpinner.open()
          await videosSetsService.deleteVideosSet(id)
          await this.getVideosSetsData()
        }
      }
    },

    async movePreviewToTrash(imageId: number) {
      if (imageId) {
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Vorschaubild  löschen',
          message:
            'Bist du sicher, dass du dieses Vorschaubild löschen möchtest.',
          okButton: 'Löschen'
        })
        if (ok) {
          this.$refs.loadingSpinner.open()
          await videosSetsService.deleteVideoSetPreview(imageId)
          await this.getVideosSetsData()
        }
      }
    }
  }
})
</script>

<style scoped></style>
