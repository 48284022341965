
import { defineComponent } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as yup from 'yup'

import { seriesService } from '@/services/Series.service'
import { languageSelect } from '@/use/languageSelectData'
import { contentType } from '@/use/contentType'
import { contentResolution } from '@/use/contentResolution'
import Alerts from '@/components/lib/Alerts.vue'
import GoBackButton from '@/components/GoBackButton.vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import { Series } from '@/interfaces/Series'

export default defineComponent({
  name: 'AddSeriesData',
  components: {
    Form,
    Field,
    ErrorMessage,
    Alerts,
    GoBackButton,
    LoadingSpinner
  },
  data() {
    const schema = yup.object({
      title: yup
        .string()
        .required()
        .min(3),
      description: yup
        .string()
        .required()
        .min(3),
      content_resolution: yup
        .string()
        .required()
        .min(3),
      sort_order: yup.string().required(),
      content_origin: yup
        .string()
        .required()
        .max(2),
      type: yup
        .string()
        .required()
        .min(2)
    })
    return {
      languageSelect,
      contentType,
      contentResolution,
      seriesFormData: {} as Series,
      alertType: null,
      schema
    }
  },
  methods: {
    async createSeries(data) {
      this.$refs.loadingSpinner.open()
      await seriesService
        .createSeries(data)
        .then(response => {
          this.$refs.loadingSpinner.close()
          this.showSuccessAlert('Serie erfolgreich gespeichert!')
          return response.data
        })
        .catch(error => {
          this.$refs.loadingSpinner.close()
          this.showErrorAlert(error.message)
        })
    },
    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
