
import { defineComponent, watchEffect } from 'vue'

import LoadingSpinner from '../components/lib/LoadingSpinner.vue'
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import SeriesTable from '../components/SeriesTable.vue'

import { seriesService } from '@/services/Series.service'
import SeriesTableHeader from '@/components/SeriesTableHeader.vue'

export default defineComponent({
  name: 'SeriesData',
  components: {
    SeriesTableHeader,
    LoadingSpinner,
    ConfirmDialogue,
    SeriesTable
  },
  data() {
    return {
      series: null,
      columns: [
        {
          key: 'logo',
          label: 'Logo'
        },
        {
          key: 'preview_image',
          label: 'Vorschau'
        },
        {
          key: 'title',
          label: 'Tietel'
        },
        {
          key: 'description',
          label: 'Beschreibung'
        },
        {
          key: 'content_resolution',
          label: 'Content Resolution'
        },
        {
          key: 'sort_order',
          label: 'Reienfolge'
        },
        {
          key: 'type',
          label: 'Type'
        },
        {
          key: 'seriesDescriptions',
          label: 'Serien Beschreibungen'
        }
      ]
    }
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    watchEffect(() => {
      this.getSeriesData()
    })
  },
  methods: {
    async getSeriesData() {
      await seriesService
        .getSeriesData()
        .then(response => {
          this.series = response.data.data
          console.log(this.series)
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
        })
    },
    async deleteSeries(id: number) {
      if (id) {
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Serie löschen',
          message: 'Bist du sicher, dass du die Serie löschen möchtest.',
          okButton: 'Löschen'
        })
        if (ok) {
          this.$refs.loadingSpinner.open()
          await seriesService.deleteSeries(id)
          await this.getSeriesData()
        }
      }
    }
  }
})
