<template>
  <modal ref="popup">
    <!--Title-->
    <div class="flex justify-between items-center pb-3">
      <p class="text-2xl font-bold">{{ title }}</p>
      <div class="modal-close cursor-pointer z-50" @click="_cancel">
        <svg
          class="fill-current text-black"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
      </div>
    </div>

    <!--Body-->
    <p>{{ message }}</p>

    <!--Footer-->
    <div class="flex justify-end pt-2">
      <button
        class="px-6 py-3 bg-transparent p-3 rounded-lg text-indigo-500 hover:bg-gray-100 hover:text-indigo-400 mr-2"
        @click="_cancel"
      >
        {{ cancelButton }}
      </button>
      <button
        class="px-6 py-3 bg-indigo-600 rounded-md text-white font-medium tracking-wide hover:bg-indigo-500"
        @click="_confirm"
      >
        {{ okButton }}
      </button>
    </div>
  </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Modal from './Modal.vue'

export default defineComponent({
  name: 'ConfirmDialogue',
  components: { Modal },
  data() {
    const title = null
    const message = null
    const okButton = null
    const cancelButton = 'Cancel'
    const resolvePromise = null
    const rejectPromise = null
    return {
      cancelButton,
      title,
      message,
      okButton,
      resolvePromise,
      rejectPromise
    }
  },
  methods: {
    async show(opts: Record<string, string>) {
      this.title = opts.title
      this.message = opts.message
      this.okButton = opts.okButton
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    _confirm() {
      this.$refs.popup.close()
      this.resolvePromise(true)
    },

    _cancel() {
      this.$refs.popup.close()
      this.resolvePromise(false)
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    }
  }
})
</script>
