import axios from 'axios'
import { createApp } from 'vue'
import App from './App.vue'
import './assets/styles/app.css'
import router from './router'
import store from './store'

import DashboardLayout from './components/DashboardLayout.vue'

import EmptyLayout from './components/EmptyLayout.vue'

axios.defaults.baseURL = process.env.VUE_APP_ADMIN_API_URL;
axios.defaults.withCredentials = true

store.dispatch('auth/getAuthentication').then(() => {
  const app = createApp(App)

  app.component('default-layout', DashboardLayout)
  app.component('empty-layout', EmptyLayout)
  app
    .use(store)
    .use(router)
    .mount('#app')
})
