
import { defineComponent, ref } from 'vue'
import { usersService } from '@/services/Users.service'
import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import LoadingSpinner from './lib/LoadingSpinner.vue'
import UserTransactionsTable from './UserTransactionsTable.vue'
import Alerts from '../components/lib/Alerts.vue'
import dateTimeFormatter from '@/filters/dateTimeFormatter'
import dateTimeFormatterForDateInput from '@/filters/dateTimeFormatterForDateInput'
import { User } from '@/interfaces/User'
import { countryRegions } from '@/use/countryRegionSelectData'
import { languageSelect } from '@/use/languageSelectData'
import { userStatus } from '@/use/userStatus'
import { userRoles } from '@/use/userRoles'
import { memberShipPeriod } from '@/use/memberShipPeriod'

export default defineComponent({
  name: 'EditUser',
  components: {
    UserTransactionsTable,
    LoadingSpinner,
    SaveButton,
    GoBackButton,
    Alerts
  },
  data() {
    const userId: string = null
    const userTransactions = []
    return {
      userId,
      user: {} as User,
      userTransactions,
      countryRegions,
      languageSelect,
      userStatus,
      userRoles,
      memberShipPeriod,
      columns: [
        {
          key: 'transaction_id',
          label: 'Transaction Id',
          sortable: false
        },
        {
          key: 'wm_id',
          label: 'WM ID',
          sortable: false
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true
        },
        {
          key: 'currency',
          label: 'Currency',
          sortable: false
        },
        {
          key: 'currency_user',
          label: 'Currency User',
          sortable: true
        },
        {
          key: 'payment_type',
          label: 'Payment Type',
          sortable: false
        },
        {
          key: 'transaction_date',
          label: 'Transaction date',
          sortable: true
        },
        {
          key: 'action',
          label: 'Action',
          sortable: true
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'trans_amount',
          label: 'Transaction amount',
          sortable: false
        },
        {
          key: 'trans_amount_usd',
          label: 'Transaction amount USD',
          sortable: false
        },
        {
          key: 'payment_subtype',
          label: 'Payment Subtype',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created at',
          sortable: true
        }
      ]
    }
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    this.userId = this.$route.params.userId
    this.getUserData(this.userId)
  },
  methods: {
    async getUserData(userId: string) {
      if (userId) {
        await usersService
          .getUserData(userId)
          .then(response => {
            this.user = ref<User>({
              previous_id: response.data.previous_id,
              name: response.data.name,
              street: response.data.street,
              postcode: response.data.postcode,
              city: response.data.city,
              country_id: response.data.country_id,
              language: response.data.language,
              email: response.data.email,
              created_at: dateTimeFormatter(response.data.created_at),
              email_verified_at: dateTimeFormatter(
                response.data.email_verified_at
              ),
              updated_at: dateTimeFormatter(response.data.updated_at),
              access_to: dateTimeFormatterForDateInput(response.data.access_to),
              status: response.data.status,
              role: response.data.role,
              period: response.data.period,
              ipaddress: response.data.ipaddress
            })
            this.userTransactions = response.data.transactions
            this.$refs.loadingSpinner.close()
            return this.user
          })
          .catch(error => {
            this.showErrorAlert(error)
            console.log(error)
          })
      }
    },

    async updateUserData() {
      this.$refs.loadingSpinner.open()
      await usersService
        .updateUserData(this.userId, this.user)
        .then(response => {
          this.user = ref<User>({
            previous_id: response.data.previous_id,
            name: response.data.name,
            street: response.data.street,
            postcode: response.data.postcode,
            city: response.data.city,
            country_id: response.data.country_id,
            language: response.data.language,
            email: response.data.email,
            created_at: dateTimeFormatter(response.data.created_at),
            email_verified_at: dateTimeFormatter(
              response.data.email_verified_at
            ),
            updated_at: dateTimeFormatter(response.data.updated_at),
            access_to: dateTimeFormatterForDateInput(response.data.access_to),
            status: response.data.status,
            role: response.data.role,
            period: response.data.period,
            ipaddress: response.data.ipaddress
          })
          this.$refs.loadingSpinner.close()
          this.showSuccessAlert('User successfully updated!')
          return this.user
        })
        .catch(error => {
          console.log(error)
          this.showErrorAlert(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
