import { Module } from 'vuex'
import PaginationState from '@/store/interfaces/PaginationState'
import PaginationGetters from '@/store/interfaces/PaginationGetters'
const paginationSettings: Module<PaginationState, any> = {
  namespaced: true,
  state: {
    perPage: null
  },
  getters: {
    getPerPage(state) {
      return state.perPage
    }
  },
  mutations: {
    SET_PER_PAGE(state, value: number) {
      state.perPage = value
    }
  },
  actions: {
    setPerPage({ commit }, perPage: number): void {
      commit('SET_PER_PAGE', perPage)
    }
  }
}

export default paginationSettings
