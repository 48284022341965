<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full">
        <div
          class="shadow overflow-hidden bg-white border-b border-gray-200 sm:rounded-lg"
        >
          <ModelsDataFilter
            ref="tableFilter"
            :perPageSelected="perPageSelected"
          />
          <Pagination
            :routeName="routeName"
            :pagination="paginationData"
            :searchString="search"
            :sortBy="sortBy"
            :sortDirection="sortDirection"
          />
          <ModelsDataTable :tableData="modelsData" :columns="columns" />
          <Pagination
            :routeName="routeName"
            :pagination="paginationData"
            :searchString="search"
            :sortBy="sortBy"
            :sortDirection="sortDirection"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from 'vue'
import LoadingSpinner from '../components/lib/LoadingSpinner.vue'
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import ModelsDataFilter from '@/components/ModelsDataFilter.vue'
import ModelsDataTable from '@/components/ModelsDataTable.vue'
import Pagination from '../components/Pagination.vue'
import { mapGetters } from 'vuex'

import { modelsDataService } from '@/services/ModelsData.service'

export default defineComponent({
  name: 'ModelsData',
  components: {
    LoadingSpinner,
    ConfirmDialogue,
    ModelsDataFilter,
    ModelsDataTable,
    Pagination
  },
  mounted() {
    if (this.$route.query.search) {
      this.getModelsData(this.$route.query.search)
    }
    this.$refs.loadingSpinner.open()
  },
  created() {
    watchEffect(() => {
      if (this.$route.query.search) {
        this.getModelsData(this.$route.query.search)
      }
      this.getModelsData(this.search)
    })
  },
  data() {
    const perPage = 10
    const page = 1
    const search = null
    const sortBy = null
    const sortDirection = 'ASC'
    return {
      routeName: 'modelsData',
      perPage: perPage,
      page: page,
      search: search,
      sortBy: sortBy,
      sortDirection: sortDirection,
      paginationData: Object,
      perPageSelected: null,
      modelsData: null,
      columns: [
        {
          key: 'id',
          label: 'ID',
          sortable: true
        },
        {
          key: 'model_preview_image',
          label: 'Vorschau Bild',
          sortable: false
        },
        {
          key: 'alias',
          label: 'Alias',
          sortable: true
        },
        {
          key: 'firstname',
          label: 'vorname',
          sortable: true
        },
        {
          key: 'lastname',
          label: 'Nachname',
          sortable: true
        },
        {
          key: 'model_descriptions',
          label: 'Beschreibungen',
          sortable: false
        }
      ]
    }
  },
  methods: {
    ...mapGetters({
      perPageGetter: 'paginationSettings/getPerPage'
    }),
    async getModelsData(search: string = null) {
      this.search = search
      if (search) {
        this.$refs.loadingSpinner.open()
        await this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            search: search
          }
        })
      }
      const perPageSettings = this.perPageGetter() ?? this.perPage
      const page = this.$route.query.page ?? this.page
      const sortBy = this.$route.query.sortBy ?? this.sortBy
      const sortDirection =
        this.$route.query.sortDirection ?? this.sortDirection

      this.perPageSelected = parseInt(perPageSettings)

      await modelsDataService
        .getModels(
          parseInt(perPageSettings),
          page,
          search,
          sortBy,
          sortDirection
        )
        .then(response => {
          this.paginationData = response.data.meta
          this.modelsData = response.data.data
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
        })
    },
    async deleteModelData(id: number) {
      if (id) {
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Video Set löschen',
          message: 'Bist du sicher, dass du dieses Model löschen möchtest.',
          okButton: 'Löschen'
        })
        if (ok) {
          this.$refs.loadingSpinner.open()
          await modelsDataService.deleteModel(id)
          await this.getModelsData()
        }
      }
    }
  }
})
</script>

<style scoped></style>
