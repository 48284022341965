
import { defineComponent, ref } from 'vue'
import ImageSetsUploader from '../components/ImageSetsUploader.vue'
import GoBackButton from '@/components/GoBackButton.vue'

export default defineComponent({
  name: 'ImageSetsContentUpload',
  components: { GoBackButton, ImageSetsUploader },
  data() {
    return {
      image_set_id: this.$route.params.image_set_id
    }
  }
})
