<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">Serien Daten</h2>
    <form @submit.prevent="updateSeriesDescription" :validation-schema="schema">
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="title"
          >Tietel</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="title"
          name="title"
          v-model="seriesDescriptionFormData.title"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="description"
          >Beschreibung</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="textarea"
          id="description"
          name="description"
          v-model="seriesDescriptionFormData.description"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="keywords"
          >Keywords</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="keywords"
          name="keywords"
          v-model="seriesDescriptionFormData.keywords"
        />
      </div>

      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="language"
          >Sprache</label
        >
        <select
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="select"
          id="language"
          name="language"
          v-model="seriesDescriptionFormData.language"
        >
          <option value="">Sprache</option>
          <option
            v-for="(language, index) in languageSelect"
            :key="index"
            :value="language.code"
            >{{ language.name }}
          </option>
        </select>
        <ErrorMessage name="content_origin" class="text-red-600" />
      </div>
      <div class="flex justify-between mt-6">
        <GoBackButton />
        <button
          type="submit"
          class="px-4 py-2 bg-gray-800 text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
        >
          Update !
        </button>
      </div>
    </form>
  </div>

  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import * as yup from 'yup'

import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import { languageSelect } from '@/use/languageSelectData'
import Alerts from '@/components/lib/Alerts.vue'
import GoBackButton from '@/components/GoBackButton.vue'
import { seriesService } from '@/services/Series.service'
import { SeriesDescription } from '@/interfaces/SeriesDescrition'

export default defineComponent({
  name: 'EditSeriesDescription',
  components: {
    LoadingSpinner,
    Alerts,
    GoBackButton
  },

  data() {
    const series_description_id: string | string[] = null
    const schema = yup.object({
      language: yup
        .string()
        .required()
        .max(2),
      title: yup
        .string()
        .required()
        .min(3),
      description: yup
        .string()
        .required()
        .min(3),
      keywords: yup
        .string()
        .required()
        .min(3)
    })
    return {
      series_description_id,
      languageSelect,
      seriesDescriptionFormData: {} as SeriesDescription,
      alertType: null,
      schema
    }
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    this.series_description_id = this.$route.params.series_description_id
    this.getDescription(this.series_description_id)
  },
  methods: {
    async getDescription(series_description_id: string | string[]) {
      await seriesService
        .getSeriesDescription(series_description_id)
        .then(response => {
          this.seriesDescriptionFormData = ref<SeriesDescription>({
            language: response.data.language,
            title: response.data.title,
            description: response.data.description,
            keywords: response.data.keywords
          })
          this.$refs.loadingSpinner.close()
          return this.seriesDescriptionFormData
        })
        .catch(error => {
          this.$refs.loadingSpinner.close()
          this.showErrorAlert(error.message)
        })
    },
    async updateSeriesDescription() {
      this.$refs.loadingSpinner.open()
      await seriesService
        .updateSeriesDescription(
          this.series_description_id,
          this.seriesDescriptionFormData
        )
        .then(response => {
          this.seriesDescriptionFormData = ref<SeriesDescription>({
            language: response.data.language,
            title: response.data.title,
            description: response.data.description,
            keywords: response.data.keywords
          })
          this.$refs.loadingSpinner.close()
          this.showSuccessAlert('Serie wurde upgedatet')
          return this.seriesDescriptionFormData
        })
        .catch(error => {
          this.showErrorAlert(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>

<style scoped></style>
