
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import { languageSelect } from '@/use/languageSelectData'
import { staticSiteSchema } from '@/schemas/staticSiteSchema'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'

import { StaticSite } from '@/interfaces/StaticSite'
import { staticSitesService } from '@/services/StaticSites.service'

export default defineComponent({
  name: 'StaticSitesForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    LoadingSpinner,
    GoBackButton,
    SaveButton,
    Alerts
  },
  data() {
    const static_site_id: number = null
    return {
      static_site_id,
      staticSiteFormData: {} as StaticSite,
      initialFormValues: {} as StaticSite,
      languageSelect,
      staticSiteSchema,
      alertType: null
    }
  },
  mounted() {
    if (this.$route.params.static_site_id) {
      this.$refs.loadingSpinner.open()
    }
  },
  created() {
    this.static_site_id = this.$route.params.static_site_id
    if (this.static_site_id) {
      this.getStaticSiteData(this.static_site_id)
    }
  },
  methods: {
    async saveStaticSiteData(data) {
      console.log('saveStaticSiteData clicked')
      if (!this.$route.params.static_site_id) {
        this.$refs.loadingSpinner.open()
        await staticSitesService
          .createStaticSiteData(data)
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Statische Seite erfolgreich gespeichert!')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      } else {
        this.$refs.loadingSpinner.open()
        await staticSitesService
          .updateStaticSiteData(this.$route.params.static_site_id, data)
          .then(response => {
            this.staticSiteFormData = ref<StaticSite>({
              language: response.data.language,
              title: response.data.title,
              slug: response.data.slug,
              content: response.data.content
            })
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Statische Seite erfolgreich updated!')
            return this.staticSiteFormData
          })
          .catch(error => {
            this.showErrorAlert(error)
            console.log(error)
          })
      }
    },

    async getStaticSiteData(static_site_id: number) {
      await staticSitesService
        .getStaticSiteData(static_site_id)
        .then(response => {
          this.initialFormValues = ref<StaticSite>({
            language: response.data.language,
            title: response.data.title,
            slug: response.data.slug,
            content: response.data.content
          })
          this.$refs.loadingSpinner.close()
          return this.initialFormValues
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
