import axios from 'axios'
import { StaticSite } from '@/interfaces/StaticSite'

class StaticSitesService {
  async getStaticSites() {
    return await axios.get(`api/sub_sites`)
  }

  async getStaticSiteData(id: number) {
    return await axios.get(`api/sub_sites/${id}`)
  }

  async createStaticSiteData(staticSite: Array<StaticSite>) {
    return await axios.post(`api/sub_sites`, staticSite)
  }

  async updateStaticSiteData(id: number, staticSite: Array<StaticSite>) {
    return await axios.put(`api/sub_sites/${id}`, staticSite)
  }

  async deleteStaticSite(id: number) {
    return await axios.delete(`api/sub_sites/${id}`)
  }
}

export const staticSitesService = new StaticSitesService()
