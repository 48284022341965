export const cupSizeSelect = [
  '70 A',
  '75 A',
  '80 A',
  '85 A',
  '70 B',
  '75 B',
  '80 B',
  '85 B',
  '70 C',
  '75 C',
  '80 C',
  '85 C',
  '70 D',
  '75 D',
  '80 D',
  '85 D',
  '70 E',
  '75 E',
  '80 E',
  '85 E',
  '70 DD',
  '75 DD',
  '80 DD',
  '85 DD'
]
