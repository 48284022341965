<template>
  <div class="mb-3 flex flex-wrap justify-between">
    <div class="w-1/8 ml-2 mt-3">
      <select
        v-model="selectedValue"
        @change="onChangePerPage($event)"
        class="h-full rounded border block w-full bg-white border-gray-400 text-gray-700 py-2 px-2 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      >
        <option
          :key="index"
          :value="records"
          v-for="(records, index) in perPageValues"
        >
          {{ records }}
        </option>
      </select>
    </div>
    <div class="relative w-1/4 mt-3 mr-2">
      <span
        class="absolute inset-y-0 right-1 flex items-center pl-2 text-gray-400"
      >
        <button
          type="submit"
          class="p-1 focus:outline-none focus:shadow-outline"
        >
          <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            class="w-6 h-6"
          >
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </button>
      </span>
      <input
        v-model.lazy="search"
        class="w-full px-4 py-2 focus:bg-gray-300 max-w-full focus:outline-none border border-gray-200 rounded text-gray-700"
        name="search"
        :placeholder="placeholderSearch"
      />
    </div>
    <div class="relative w-1/8 mt-3 mr-2 content-end">
      <button
        @click="$router.push({ name: 'addImageSet' })"
        class="px-4 py-2 bg-indigo-600 hover:bg-indigo-500 text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
      >
        Bilder Set zufügen !
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'ImagesSetsDataFilter',
  props: {
    perPageSelected: {
      type: Number,
      default: () => 25
    },
    perPageValues: {
      type: Array,
      default: () => [10, 25, 50]
    },
    placeholderSearch: {
      type: String,
      default: 'Search Table'
    }
  },
  data() {
    const search = this.$route.query.search ? this.$route.query.search : null
    return {
      search,
      selectedValue: null
    }
  },
  watch: {
    async search(searchString) {
      if (searchString.length > 0 && searchString.trim()) {
        this.search = searchString
      }
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          search: this.search,
          page: 1
        }
      })
      await this.$parent.getImagesSetsData(this.search)
    }
  },
  created() {
    this.setSelectedValue()
  },
  methods: {
    ...mapActions({
      setPerPageAction: 'paginationSettings/setPerPage'
    }),
    setSelectedValue() {
      this.selectedValue = this.perPageSelected
    },
    setPerPage(event) {
      this.setPerPageAction(event.target.value)
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          perPage: event.target.value,
          page: 1
        }
      })
      this.$parent.perPage = event.target.value
      this.$parent.getImagesSetsData()
    },

    onChangePerPage(event) {
      this.setPerPage(event)
    }
  }
})
</script>
