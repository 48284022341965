<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="bg-indigo-100">
      <tr>
        <th
          v-for="(column, index) in columns"
          :key="index"
          scope="col"
          class=" px-6 py-3 text-left text-xs font-medium text-indigo-700 uppercase tracking-wider"
          :class="[
            column.sortable ? 'cursor-pointer hover:bg-gray-200' : '',
            sortBy === column.key && column.sortable ? 'columnActive' : ''
          ]"
          @click="column.sortable ? sort(column.key) : ''"
        >
          <div class="inline-flex flex-wrap w-full">
            <div class="inline-block text-right mr-3 mt-1 left-0">
              {{ column.label }}
            </div>
            <div v-if="column.sortable" class="text-right right-0">
              <svg
                class="w-3 h-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                v-show="sortBy === column.key && sortDirection === 'ASC'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                ></path>
              </svg>
              <svg
                class="w-3 h-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                v-show="sortBy === column.key && sortDirection === 'DESC'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
          </div>
        </th>

        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium text-indigo-700 uppercase tracking-wider hover:bg-indigo-200"
        >
          <div class="text-right mr-3 mt-1 left-0">
            Edit
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <tr
        v-for="(row, index) in tableData"
        :key="index"
        class="hover:bg-indigo-200"
      >
        <td class="px-2 py-4 break-all">
          <div class="flex flex-row items-center text-sm">
            {{ row.id }}
          </div>
        </td>
        <td class="px-6 py-4 break-all">
          <div class="flex items-center truncate text-sm">
            {{ this.truncateTableString(row.set_name, 25) }} ...
          </div>
        </td>
        <td class="px-6 py-4">
          <div class="flex items-center truncate text-sm">
            {{ this.truncateTableString(row.description, 25) }} ...
          </div>
        </td>
        <td class="px-6 py-4">
          <div class="flex items-center truncate text-sm">
            <div v-if="row.series">
              {{ row.series.title }}
            </div>
            <div v-else>
              <span
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-900 text-white"
              >
                N.A</span
              >
            </div>
          </div>
        </td>
        <td class="px-6 py-4">
          <div class="flex items-center truncate text-sm">
            {{ this.dateTimeFormatter(row.publish_on) }}
          </div>
        </td>

        <td class="px-6 py-4">
          <div class="flex items-center">
            <div
              v-if="
                row.videos_sets_descriptions &&
                  row.videos_sets_descriptions.length > 0
              "
            >
              <div
                v-for="(description, index) in row.videos_sets_descriptions"
                :key="index"
                class="h-6 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2 bg-green-100 text-green-800"
              >
                <router-link
                  :to="{
                    name: 'editVideoSetDescription',
                    params: { video_set_description_id: description.id }
                  }"
                >
                  {{ description.language }}
                </router-link>
              </div>
            </div>
            <div v-else>
              <span
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-900 text-white"
              >
                N.A</span
              >
            </div>
          </div>
        </td>
        <td class="px-6 py-4">
          <div
            v-if="row.videos_sets_preview && row.videos_sets_preview.length > 0"
            class="grid grid-cols-2 gap-1"
          >
            <div
              v-for="(image, index) in row.videos_sets_preview"
              :key="index"
              class="items-center justify-center"
            >
              <img
                :src="
                  image.video_set_preview_url +
                    '/' +
                    row.slug +
                    '/300x300/' +
                    image.image_name
                "
              />
              <div
                class="mr-2 cursor-pointer"
                @click.prevent="this.$parent.movePreviewToTrash(image.id)"
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div v-else>
            <router-link
              :to="{
                name: 'videoSetsPreviewUpload',
                params: { video_set_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900 mr-3"
            >
              <div
                class="h-8 px-2 inline-flex text-xs  flex-wrap flex items-center justify-center font-semibold rounded-md m-2 bg-indigo-500 text-white"
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
              </div>
            </router-link>
          </div>
        </td>
        <td class="px-6 py-4">
          <div v-if="row.videos_sets_content" class="grid grid-cols-1">
            <div
              class="h-6 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2"
              :class="[
                row.videos_sets_content.sd === 1
                  ? 'bg-green-100 text-green-800'
                  : 'bg-gray-700 text-white'
              ]"
            >
              SD
            </div>
            <div
              class="h-6 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2"
              :class="[
                row.videos_sets_content.hd === 1
                  ? 'bg-green-100 text-green-800'
                  : 'bg-gray-700 text-white'
              ]"
            >
              HD
            </div>
            <div
              class="h-6 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2"
              :class="
                row.videos_sets_content.full_hd === 1
                  ? 'bg-green-100 text-green-800'
                  : 'bg-gray-700 text-white'
              "
            >
              FULL HD
            </div>
            <div class="text-xs items-center">
              Dauer: {{ row.videos_sets_content.duration }} Sekunden
            </div>
          </div>
          <div v-else>
            <router-link
              :to="{
                name: 'videoSetsContentUpload',
                params: { video_set_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900 mr-3"
            >
              <div
                class="h-8 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2 bg-indigo-500 text-white"
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
              </div>
            </router-link>
          </div>
        </td>
        <td class="flex-col px-6 py-4 text-right text-sm font-medium">
          <div class="w-full text-right">
            <router-link
              :to="{ name: 'editVideoSet', params: { video_set_id: row.id } }"
              class="text-indigo-600 hover:text-indigo-900"
              >Bearbeiten</router-link
            >
          </div>
          <div class="w-full text-right">
            <a
              href="#"
              class="text-red-600 hover:text-indigo-900"
              @click.prevent="this.$parent.deleteVideoSet(row.id)"
              >Löschen</a
            >
          </div>
          <div class="w-full text-right">
            <router-link
              :to="{
                name: 'addVideoSetDescription',
                params: { video_set_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900"
              >Beschreibung</router-link
            >
          </div>
          <div class="w-full text-right">
            <router-link
              :to="{
                name: 'videoSetsPreviewUpload',
                params: { video_set_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900"
              >Vorschaubilder hochladen</router-link
            >
          </div>
          <div class="w-full text-right">
            <router-link
              :to="{
                name: 'videoSetsContentUpload',
                params: { video_set_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900"
              >Video hochladen</router-link
            >
          </div>
          <div
            class="w-full text-right"
            v-if="
              row.videos_sets_content &&
                row.videos_sets_content.video_member_url
            "
          >
            <a
              :href="row.videos_sets_content.video_member_url + '/' + row.id"
              target="_blank"
              class="text-indigo-600 hover:text-indigo-900"
              >Video Member Vorschau</a
            >
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import truncateTableString from '@/filters/truncateTableString'
import dateTimeFormatter from '@/filters/dateTimeFormatter'

export default defineComponent({
  name: 'VideosSetsTable',
  props: {
    tableData: Object,
    columns: Array
  },
  data() {
    const sortBy = 'id'
    const sortDirection = 'DESC'
    return {
      sortBy: sortBy,
      sortDirection: sortDirection
    }
  },
  methods: {
    truncateTableString,
    dateTimeFormatter,
    sort(column) {
      if (column === this.sortBy) {
        if (this.sortDirection == 'ASC') {
          this.sortDirection = 'DESC'
        } else {
          this.sortDirection = 'ASC'
        }
      }
      if (column != this.sortBy) {
        this.sortDirection = 'ASC'
        this.sortBy = column
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          sortDirection: this.sortDirection,
          sortBy: this.sortBy,
          page: 1
        }
      })
      this.$parent.sortBy = this.sortBy
      this.$parent.sortDirection = this.sortDirection
    }
  }
})
</script>

<style>
.columnActive {
  @apply bg-indigo-400;
  @apply text-white !important;
}
</style>
