
import { defineComponent, ref, onMounted } from 'vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import ConfirmDialogue from '@/components/ConfirmDialogue.vue'
import Pagination from '@/components/Pagination.vue'
import PaymentPackagesTable from '@/components/PaymentPackagesTable.vue'
import { paymentPackagesService } from '@/services/PaymentPackages.Service'
import PaymentPackages from '@/interfaces/PaymentPackages'
import PaginationData from '@/interfaces/PaginationData'
import PaymentPackagesFilter from '@/components/PaymentPackagesFilter.vue'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'PaymentPackages',
  components: {
    PaymentPackagesFilter,
    LoadingSpinner,
    ConfirmDialogue,
    Pagination,
    PaymentPackagesTable
  },
  setup() {
    const store = useStore()
    const loadingSpinner = ref(null)
    const confirmDialogue = ref(null)
    const paginationData = ref<PaginationData>({
      per_page: 10,
      from: 1,
      to: 10,
      total: 0,
      current_page: 1,
      last_page: 1
    })
    const routeName = 'paymentPackages'
    const paymentPackagesData = ref<PaymentPackages[]>()
    const page = ref<number>(1)
    const perPage = ref<number>(10)
    const searchString = ref<string>()
    const sortBy = ref<string>()
    const sortDirection = ref<string>('ASC')
    const perPageSelected = ref<number>()

    perPageSelected.value =
      store.getters['paginationSettings/getPerPage'] ?? perPage.value

    const columns = [
      {
        key: 'id',
        label: 'Id',
        sortable: true
      },
      {
        key: 'epoch_pi_code',
        label: 'Epoch PI code',
        sortable: false
      },
      {
        key: 'amount',
        label: 'Preis gesamt',
        sortable: true
      },
      {
        key: 'amount_month',
        label: 'Preis Monat',
        sortable: true
      },
      {
        key: 'amount_day',
        label: 'Preis Tag',
        sortable: false
      },
      {
        key: 'price_before',
        label: 'Vorheriger Preis',
        sortable: false
      },
      {
        key: 'currency',
        label: 'Währung',
        sortable: false
      },
      {
        key: 'period',
        label: 'Laufzeit',
        sortable: true
      },
      {
        key: 'is_payment_site',
        label: 'Pay Seite',
        sortable: false
      },
      {
        key: 'is_recurring',
        label: 'Wiederkehrend',
        sortable: false
      },
      {
        key: 'is_highlight',
        label: 'Highlight',
        sortable: false
      },
      {
        key: 'is_active',
        label: 'Active',
        sortable: false
      },
      {
        key: 'order',
        label: 'Reihenfolge',
        sortable: false
      }
    ]

    onMounted(async () => {
      await getPaymentPackagesData(searchString.value)
    })
    const getPaymentPackagesData = async (search: string = null) => {
      loadingSpinner.value.open()
      searchString.value = search
      await paymentPackagesService
        .getPaymentPackages(
          perPage.value,
          page.value,
          searchString.value,
          sortBy.value,
          sortDirection.value
        )
        .then(response => {
          paginationData.value = response.data.meta
          paymentPackagesData.value = response.data.data
          loadingSpinner.value.close()
        })
        .catch(error => {
          console.log(error)
        })
    }
    const deletePaymentPackage = async (id: number) => {
      if (id) {
        const ok = await confirmDialogue.value.show({
          title: 'Payment Paket löschen',
          message:
            'Bist du sicher, dass du dieses Payment Paket löschen möchtest.',
          okButton: 'Löschen'
        })
        console.log(ok)
        if (ok) {
          loadingSpinner.value.open()
          await paymentPackagesService.destroyPaymentPackage(id)
          await getPaymentPackagesData()
        }
      }
    }

    return {
      getPaymentPackagesData,
      paginationData,
      routeName,
      searchString,
      sortBy,
      sortDirection,
      loadingSpinner,
      confirmDialogue,
      columns,
      perPageSelected,
      paymentPackagesData,
      deletePaymentPackage
    }
  }
})
