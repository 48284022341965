export const memberShipPeriod = [
  {
    name: '30 days',
    value: 30
  },
  {
    name: '90 days',
    value: 90
  },
  {
    name: '180 days',
    value: 180
  },
  {
    name: '365 days',
    value: 365
  },
  {
    name: '3650 days',
    value: 3650
  }
]
