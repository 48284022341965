<template>
  <div>
    <h3 class="text-gray-700 text-3xl font-medium">Dashboard</h3>
    <span class="p-0.5"></span>
    <h3 class="text-base font-semibold leading-6 text-gray-900">User data</h3>

    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <DashboardUserDataComponent
        v-for="(dashboardData, index) in dashboardDataArray"
        :key="index"
        :dashboard-data="dashboardData"
      ></DashboardUserDataComponent>
    </dl>
    <span class="p-0.5"></span>
    <h3 class="text-base font-semibold leading-6 text-gray-900">
      Revenue data
    </h3>

    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <DashboardRevenueDataComponent
        v-for="(dashboardData, index) in dashboardRevenueDataArray"
        :key="index"
        :dashboard-data="dashboardData"
      ></DashboardRevenueDataComponent>
    </dl>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import DashboardUserDataComponent from '@/components/DashboardUserDataComponent.vue'
import DashboardRevenueDataComponent from '@/components/DashboardRevenueDataComponent.vue'
import { dashboardDataService } from '@/services/DashboardData.service'
import DashboardData from '@/interfaces/DashboardData'

export default defineComponent({
  name: 'Dashboard',
  components: {
    DashboardUserDataComponent,
    DashboardRevenueDataComponent
  },
  setup() {
    const dashboardDataArray = ref<DashboardData[]>([])
    const dashboardRevenueDataArray = ref<DashboardData[]>([])
    onMounted(async () => {
      await activePayUserData()
      await activeFreeUser()
      await inactiveUser()
      await currentMonthNewPayUser()
      await currentMonthNewFreeUser()
      await lastMonthNewPayUser()
      await lastMonthNewFreeUser()
      await lastMonthSum()
      await currentMonthSum()
      await currentDaySum()
      await lastSevenDaysSum()
    })
    const activePayUserData = async () => {
      await dashboardDataService
        .getActivePayUser()
        .then(response => {
          const data = response.data.data
          const dashboardData = ref<DashboardData>({
            widgetName: data.name,
            stats: data.total,
            iconName: 'UsersIcon'
          })

          dashboardDataArray.value.push(dashboardData.value)
        })
        .catch(error => {
          console.log(error)
        })
    }

    const activeFreeUser = async () => {
      await dashboardDataService
        .getActiveFreeUser()
        .then(response => {
          const data = response.data.data
          const dashboardData = ref<DashboardData>({
            widgetName: data.name,
            stats: data.total,
            iconName: 'UsersIcon'
          })

          dashboardDataArray.value.push(dashboardData.value)
        })
        .catch(error => {
          console.log(error)
        })
    }

    const inactiveUser = async () => {
      await dashboardDataService
        .getInactiveUser()
        .then(response => {
          const data = response.data.data
          const dashboardData = ref<DashboardData>({
            widgetName: data.name,
            stats: data.total,
            iconName: 'UsersIcon'
          })

          dashboardDataArray.value.push(dashboardData.value)
        })
        .catch(error => {
          console.log(error)
        })
    }

    const currentMonthNewPayUser = async () => {
      await dashboardDataService
        .getCurrentMonthNewPayUser()
        .then(response => {
          const data = response.data.data
          const dashboardData = ref<DashboardData>({
            widgetName: data.name,
            stats: data.total,
            iconName: 'UsersIcon'
          })

          dashboardDataArray.value.push(dashboardData.value)
        })
        .catch(error => {
          console.log(error)
        })
    }

    const currentMonthNewFreeUser = async () => {
      await dashboardDataService
        .getCurrentMonthNewFreeUser()
        .then(response => {
          const data = response.data.data
          const dashboardData = ref<DashboardData>({
            widgetName: data.name,
            stats: data.total,
            iconName: 'UsersIcon'
          })

          dashboardDataArray.value.push(dashboardData.value)
        })
        .catch(error => {
          console.log(error)
        })
    }

    const lastMonthNewPayUser = async () => {
      await dashboardDataService
        .getLastMonthNewPayUser()
        .then(response => {
          const data = response.data.data
          const dashboardData = ref<DashboardData>({
            widgetName: data.name,
            stats: data.total,
            iconName: 'UsersIcon'
          })

          dashboardDataArray.value.push(dashboardData.value)
        })
        .catch(error => {
          console.log(error)
        })
    }

    const lastMonthNewFreeUser = async () => {
      await dashboardDataService
        .getLastMonthNewFreeUser()
        .then(response => {
          const data = response.data.data
          const dashboardData = ref<DashboardData>({
            widgetName: data.name,
            stats: data.total,
            iconName: 'UsersIcon'
          })

          dashboardDataArray.value.push(dashboardData.value)
        })
        .catch(error => {
          console.log(error)
        })
    }
    const lastMonthSum = async () => {
      await dashboardDataService
        .getLastMonthSum()
        .then(response => {
          const data = response.data.data
          const dashboardData = ref<DashboardData>({
            widgetName: data.name,
            stats: data.total,
            iconName: 'MoneyIcon'
          })

          dashboardRevenueDataArray.value.push(dashboardData.value)
        })
        .catch(error => {
          console.log(error)
        })
    }

    const currentMonthSum = async () => {
      await dashboardDataService
        .getCurrentMonthSum()
        .then(response => {
          const data = response.data.data
          const dashboardData = ref<DashboardData>({
            widgetName: data.name,
            stats: data.total,
            iconName: 'MoneyIcon'
          })

          dashboardRevenueDataArray.value.push(dashboardData.value)
        })
        .catch(error => {
          console.log(error)
        })
    }

    const currentDaySum = async () => {
      await dashboardDataService
        .getCurrentDaySum()
        .then(response => {
          const data = response.data.data
          const dashboardData = ref<DashboardData>({
            widgetName: data.name,
            stats: data.total,
            iconName: 'MoneyIcon'
          })

          dashboardRevenueDataArray.value.push(dashboardData.value)
        })
        .catch(error => {
          console.log(error)
        })
    }
    const lastSevenDaysSum = async () => {
      await dashboardDataService
        .getLastSevenDaysSum()
        .then(response => {
          const data = response.data.data
          const dashboardData = ref<DashboardData>({
            widgetName: data.name,
            stats: data.total,
            iconName: 'MoneyIcon'
          })

          dashboardRevenueDataArray.value.push(dashboardData.value)
        })
        .catch(error => {
          console.log(error)
        })
    }

    return {
      dashboardDataArray,
      dashboardRevenueDataArray
    }
  }
})
</script>
