
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingSpinner',
  props: {
    animationDuration: {
      type: Number,
      default: 1000
    },
    size: {
      type: Number,
      default: 60
    },
    color: {
      type: String,
      default: '#fff'
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    open() {
      this.isVisible = true
    },

    close() {
      this.isVisible = false
    }
  },
  computed: {
    spinnerStyle() {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`
      }
    },
    circleStyle() {
      return {
        borderWidth: `${this.size / 10}px`,
        animationDuration: `${this.animationDuration}ms`
      }
    },
    circle1Style() {
      return Object.assign(
        {
          borderTopColor: this.color
        },
        this.circleStyle
      )
    },
    circle2Style() {
      return Object.assign(
        {
          borderBottomColor: this.color
        },
        this.circleStyle
      )
    }
  }
})
