
import { defineComponent } from 'vue'
import LightBoxModal from './LightBoxModal.vue'

import VLazyImage from 'v-lazy-image'

export default defineComponent({
  name: 'LightBox',
  components: { LightBoxModal, VLazyImage },
  data() {
    const imageName = null
    const url = null
    return {
      url,
      imageName
    }
  },
  methods: {
    async show(opt: Record<string, string>) {
      this.url =
        opt.imagePreviewUrl + '/' + opt.slug + '/1024x0/' + opt.imageName
      this.imageName = opt.imageName
      console.log(opt.imageName)
      if (opt.cancelButton) {
        this.cancelButton = opt.cancelButton
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    _cancel() {
      this.$refs.popup.close()
      this.url = null
    }
  }
})
