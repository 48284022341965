
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
export default defineComponent({
  name: 'PaymentPackagesFilter',
  props: {
    perPageSelected: {
      type: Number,
      default: () => 25
    },
    perPageValues: {
      type: Array,
      default: () => [10, 25, 50]
    },
    placeholderSearch: {
      type: String,
      default: 'Search Table'
    }
  },
  setup(props, { emit }) {
    //const instance = getCurrentInstance();
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const searchString = ref<string>(null)
    const selectedValue = ref<number>()
    const getPerPage = computed(
      () => store.getters['paginationSettings/getPerPage']
    )
    selectedValue.value = getPerPage.value ?? props.perPageSelected

    const onChangePerPage = event => {
      store.dispatch('paginationSettings/setPerPage', event.target.value)
    }
    watch(searchString, searchString => {
      let search = null
      if (searchString.length > 0 && searchString.trim()) {
        search = searchString
      }
      router.push({
        path: route.path,
        query: {
          ...route.query,
          search: search,
          page: 1
        }
      })
      emit('search')
    })

    return {
      searchString,
      selectedValue,
      onChangePerPage
    }
  }
})
