
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Modal',

  data() {
    return {
      isVisible: false
    }
  },

  methods: {
    open() {
      this.isVisible = true
    },

    close() {
      this.isVisible = false
    }
  }
})
