<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">Serien Daten</h2>
    <Form @submit="createSeriesDescription" :validation-schema="schema">
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="title"
          >Tietel</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="title"
          name="title"
          v-model="seriesDescriptionFormData.title"
        />
        <ErrorMessage name="title" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="description"
          >Beschreibung</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="textarea"
          id="description"
          name="description"
          v-model="seriesDescriptionFormData.description"
        />
        <ErrorMessage name="description" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="keywords"
          >Keywords</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="keywords"
          name="keywords"
          v-model="seriesDescriptionFormData.keywords"
        />
        <ErrorMessage name="slug" class="text-red-600" />
      </div>

      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="language"
          >Sprache</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="select"
          id="language"
          name="language"
          v-model="seriesDescriptionFormData.language"
        >
          <option value="">Sprache</option>
          <option
            v-for="(language, index) in languageSelect"
            :key="index"
            :value="language.code"
            >{{ language.name }}
          </option>
        </Field>
        <ErrorMessage name="content_origin" class="text-red-600" />
      </div>
      <div class="flex justify-between mt-6">
        <GoBackButton />
        <button
          type="submit"
          class="px-4 py-2 bg-gray-800 text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
        >
          Update !
        </button>
      </div>
    </Form>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as yup from 'yup'

import { languageSelect } from '@/use/languageSelectData'
import Alerts from '@/components/lib/Alerts.vue'
import GoBackButton from '@/components/GoBackButton.vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import {SeriesDescription} from '@/interfaces/SeriesDescrition'
import { seriesService } from '@/services/Series.service'

export default defineComponent({
  name: 'AddSeriesDescription',
  components: {
    Form,
    Field,
    ErrorMessage,
    Alerts,
    GoBackButton,
    LoadingSpinner
  },
  data() {
    const series_id: number = null
    const schema = yup.object({
      language: yup
        .string()
        .required()
        .max(2),
      title: yup
        .string()
        .required()
        .min(3),
      description: yup
        .string()
        .required()
        .min(3),
      keywords: yup
        .string()
        .required()
        .min(3)
    })
    return {
      series_id,
      languageSelect,
      seriesDescriptionFormData: {} as SeriesDescription,
      alertType: null,
      schema
    }
  },
  created() {
    this.series_id = this.$route.params.series_id
  },
  methods: {
    async createSeriesDescription(data) {
      this.$refs.loadingSpinner.open()
      await seriesService
        .createSeriesDescription(this.series_id, data)
        .then(response => {
          this.$refs.loadingSpinner.close()
          this.showSuccessAlert('Serien Beschreibung erfolgreich gespeichert!')
          return response.data
        })
        .catch(error => {
          this.$refs.loadingSpinner.close()
          this.showErrorAlert(error.message)
        })
    },
    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>

<style scoped></style>
