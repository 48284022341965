<template>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <EditUser />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import EditUser from '../components/EditUser.vue'
import ConfirmDialogue from '../components/ConfirmDialogue.vue'

export default defineComponent({
  name: 'UserData',
  components: { ConfirmDialogue, EditUser },
  data() {
    return {
      userId: null
    }
  }
})
</script>

<style scoped></style>
