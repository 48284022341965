
import { defineComponent } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as yup from 'yup'

import { languageSelect } from '@/use/languageSelectData'
import Alerts from '@/components/lib/Alerts.vue'
import GoBackButton from '@/components/GoBackButton.vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import {SeriesDescription} from '@/interfaces/SeriesDescrition'
import { seriesService } from '@/services/Series.service'

export default defineComponent({
  name: 'AddSeriesDescription',
  components: {
    Form,
    Field,
    ErrorMessage,
    Alerts,
    GoBackButton,
    LoadingSpinner
  },
  data() {
    const series_id: number = null
    const schema = yup.object({
      language: yup
        .string()
        .required()
        .max(2),
      title: yup
        .string()
        .required()
        .min(3),
      description: yup
        .string()
        .required()
        .min(3),
      keywords: yup
        .string()
        .required()
        .min(3)
    })
    return {
      series_id,
      languageSelect,
      seriesDescriptionFormData: {} as SeriesDescription,
      alertType: null,
      schema
    }
  },
  created() {
    this.series_id = this.$route.params.series_id
  },
  methods: {
    async createSeriesDescription(data) {
      this.$refs.loadingSpinner.open()
      await seriesService
        .createSeriesDescription(this.series_id, data)
        .then(response => {
          this.$refs.loadingSpinner.close()
          this.showSuccessAlert('Serien Beschreibung erfolgreich gespeichert!')
          return response.data
        })
        .catch(error => {
          this.$refs.loadingSpinner.close()
          this.showErrorAlert(error.message)
        })
    },
    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
