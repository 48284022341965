
import { defineComponent } from 'vue'

import truncateTableString from '@/filters/truncateTableString'
import dateTimeFormatter from '@/filters/dateTimeFormatter'

export default defineComponent({
  name: 'ImagesSetsTable',
  props: {
    tableData: Object,
    columns: Array
  },
  data() {
    const sortBy = 'id'
    const sortDirection = 'DESC'
    return {
      sortBy: sortBy,
      sortDirection: sortDirection
    }
  },
  methods: {
    truncateTableString,
    dateTimeFormatter,
    sort(column) {
      if (column === this.sortBy) {
        if (this.sortDirection == 'ASC') {
          this.sortDirection = 'DESC'
        } else {
          this.sortDirection = 'ASC'
        }
      }
      if (column != this.sortBy) {
        this.sortDirection = 'ASC'
        this.sortBy = column
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          sortDirection: this.sortDirection,
          sortBy: this.sortBy,
          page: 1
        }
      })
      this.$parent.sortBy = this.sortBy
      this.$parent.sortDirection = this.sortDirection
    }
  }
})
