
import { defineComponent } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as yup from 'yup'

import LoadingSpinner from './lib/LoadingSpinner.vue'
import Alerts from '../components/lib/Alerts.vue'
import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import { User } from '@/interfaces/User'
import { usersService } from '@/services/Users.service'
import { countryRegions } from '@/use/countryRegionSelectData'
import { languageSelect } from '@/use/languageSelectData'
import { userStatus } from '@/use/userStatus'
import { userRoles } from '@/use/userRoles'
import { memberShipPeriod } from '@/use/memberShipPeriod'

export default defineComponent({
  name: 'AddUserForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    Alerts,
    GoBackButton,
    SaveButton,
    LoadingSpinner
  },
  data() {
    const schema = yup.object({
      name: yup
        .string()
        .required()
        .min(3),
      email: yup
        .string()
        .required()
        .email(),
      country_id: yup.string().required(),
      language: yup.string().required(),
      status: yup.string().required(),
      role: yup.string().required(),
      period: yup.number().required(),
      password: yup
        .string()
        .required()
        .min(10)
    })
    return {
      countryRegions,
      languageSelect,
      userStatus,
      userRoles,
      memberShipPeriod,
      userData: {} as User,
      alertType: null,
      schema,
      error_messages: null
    }
  },
  methods: {
    async createUser(data) {
      this.$refs.loadingSpinner.open()
      await usersService
        .createUser(data)
        .then(response => {
          this.$refs.loadingSpinner.close()
          this.showSuccessAlert('User successfully saved!')
          console.log(response.data)
          return response.data
        })
        .catch(error => {
          this.$refs.loadingSpinner.close()
          this.error_messages = error.response.data
          this.showErrorAlert(error.message)
        })
    },
    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
