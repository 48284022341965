
import { defineComponent } from 'vue'
import Sidebar from './Sidebar.vue'
import Header from './Header.vue'

export default defineComponent({
  name: 'DashboardLayout',
  components: {
    Header,
    Sidebar
  }
})
