
import { defineComponent } from 'vue'

import truncateTableString from '@/filters/truncateTableString'

export default defineComponent({
  name: 'SeriesTable',
  props: {
    tableData: Object,
    columns: Array
  },
  methods: {
    truncateTableString
  }
})
