<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">
      Model Content Relation
    </h2>
    <div class="border-t border-b pt-8 pb-8 grid grid-cols-3 gap-8">
      <div class="flex flex-col justify-start">
        <div
          class="flex flex-col w-full object-cover  justify-items-start border rounded-lg overflow-hidden"
          style="min-heigth:300px"
        >
          <div
            v-if="
              model_data.model_preview_images &&
                model_data.model_preview_images.length > 0
            "
          >
            <img
              class="w-full h-full object-cover"
              :src="
                model_data.model_preview_images[0].actor_preview_url +
                  '/' +
                  model_data.slug +
                  '/450x0/' +
                  model_data.model_preview_images[0].image_name
              "
            />
          </div>
          <div v-else>
            <img
              class="w-full h-full object-cover"
              src="https://via.placeholder.com/450x300/"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="flex flex-col gap-4 pt-16">
          <h1 class="capitalize text-4xl font-extrabold">
            {{ model_data.alias }}
          </h1>
          <h2 class="text-3xl">
            {{ model_data.firstname }} {{ model_data.lastname }}
          </h2>
          <GoBackButton />
        </div>
      </div>
    </div>
    <div></div>
  </div>
  <div class="flex flex-wrap">
    <div class="w-full">
      <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
          <a
            class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
            v-on:click="toggleTabs(1)"
            v-bind:class="{
              'text-blueGray-600 bg-white': openTab !== 1,
              'text-white bg-indigo-600': openTab === 1
            }"
          >
            Video Sets
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
          <a
            class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
            v-on:click="toggleTabs(2)"
            v-bind:class="{
              'text-blueGray-600 bg-white': openTab !== 2,
              'text-white bg-indigo-600': openTab === 2
            }"
          >
            Bilder Sets
          </a>
        </li>
      </ul>
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
      >
        <div class="px-4 py-5 flex-auto">
          <form @submit.prevent="saveModelContentSets">
            <div class="tab-content tab-space">
              <div
                v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }"
              >
                <div v-if="videoSetsToSelect && videoSetsToSelect.length > 0">
                  <Multiselect
                    name="videoSets"
                    @deselect="videoSetDeselected"
                    v-model="modelContentSetsData.videos_sets"
                    mode="tags"
                    placeholder="Wähle Video Sets aus"
                    :searchable="true"
                    :options="videoSetsToSelect"
                    :closeOnSelect="false"
                    :createTag="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div
                v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }"
              >
                <div v-if="imageSetsToSelect && imageSetsToSelect.length > 0">
                  <Multiselect
                    name="imageSets"
                    @deselect="imagesSetDeselected"
                    v-model="modelContentSetsData.images_sets"
                    mode="tags"
                    placeholder="Wähle Bilder Sets aus"
                    :searchable="true"
                    :options="imageSetsToSelect"
                    :closeOnSelect="false"
                    :createTag="true"
                  >
                  </Multiselect>
                </div>
              </div>
            </div>
            <div class="flex justify-between mt-6">
              <GoBackButton />
              <SaveButton />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import GoBackButton from './GoBackButton.vue'
import { ModelsData } from '@/interfaces/ModelsData'
import { modelsDataService } from '@/services/ModelsData.service'
import Alerts from '@/components/lib/Alerts.vue'
import Multiselect from '@vueform/multiselect'
import { ContentSetsSelect } from '@/interfaces/ContentSetsSelect'
import SaveButton from './SaveButton.vue'
import { ModelContentsSetsData } from '@/interfaces/ModelContentsSetsData'

export default defineComponent({
  name: 'ModelContentDataForm',
  components: {
    Multiselect,
    GoBackButton,
    SaveButton,
    LoadingSpinner,
    Alerts
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  data() {
    const model_id: number = null
    const imageSetsToSelect: ContentSetsSelect[] = []
    const videoSetsToSelect: ContentSetsSelect[] = []
    return {
      model_id,
      model_data: {} as ModelsData,
      alertType: null,
      imageSets: null,
      openTab: 1,
      imageSetsToSelect,
      videoSetsToSelect,
      modelContentSetsData: {} as ModelContentsSetsData
    }
  },
  created() {
    this.model_id = this.$route.params.model_id
    this.getModelData(this.model_id)
    this.getContentDataForModel()
  },
  methods: {
    toggleTabs(tabNumber: number) {
      this.openTab = tabNumber
    },
    async getModelData(model_id: number) {
      await modelsDataService
        .getModel(model_id)
        .then(response => {
          this.model_data = ref<ModelsData>({
            model_preview_images: response.data.data.actors_preview_images,
            slug: response.data.data.slug,
            alias: response.data.data.alias,
            firstname: response.data.data.firstname,
            lastname: response.data.data.lastname,
            gender: response.data.data.gender,
            age: response.data.data.age,
            nationality: response.data.data.nationality,
            height: response.data.data.height,
            cup_size: response.data.data.cup_size,
            weight: response.data.data.weight,
            zodiac: response.data.data.zodiac,
            type: response.data.data.type,
            genital_area: response.data.data.genital_area,
            preferences: response.data.data.preferences
          })
          const videoSetsExists: string[] = []
          const imageSetsExists: string[] = []
          for (const contentData of response.data.data.actors_content) {
            if (contentData.content_type === 'videos_sets') {
              videoSetsExists.push(contentData.content_id)
            }
            if (contentData.content_type === 'images_sets') {
              imageSetsExists.push(contentData.content_id)
            }
          }
          this.modelContentSetsData.videos_sets = videoSetsExists
          this.modelContentSetsData.images_sets = imageSetsExists
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },
    async getContentDataForModel() {
      await modelsDataService
        .getContentToActorData()
        .then(response => {
          for (const imageSet of response.data.data.image_sets) {
            this.imageSetsToSelect.push({
              value: imageSet.image_set_id.toString(),
              label: imageSet.set_name
            })
          }
          for (const videoSet of response.data.data.video_sets) {
            this.videoSetsToSelect.push({
              value: videoSet.videos_sets_id.toString(),
              label: videoSet.set_name
            })
          }
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
        })
    },
    async saveModelContentSets() {
      if (this.model_id) {
        this.$refs.loadingSpinner.open()
        await modelsDataService
          .createModelContentSets(this.model_id, this.modelContentSetsData)
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Model Imagesets erfolgreich gespeichert')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      }
    },

    async imagesSetDeselected(content_id: number) {
      if (content_id) {
        await modelsDataService
          .deleteModelContentSet(this.model_id, content_id, 'images_sets')
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Model Bilder Set erfolgreich entfrent')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      }
    },

    async videoSetDeselected(content_id: number) {
      if (content_id) {
        await modelsDataService
          .deleteModelContentSet(this.model_id, content_id, 'videos_sets')
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Model Video Set erfolgreich entfrent')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      }
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>

<style scoped></style>
