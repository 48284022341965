
import { defineComponent } from 'vue'
import PaymentPackagesDataForm from '@/components/PaymentPackagesDataForm.vue'

export default defineComponent({
  name: 'PaymentPackagesData',
  components: {
    PaymentPackagesDataForm
  }
})
