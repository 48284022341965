
import { defineComponent } from 'vue'
import Modal from './Modal.vue'

export default defineComponent({
  name: 'ConfirmDialogue',
  components: { Modal },
  data() {
    const title = null
    const message = null
    const okButton = null
    const cancelButton = 'Cancel'
    const resolvePromise = null
    const rejectPromise = null
    return {
      cancelButton,
      title,
      message,
      okButton,
      resolvePromise,
      rejectPromise
    }
  },
  methods: {
    async show(opts: Record<string, string>) {
      this.title = opts.title
      this.message = opts.message
      this.okButton = opts.okButton
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    _confirm() {
      this.$refs.popup.close()
      this.resolvePromise(true)
    },

    _cancel() {
      this.$refs.popup.close()
      this.resolvePromise(false)
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    }
  }
})
