
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import { seriesService } from '@/services/Series.service'
import { imagesSetsService } from '@/services/ImagesSets.service'
import { imageSetSchema } from '@/schemas/imageSetSchema'
import { ImageSet } from '@/interfaces/ImageSet'
import LoadingSpinner from './lib/LoadingSpinner.vue'
import Alerts from '../components/lib/Alerts.vue'
import dateTimeFormatterForDateInput from '@/filters/dateTimeFormatterForDateInput'

export default defineComponent({
  name: 'ImagesSetDataForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    GoBackButton,
    SaveButton,
    LoadingSpinner,
    Alerts
  },
  data() {
    const seriesDataArray = []
    const image_set_id: number = null
    return {
      image_set_id,
      seriesDataArray,
      imageSetFormData: {} as ImageSet,
      initialFormValues: {} as ImageSet,
      imageSetSchema,
      alertType: null
    }
  },

  created() {
    this.image_set_id = this.$route.params.image_set_id
    this.getSeriesDataForSelect().then(() => {
      if (this.image_set_id) {
        this.$refs.loadingSpinner.open()
        this.getImageSetData(this.image_set_id)
      }
    })
  },
  methods: {
    async getSeriesDataForSelect() {
      await seriesService
        .getSeriesData()
        .then(response => {
          this.seriesDataArray = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async saveImageSetData(data) {
      console.log('Save Clicked')
      if (!this.$route.params.image_set_id) {
        this.$refs.loadingSpinner.open()
        await imagesSetsService
          .createImageSet(data)
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Bilder Set erfolgreich erstellt!')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      } else {
        this.$refs.loadingSpinner.open()
        await imagesSetsService
          .updateImageSet(this.image_set_id, data)
          .then(response => {
            this.imageSetFormData = ref<ImageSet>({
              set_name: response.data.set_name,
              description: response.data.description,
              series_id: response.data.series_id,
              publish_on: dateTimeFormatterForDateInput(
                response.data.publish_on
              )
            })
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Bilder Set  erfolgreich updated!')
            return this.imageSetFormData
          })
          .catch(error => {
            this.showErrorAlert(error)
            console.log(error)
          })
      }
    },
    async getImageSetData(image_set_id: number) {
      await imagesSetsService
        .getImageSetData(image_set_id)
        .then(response => {
          this.initialFormValues = ref<ImageSet>({
            set_name: response.data.data.set_name,
            description: response.data.data.description,
            series_id: response.data.data.series.id,
            publish_on: dateTimeFormatterForDateInput(
              response.data.data.publish_on
            )
          })
          this.$refs.loadingSpinner.close()
          return this.imageSetFormData
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
