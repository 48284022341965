
import { defineComponent, PropType } from 'vue'
import EnvelopeOpenIcon from '@/components/icons/EnvelopeOpenIcon.vue'
import ArrowDownIcon from '@/components/icons/ArrowDownIcon.vue'
import UsersIcon from '@/components/icons/UsersIcon.vue'
import ArrowUpIcon from '@/components/icons/ArrowUpIcon.vue'
import CursorArrowRaysIcon from '@/components/icons/CursorArrowRaysIcon.vue'
import DashboardData from '@/interfaces/DashboardData'
import MoneyIcon from '@/components/icons/MoneyIcon.vue'

export default defineComponent({
  name: 'DashboardRevenueDataComponent',
  components: {
    MoneyIcon,
    UsersIcon,
    EnvelopeOpenIcon,
    CursorArrowRaysIcon,
    ArrowUpIcon,
    ArrowDownIcon
  },
  props: {
    dashboardData: {
      type: Object as PropType<DashboardData>
    }
  }
})
