import * as yup from 'yup'

export const imageSettingsSchema = yup.object({
  thumbnail_mobile_max_width: yup
    .number()
    .required()
    .min(2),
  thumbnail_mobile_max_height: yup
    .number()
    .required()
    .min(2),
  thumbnail_desktop_max_width: yup
    .number()
    .required()
    .min(2),
  thumbnail_desktop_max_height: yup
    .number()
    .required()
    .min(2),

  image_mobile_max_width: yup
    .number()
    .required()
    .min(2),
  image_mobile_max_height: yup
    .number()
    .required()
    .min(2),
  image_desktop_max_width: yup
    .number()
    .required()
    .min(2),
  image_desktop_max_height: yup
    .number()
    .required()
    .min(2)
})
