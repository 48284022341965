
import { defineComponent, watchEffect } from 'vue'
import { newPasswordService } from '@/services/NewPasswordService'

import LoadingSpinner from '../components/lib/LoadingSpinner.vue'
import GoBackButton from '../components/GoBackButton.vue'

export default defineComponent({
  name: 'forgotPassword',
  components: {
    LoadingSpinner,
    GoBackButton
  },
  data() {
    return {
      form: {
        email: ''
      },
      status: null
    }
  },
  methods: {
    async forgotPassword() {
      this.$refs.loadingSpinner.open()
      await newPasswordService
        .getResetPasswordEmail(this.form)
        .then(response => {
          this.status = response.data.status
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
})
