<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">
      Video Set Daten
    </h2>
    <Form
      @submit="saveVideoSetData"
      :validation-schema="videoSetSchema"
      :initial-values="initialFormValues"
    >
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="series_id"
          >Serie</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="select"
          id="series_id"
          name="series_id"
          v-model="videosSetFormData.series"
        >
          <option value="">Serie (Theme) </option>
          <option
            v-for="(series_values, index) in seriesDataArray"
            :key="index"
            :value="series_values.id"
            >{{ series_values.title }}
          </option>
        </Field>
        <ErrorMessage name="title" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="set_name"
          >Set Name</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="set_name"
          name="set_name"
          v-model="videosSetFormData.set_name"
        />
        <ErrorMessage name="keywords" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="description"
          >Set Beschreibung</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="textarea"
          id="description"
          name="description"
          v-model="videosSetFormData.description"
        />
        <ErrorMessage name="description" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="publish_on"
          >Veröffentlichen am</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="datetime-local"
          id="publish_on"
          name="publish_on"
          v-model="videosSetFormData.publish_on"
        />
        <ErrorMessage name="publish_on" />
      </div>

      <div class="flex justify-between mt-6">
        <GoBackButton />
        <SaveButton />
      </div>
    </Form>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import { seriesService } from '@/services/Series.service'
import { videosSetsService } from '@/services/VideosSets.service'
import { videoSetSchema } from '@/schemas/videoSetSchema'
import { VideoSets } from '@/interfaces/VideoSets'

import LoadingSpinner from './lib/LoadingSpinner.vue'
import Alerts from '../components/lib/Alerts.vue'
import dateTimeFormatterForDateInput from '@/filters/dateTimeFormatterForDateInput'

export default defineComponent({
  name: 'VideosSetsDataForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    GoBackButton,
    SaveButton,
    LoadingSpinner,
    Alerts
  },
  data() {
    const seriesDataArray = []
    const video_set_id: number = null
    return {
      video_set_id,
      seriesDataArray,
      videosSetFormData: {} as VideoSets,
      initialFormValues: {} as VideoSets,
      videoSetSchema,
      alertType: null
    }
  },
  created() {
    this.video_set_id = this.$route.params.video_set_id
    this.getSeriesDataForSelect().then(() => {
      if (this.video_set_id) {
        this.$refs.loadingSpinner.open()
        this.getVideoSetData(this.video_set_id)
      }
    })
  },
  methods: {
    async getSeriesDataForSelect() {
      await seriesService
        .getSeriesData()
        .then(response => {
          this.seriesDataArray = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async saveVideoSetData(data) {
      if (!this.$route.params.video_set_id) {
        this.$refs.loadingSpinner.open()
        await videosSetsService
          .createVideoSet(data)
          .then(response => {
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Videos Set erfolgreich erstellt!')
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      } else {
        this.$refs.loadingSpinner.open()
        await videosSetsService
          .updateVideoSet(this.video_set_id, data)
          .then(response => {
            this.videosSetFormData = ref<VideoSets>({
              set_name: response.data.set_name,
              description: response.data.description,
              series_id: response.data.series_id,
              publish_on: dateTimeFormatterForDateInput(
                response.data.publish_on
              )
            })
            this.$refs.loadingSpinner.close()
            this.showSuccessAlert('Video Set  erfolgreich updated!')
            return this.videosSetFormData
          })
          .catch(error => {
            this.showErrorAlert(error)
            console.log(error)
          })
      }
    },
    async getVideoSetData(video_set_id: number) {
      await videosSetsService
        .getVideoSetData(video_set_id)
        .then(response => {
          this.initialFormValues = ref<VideoSets>({
            set_name: response.data.data.set_name,
            description: response.data.data.description,
            series_id: response.data.data.series.id,
            publish_on: dateTimeFormatterForDateInput(
              response.data.data.publish_on
            )
          })
          this.$refs.loadingSpinner.close()
          return this.videoSetFormData
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>

<style scoped></style>
