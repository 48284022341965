
import { defineComponent, PropType, ref } from 'vue'
import * as Filepond from 'filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import axios from 'axios'

export default defineComponent({
  name: 'VideosSetsContentUploader',
  props: {
    video_set_id: {
      type: String as PropType<string>,
      required: true
    }
  },
  mounted() {
    Filepond.registerPlugin(FilePondPluginImagePreview)
    const pond = Filepond.create(this.$refs.file, {
      allowRevert: false,
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          const form = new FormData()
          const cancelTokenSource = axios.CancelToken.source()
          axios
            .post(`api/videos_sets_content/upload_video/${this.video_set_id}`, {
              filename: file.name
            })
            .then(response => {
              console.log(response.data.additionalData)
              const additionalData = response.data.additionalData
              for (const field in additionalData) {
                form.append(field, additionalData[field])
              }
              form.append('file', file)
              axios
                .post(response.data.attributes.action, form, {
                  onUploadProgress(e) {
                    progress(e.lengthComputable, e.loaded, e.total)
                  },
                  cancelToken: cancelTokenSource.token
                })
                .then(() => {
                  load(`${additionalData.key}`)
                })
            })
          return {
            abort: () => {
              cancelTokenSource.cancel()
              abort()
            }
          }
        }
      },
      onaddfile: (error, file) => {
        if (error) {
          console.log(error)
          return
        }
        //console.log(file)
      },
      onprocessfile: (error, file) => {
        if (error) {
          console.log(error)
          return
        }
        console.log(file)
        pond.removeFile(file.filename)
        axios
          .post(`api/videos_sets_content/store_video/${this.video_set_id}`, {
            filename: file.filename
          })
          .then(response => {
            console.log(response.data)
          })
      }
    })
  }
})
