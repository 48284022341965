<template>
  <ModelContentDataForm/>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import ModelContentDataForm from '../components/ModelContentDataForm.vue'

export default defineComponent({
  name: 'ModelContentData',
  components: {
    ModelContentDataForm
  }
})
</script>

<style scoped></style>
