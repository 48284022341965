<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">
      Video Sets Beschreibung
    </h2>
    <Form
      @submit="saveVideoSetDescription"
      :validation-schema="videoSetDescriptionSchema"
      :initial-values="initialFormValues"
    >
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="title"
          >Tietel</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="title"
          name="title"
          v-model="videoSetDescriptionFormData.title"
        />
        <ErrorMessage name="title" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="description"
          >Beschreibung</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="description"
          name="description"
          v-model="videoSetDescriptionFormData.description"
        />
        <ErrorMessage name="description" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="keywords"
          >Keywords</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="keywords"
          name="keywords"
          v-model="videoSetDescriptionFormData.keywords"
        />
        <ErrorMessage name="keywords" class="text-red-600" />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="language"
          >Sprache</label
        >
        <Field
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          as="select"
          id="language"
          name="language"
          v-model="videoSetDescriptionFormData.language"
        >
          <option value="">Wähle die Sprache</option>
          <option
            v-for="(language, index) in languageSelect"
            :key="index"
            :value="language.code"
            >{{ language.name }}
          </option>
        </Field>
        <ErrorMessage name="language" class="text-red-600" />
      </div>
      <div class="flex justify-between mt-6">
        <GoBackButton />
        <SaveButton />
      </div>
    </Form>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import { languageSelect } from '@/use/languageSelectData'
import { videoSetDescriptionSchema } from '@/schemas/VideoSetDescriptionSchema'
import { VideoSetDescription } from '@/interfaces/VideoSetDescription'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import Alerts from '@/components/lib/Alerts.vue'
import { videosSetsService } from '@/services/VideosSets.service'

export default defineComponent({
  name: 'VideoSetDescriptionForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    LoadingSpinner,
    GoBackButton,
    SaveButton,
    Alerts
  },
  created() {
    this.video_set_id = this.$route.params.video_set_id
    this.video_set_description_id = this.$route.params.video_set_description_id
    if (this.video_set_description_id) {
      this.getVideoSetDescriptionData(this.video_set_description_id)
    }
  },
  data() {
    const video_set_description_id: number = null
    const video_set_id: number = null
    return {
      video_set_description_id,
      video_set_id,
      languageSelect,
      videoSetDescriptionSchema,
      alertType: null,
      videoSetDescriptionFormData: {} as VideoSetDescription,
      initialFormValues: {} as VideoSetDescription
    }
  },
  methods: {
    async saveVideoSetDescription(data) {
      if (!this.video_set_description_id) {
        await videosSetsService
          .createVideoSetDescription(this.video_set_id, data)
          .then(response => {
            this.showSuccessAlert(
              'Video Set Beschreibung erfolgreich gespeichert!'
            )
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      } else {
        await videosSetsService
          .updateVideoSetDescription(this.video_set_description_id, data)
          .then(response => {
            this.showSuccessAlert(
              'Video Set Beschreibung erfolgreich geändert!'
            )
            return response.data
          })
          .catch(error => {
            this.$refs.loadingSpinner.close()
            this.showErrorAlert(error.message)
          })
      }
    },
    async getVideoSetDescriptionData(video_set_description_id: number) {
      await videosSetsService
        .getVideoSetsDescription(video_set_description_id)
        .then(response => {
          this.initialFormValues = ref<VideoSetDescription>({
            title: response.data.data.title,
            description: response.data.data.description,
            keywords: response.data.data.keywords,
            language: response.data.data.language
          })
        })
        .catch(error => {
          this.showErrorAlert(error)
          console.log(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>

<style scoped></style>
