<template>
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="bg-gray-50">
      <tr>
        <th
          v-for="(column, index) in columns"
          :key="index"
          scope="col"
          class=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:bg-indigo-200"
          :class="{
            columnActive: sortBy === column.key && column.sortable
          }"
          @click="sort(column.key)"
        >
          <div class="inline-flex flex-wrap w-full">
            <div class="inline-block text-right mr-3 mt-1 left-0">
              {{ column.label }}
            </div>
            <div v-if="column.sortable" class="text-right right-0">
              <svg
                class="w-3 h-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                v-show="sortBy === column.key && sortDirection === 'ASC'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                ></path>
              </svg>
              <svg
                class="w-3 h-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                v-show="sortBy === column.key && sortDirection === 'DESC'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
          </div>
        </th>

        <th scope="col" class="relative px-6 py-3">
          <span class="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <tr
        v-for="(row, index) in tableData"
        :key="index"
        class="hover:bg-indigo-200"
      >
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            <div class="flex-shrink-0 h-10 w-10">
              <img
                class="h-10 w-10 rounded-full"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
            </div>
            <div class="ml-4">
              <div class="text-sm font-medium text-gray-900">
                {{ row.name }}
              </div>

              <div class="text-xs font-normal text-gray-500">
                {{ row.email }}
              </div>
            </div>
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex text-sm items-center">
            {{ row.previous_id }}
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex text-sm items-center">
            {{ this.dateTimeFormatter(row.created_at) }}
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex text-sm items-center">
            {{ this.dateTimeFormatter(row.email_verified_at) }}
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex text-sm items-center">
            {{ this.dateTimeFormatter(row.access_to) }}
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex text-sm items-center">
            {{ row.period }}
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <span
            v-if="row.status && row.status==='active'"
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          >
            {{ row.status }}
          </span>
          <span
            v-else
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-500 text-yellow-900"
          >
            Inactive
          </span>
        </td>
        <td
          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
          v-if="row.role"
        >
          {{ row.role }}
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500" v-else>
          no pay user
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <router-link
            :to="{ name: 'editUser', params: { userId: row.id } }"
            class="text-indigo-600 hover:text-indigo-900 mr-3"
            >Bearbeiten
          </router-link>
          <router-link
            :to="{ name: 'editUserPassword', params: { userId: row.id } }"
            class="text-indigo-600 hover:text-indigo-900 mr-3"
            >Password ändern
          </router-link>
          <a
            href="#"
            class="text-red-600 hover:text-indigo-900"
            @click.prevent="this.$parent.deleteUser(row.id)"
            >Löschen</a
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import dateTimeFormatter from '../filters/dateTimeFormatter'

export default defineComponent({
  name: 'UserTable',
  props: {
    tableData: Object,
    columns: Array
  },
  data() {
    const sortBy = 'created_at'
    const sortDirection = 'asc'
    return {
      sortBy: sortBy,
      sortDirection: sortDirection
    }
  },
  methods: {
    dateTimeFormatter,
    sort(column) {
      if (column === this.sortBy) {
        if (this.sortDirection == 'ASC') {
          this.sortDirection = 'DESC'
        } else {
          this.sortDirection = 'ASC'
        }
      }
      if (column != this.sortBy) {
        this.sortDirection = 'ASC'
        this.sortBy = column
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          sortDirection: this.sortDirection,
          sortBy: this.sortBy,
          page: 1
        }
      })
      this.$parent.sortBy = this.sortBy
      this.$parent.sortDirection = this.sortDirection
    }
  }
})
</script>

<style>
.columnActive {
  @apply bg-indigo-400;
  @apply text-white !important;
}
</style>
