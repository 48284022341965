import axios from 'axios'
import { User } from '@/interfaces/User'

class UsersService {
  async getUsersData(
    perPage: number = null,
    page: number = null,
    search: string = null,
    sortBy: string = null,
    sortDirection: string = null,
    status = 'active'
  ) {
    if (search) {
      return await axios.get(
        `api/users?per_page=${perPage}&page=${page}&search=${search}&status=${status}`
      )
    }
    if (sortBy && search) {
      return await axios.get(
        `api/users?per_page=${perPage}&page=${page}&search=${search}&sortBy=${sortBy}&sortDirection=${sortDirection}&status=${status}`
      )
    }
    if (sortBy && !search) {
      return await axios.get(
        `api/users?per_page=${perPage}&page=${page}&sortBy=${sortBy}&sortDirection=${sortDirection}&status=${status}`
      )
    }

    return await axios.get(
      `api/users?per_page=${perPage}&page=${page}&status=${status}`
    )
  }

  async deleteUser(id: string) {
    return await axios.delete(`api/users/${id}`)
  }

  async getUserData(id: string) {
    return await axios.get(`api/users/${id}`)
  }

  async updateUserData(id: string, userData: Array<User>) {
    return await axios.put(`api/users/${id}`, userData)
  }

  async createUser(userData: Array<User>) {
    return await axios.post('api/users', userData)
  }

  async updateUserPassword(id: string, userData: Array<User>) {
    return await axios.put(`api/users/password_update/${id}`, userData)
  }
}

export const usersService = new UsersService()
