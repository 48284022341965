<template>
  <section>
    <div
      class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
    >
      <div class="flex-1 flex justify-between sm:hidden">
        <a
          href="#"
          class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
        >
          Previous
        </a>
        <a
          href="#"
          class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
        >
          Next
        </a>
      </div>
      <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p class="text-sm text-gray-700">
            Showing
            <span class="font-medium">{{ pagination.from }}</span>
            to
            <span class="font-medium">{{ pagination.to }}</span>
            of
            <span class="font-medium">{{ pagination.total }}</span>
            results
          </p>
        </div>
        <div>
          <nav
            class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <router-link
              :to="{
                name: routeName,
                query: {
                  page: previewPage(),
                  perPage: pagination.per_page,
                  search: searchString,
                  sortBy: sortBy,
                  sortDirection: sortDirection,
                  series_id: seriesId
                }
              }"
              :class="{ 'disabled-arrow': !hasPreview() }"
              rel="prev"
              class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-indigo-200"
            >
              <span class="sr-only">Previous</span>
              <!-- Heroicon name: solid/chevron-left -->
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </router-link>
            <router-link
              v-if="hasFirst()"
              :to="{
                name: routeName,
                query: {
                  page: 1,
                  perPage: pagination.per_page,
                  search: searchString,
                  sortBy: sortBy,
                  sortDirection: sortDirection,
                  series_id: seriesId
                }
              }"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700  hover:bg-indigo-200"
            >
              1
            </router-link>
            <a
              v-if="hasFirst()"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700  hover:bg-indigo-200 disabled-arrow"
            >
              ...
            </a>

            <router-link
              v-for="(link, index) in pages()"
              :key="index"
              :to="{
                name: routeName,
                query: {
                  page: link,
                  perPage: pagination.per_page,
                  search: searchString,
                  sortBy: sortBy,
                  sortDirection: sortDirection,
                  series_id: seriesId
                }
              }"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700  hover:bg-indigo-200"
              :class="{ disabled: link === parseInt(pagination.current_page) }"
            >
              {{ link }}
            </router-link>
            <a
              v-if="hasLast()"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700  hover:bg-indigo-200 disabled-arrow"
            >
              ...
            </a>
            <router-link
              v-if="hasLast()"
              :to="{
                name: routeName,
                query: {
                  page: pagination.last_page,
                  perPage: pagination.per_page,
                  search: searchString,
                  sortBy: sortBy,
                  sortDirection: sortDirection,
                  series_id: seriesId
                }
              }"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700  hover:bg-indigo-200"
            >
              {{ pagination.last_page }}
            </router-link>

            <router-link
              :to="{
                name: routeName,
                query: {
                  page: parseInt(pagination.current_page) + 1,
                  perPage: pagination.per_page,
                  search: searchString,
                  sortBy: sortBy,
                  sortDirection: sortDirection,
                  series_id: seriesId
                }
              }"
              :class="{
                'disabled-arrow':
                  pagination.current_page === pagination.last_page
              }"
              rel="prev"
              class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500  hover:bg-indigo-200"
            >
              <span class="sr-only">Next</span>
              <!-- Heroicon name: solid/chevron-right -->
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </router-link>
          </nav>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Pagination',
  props: {
    routeName: null,
    pagination: null,
    searchString: null,
    sortBy: null,
    sortDirection: null,
    pageRange: {
      type: Number,
      default: 2
    },
    seriesId: null
  },

  methods: {
    hasPreview() {
      return this.pagination.current_page > 1
    },
    previewPage() {
      return this.pagination.current_page - 1
    },
    hasFirst() {
      return this.rangeStart() !== 1
    },
    hasLast() {
      return this.rangeEnd() < this.pagination.last_page
    },
    rangeStart() {
      const start = this.pagination.current_page - this.pageRange
      return start > 0 ? start : 1
    },
    rangeEnd() {
      const end = this.pagination.current_page + this.pageRange
      return end < this.pagination.last_page ? end : this.pagination.last_page
    },
    pages() {
      const pages = []
      for (let i = this.rangeStart(); i <= this.rangeEnd(); i++) {
        pages.push(i)
      }
      return pages
    }
  }
})
</script>

<style scoped>
.disabled {
  @apply bg-indigo-400;
  pointer-events: none;
}

.disabled-arrow {
  pointer-events: none;
  opacity: 0.6;
}
</style>
