
import { defineComponent, ref } from 'vue'

import { languageSelect } from '@/use/languageSelectData'
import { seriesService } from '@/services/Series.service'
import { contentType } from '@/use/contentType'
import { contentResolution } from '@/use/contentResolution'
import { Series } from '@/interfaces/Series'
import GoBackButton from './GoBackButton.vue'
import LoadingSpinner from './lib/LoadingSpinner.vue'
import Alerts from '../components/lib/Alerts.vue'

export default defineComponent({
  name: 'SeriesDataForm',
  components: {
    LoadingSpinner,
    GoBackButton,
    Alerts
  },
  data() {
    const series_id: number = null
    return {
      series_id,
      seriesFormData: {} as Series,
      languageSelect,
      contentType,
      contentResolution
    }
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    this.series_id = this.$route.params.series_id
    this.getASeriesData(this.series_id)
  },
  methods: {
    async getASeriesData(series_id: number) {
      console.log(series_id)
      if (series_id) {
        await seriesService
          .getASeriesData(series_id)
          .then(response => {
            this.seriesFormData = ref<Series>({
              title: response.data.title,
              slug: response.data.slug,
              description: response.data.description,
              content_resolution: response.data.content_resolution,
              sort_order: response.data.sort_order,
              content_origin: response.data.content_origin,
              type: response.data.type
            })
            this.$refs.loadingSpinner.close()
            return this.seriesFormData
          })
          .catch(error => {
            this.showErrorAlert(error)
            console.log(error)
          })
      }
    },
    async updateSeriesData() {
      this.$refs.loadingSpinner.open()
      await seriesService
        .updateSeriesData(this.series_id, this.seriesFormData)
        .then(response => {
          this.seriesFormData = ref<Series>({
            title: response.data.title,
            slug: response.data.slug,
            description: response.data.description,
            content_resolution: response.data.content_resolution,
            sort_order: response.data.sort_order,
            content_origin: response.data.content_origin,
            type: response.data.type
          })
          this.$refs.loadingSpinner.close()
          this.showSuccessAlert('Serie wurde upgedatet')
          return this.seriesFormData
        })
        .catch(error => {
          console.log(error)
          this.showErrorAlert(error)
        })
    },
    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
