<template>
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="bg-gray-50">
      <tr>
        <th
          v-for="(column, index) in columns"
          :key="index"
          scope="col"
          class=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:bg-indigo-200"
        >
          <div class="inline-flex flex-wrap w-full">
            <div class="inline-block text-right mr-3 mt-1 left-0">
              {{ column.label }}
            </div>
          </div>
        </th>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:bg-indigo-200"
        >
          <div class="inline-block text-right mr-3 mt-1 left-0">
            Edit
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <tr
        v-for="(row, index) in tableData"
        :key="index"
        class="hover:bg-indigo-200"
      >
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center truncate">
            {{ row.title }}
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            {{ row.description }}
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            {{ row.keywords }}
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            {{ row.language }}
          </div>
        </td>
        <td class="flex-col px-6 py-4 text-right text-sm font-medium">
          <div>
            <router-link
              :to="{ name: 'editSiteDescriptionData', params: { site_description_id: row.id } }"
              class="text-indigo-600 hover:text-indigo-900 mr-3"
              >Bearbeiten
            </router-link>
          </div>
          <div>
            <a
              href="#"
              class="text-red-600 hover:text-indigo-900 mr-3"
              @click.prevent="this.$parent.deleteSiteDescription(row.id)"
              >Löschen</a
            >
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import truncateTableString from '@/filters/truncateTableString'

export default defineComponent({
  name: 'SiteDescriptionTable',
  props: {
    tableData: {
      type: Object
    },
    columns: Array as PropType<Array<string>>
  },
  methods: {
    truncateTableString
  }
})
</script>

<style scoped></style>
