<template>
  <button type="submit" class="flex items-center gap-4 my-6 cursor-pointer ">
    <div
      class="bg-gray-800 px-5 py-3 text-white rounded-lg text-center  hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
    >
      Speichern!
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SaveButton'
})
</script>
