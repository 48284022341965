
import { defineComponent, ref } from 'vue'
import AppSeriesPreviewUploader from '../components/AppSeriesPreviewUploader.vue'
import GoBackButton from '@/components/GoBackButton.vue'

export default defineComponent({
  name: 'SeriesPreviewUpload',
  components: { GoBackButton, AppSeriesPreviewUploader },
  data() {
    return {
      series_id: this.$route.params.series_id
    }
  }
})
