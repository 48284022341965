<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">User Data</h2>

    <form @submit.prevent="updateUserData">
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="previous_id"
          >Vorherige ID</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="number"
          id="previous_id"
          v-model="user.previous_id"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="name"
          >Name</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="name"
          v-model="user.name"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="street"
          >Strasse</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="street"
          v-model="user.street"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="postcode"
          >Postleitzahl</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="postcode"
          v-model="user.postcode"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="city"
          >Stadt</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="city"
          v-model="user.city"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="country_id"
          >Land</label
        >
        <select
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          id="country_id"
          v-model="user.country_id"
        >
          <option>Land</option>
          <option
            v-for="(country, index) in countryRegions"
            :key="index"
            :value="country.countryShortCode"
          >
            {{ country.countryName }}
          </option>
        </select>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="language"
          >Sprache</label
        >
        <select
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          id="language"
          v-model="user.language"
        >
          <option value="">Language</option>
          <option
            v-for="(language, index) in languageSelect"
            :key="index"
            :value="language.code"
            >{{ language.name }}
          </option>
        </select>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="email"
          >E-Mail</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="email"
          id="email"
          v-model="user.email"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="created_at"
          >Erstellt</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="created_at"
          disabled
          v-model="user.created_at"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="access_to"
          >Email bestätigt</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="datetime-local"
          id="access_to"
          v-model="user.email_verified_at"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="updated_at"
          >Geändert</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="updated_at"
          disabled
          v-model="user.updated_at"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="access_to"
          >Vollzugang bis</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="datetime-local"
          id="access_to"
          v-model="user.access_to"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="status"
          >Status</label
        >
        <select
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          id="status"
          v-model="user.status"
        >
          <option value="">User Status</option>
          <option
            v-for="(status, index) in userStatus"
            :key="index"
            :value="status.value"
            >{{ status.name }}
          </option>
        </select>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="role"
          >Role</label
        >
        <select
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          id="role"
          v-model="user.role"
        >
          <option value="">User role</option>
          <option
            v-for="(role, index) in userRoles"
            :key="index"
            :value="role.value"
            >{{ role.name }}
          </option>
        </select>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="period"
          >Period</label
        >
        <select
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          id="period"
          v-model="user.period"
        >
          <option value="">Membership period</option>
          <option
            v-for="(membership, index) in memberShipPeriod"
            :key="index"
            :value="membership.value"
            >{{ membership.name }}
          </option>
        </select>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="ipaddress"
          >IP Adresse</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          disabled
          type="text"
          id="ipaddress"
          v-model="user.ipaddress"
        />
      </div>
      <div class="flex justify-between mt-6">
        <GoBackButton />
        <SaveButton />
      </div>
    </form>
    <div class="mt-6" v-if="userTransactions.length > 0">
      <h2 class="text-lg text-gray-700 font-semibold capitalize">
        User Transactionen
      </h2>
      <UserTransactionsTable
        :columns="columns"
        :transactionData="userTransactions"
      />
    </div>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { usersService } from '@/services/Users.service'
import GoBackButton from './GoBackButton.vue'
import SaveButton from './SaveButton.vue'
import LoadingSpinner from './lib/LoadingSpinner.vue'
import UserTransactionsTable from './UserTransactionsTable.vue'
import Alerts from '../components/lib/Alerts.vue'
import dateTimeFormatter from '@/filters/dateTimeFormatter'
import dateTimeFormatterForDateInput from '@/filters/dateTimeFormatterForDateInput'
import { User } from '@/interfaces/User'
import { countryRegions } from '@/use/countryRegionSelectData'
import { languageSelect } from '@/use/languageSelectData'
import { userStatus } from '@/use/userStatus'
import { userRoles } from '@/use/userRoles'
import { memberShipPeriod } from '@/use/memberShipPeriod'

export default defineComponent({
  name: 'EditUser',
  components: {
    UserTransactionsTable,
    LoadingSpinner,
    SaveButton,
    GoBackButton,
    Alerts
  },
  data() {
    const userId: string = null
    const userTransactions = []
    return {
      userId,
      user: {} as User,
      userTransactions,
      countryRegions,
      languageSelect,
      userStatus,
      userRoles,
      memberShipPeriod,
      columns: [
        {
          key: 'transaction_id',
          label: 'Transaction Id',
          sortable: false
        },
        {
          key: 'wm_id',
          label: 'WM ID',
          sortable: false
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true
        },
        {
          key: 'currency',
          label: 'Currency',
          sortable: false
        },
        {
          key: 'currency_user',
          label: 'Currency User',
          sortable: true
        },
        {
          key: 'payment_type',
          label: 'Payment Type',
          sortable: false
        },
        {
          key: 'transaction_date',
          label: 'Transaction date',
          sortable: true
        },
        {
          key: 'action',
          label: 'Action',
          sortable: true
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'trans_amount',
          label: 'Transaction amount',
          sortable: false
        },
        {
          key: 'trans_amount_usd',
          label: 'Transaction amount USD',
          sortable: false
        },
        {
          key: 'payment_subtype',
          label: 'Payment Subtype',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created at',
          sortable: true
        }
      ]
    }
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    this.userId = this.$route.params.userId
    this.getUserData(this.userId)
  },
  methods: {
    async getUserData(userId: string) {
      if (userId) {
        await usersService
          .getUserData(userId)
          .then(response => {
            this.user = ref<User>({
              previous_id: response.data.previous_id,
              name: response.data.name,
              street: response.data.street,
              postcode: response.data.postcode,
              city: response.data.city,
              country_id: response.data.country_id,
              language: response.data.language,
              email: response.data.email,
              created_at: dateTimeFormatter(response.data.created_at),
              email_verified_at: dateTimeFormatter(
                response.data.email_verified_at
              ),
              updated_at: dateTimeFormatter(response.data.updated_at),
              access_to: dateTimeFormatterForDateInput(response.data.access_to),
              status: response.data.status,
              role: response.data.role,
              period: response.data.period,
              ipaddress: response.data.ipaddress
            })
            this.userTransactions = response.data.transactions
            this.$refs.loadingSpinner.close()
            return this.user
          })
          .catch(error => {
            this.showErrorAlert(error)
            console.log(error)
          })
      }
    },

    async updateUserData() {
      this.$refs.loadingSpinner.open()
      await usersService
        .updateUserData(this.userId, this.user)
        .then(response => {
          this.user = ref<User>({
            previous_id: response.data.previous_id,
            name: response.data.name,
            street: response.data.street,
            postcode: response.data.postcode,
            city: response.data.city,
            country_id: response.data.country_id,
            language: response.data.language,
            email: response.data.email,
            created_at: dateTimeFormatter(response.data.created_at),
            email_verified_at: dateTimeFormatter(
              response.data.email_verified_at
            ),
            updated_at: dateTimeFormatter(response.data.updated_at),
            access_to: dateTimeFormatterForDateInput(response.data.access_to),
            status: response.data.status,
            role: response.data.role,
            period: response.data.period,
            ipaddress: response.data.ipaddress
          })
          this.$refs.loadingSpinner.close()
          this.showSuccessAlert('User successfully updated!')
          return this.user
        })
        .catch(error => {
          console.log(error)
          this.showErrorAlert(error)
        })
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>

<style scoped></style>
