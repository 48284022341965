
import { defineComponent, ref } from 'vue'

import { newPasswordService } from '@/services/NewPasswordService'
import { NewPassword } from '@/interfaces/NewPassword'

import LoadingSpinner from '../components/lib/LoadingSpinner.vue'
import GoBackButton from '../components/GoBackButton.vue'

export default defineComponent({
  name: 'ResetPassword',
  components: {
    LoadingSpinner
  },
  data() {
    return {
      form: {} as NewPassword,
      status: null,
      error: null,
      error_message: null
    }
  },
  created() {
    this.form.token = this.$route.params.token
  },
  methods: {
    async resetPassword() {
      this.$refs.loadingSpinner.open()
      this.error = null
      this.error_message = null
      await newPasswordService
        .resetPasswordData(this.form)
        .then(response => {
          console.log(response.data)
          this.status = response.data.status
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log((this.error = error.response.data))
          this.error = error.response.data.message
          this.error_message = ''
          for (const key in error.response.data.errors.password) {
            this.error_message =
              this.error_message +
              error.response.data.errors.password[key] +
              ' '
          }

          this.$refs.loadingSpinner.close()
        })
    }
  }
})
