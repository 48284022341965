<template>
  <div class="flex justify-center mt-6 mb-6">
    <GoBackButton />
  </div>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full">
        <div class="overflow-hidden sm:rounded-lg">
          <ImageSetsUploader :image_set_id="image_set_id" />
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-center mt-6">
    <GoBackButton />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import ImageSetsUploader from '../components/ImageSetsUploader.vue'
import GoBackButton from '@/components/GoBackButton.vue'

export default defineComponent({
  name: 'ImageSetsContentUpload',
  components: { GoBackButton, ImageSetsUploader },
  data() {
    return {
      image_set_id: this.$route.params.image_set_id
    }
  }
})
</script>

<style scoped></style>
