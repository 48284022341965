
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Alerts',
  props: {
    alertType: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      message: '',
      isActive: false,
      isVisible: false
    }
  },
  methods: {
    open(message: string) {
      this.message = message
      this.isVisible = true
      this.isActive = true
    },

    close() {
      this.isVisible = false
      this.isActive = false
    }
  }
})
