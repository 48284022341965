<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <DataTableFilters
            ref="tableFilter"
            :perPageSelected="perPageSelected"
            :statusSelected="status"
          />
          <UserTable :tableData="userData" :columns="columns" />
          <Pagination
            :routeName="routeName"
            :pagination="paginationData"
            :searchString="search"
            :sortBy="sortBy"
            :sortDirection="sortDirection"
          />
        </div>
      </div>
    </div>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue'
import { mapGetters } from 'vuex'

import { usersService } from '@/services/Users.service'
import UserTable from '../components/UserTable.vue'
import Pagination from '../components/Pagination.vue'
import DataTableFilters from '../components/DataTableFilters.vue'
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import LoadingSpinner from '../components/lib/LoadingSpinner.vue'
import Alerts from '../components/lib/Alerts.vue'

export default defineComponent({
  name: 'Users',
  components: {
    ConfirmDialogue,
    LoadingSpinner,
    Pagination,
    UserTable,
    DataTableFilters,
    Alerts
  },
  data() {
    const perPage = 10
    const page = 1
    const search = null
    const sortBy = null
    const sortDirection = 'ASC'
    const status = 'active'
    return {
      routeName: 'users',
      userData: null,
      perPage: perPage,
      page: page,
      search: search,
      sortBy: sortBy,
      sortDirection: sortDirection,
      status,
      paginationData: Function,
      perPageSelected: null,
      alertType: null,
      columns: [
        {
          key: 'name',
          label: 'Name',
          sortable: false
        },
        {
          key: 'previous_id',
          label: 'Vorherige ID',
          sortable: false
        },
        {
          key: 'created_at',
          label: 'Erstellt',
          sortable: true
        },
        {
          key: 'email_verified_at',
          label: 'Email bestätigt',
          sortable: true
        },
        {
          key: 'access_to',
          label: 'Vollzugang bis',
          sortable: true
        },
        {
          key: 'period',
          label: 'Laufzeit',
          sortable: true
        },
        {
          key: 'status',
          label: 'status',
          sortable: true
        },
        {
          key: 'role',
          label: 'Role',
          sortable: true
        }
      ]
    }
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    watchEffect(() => {
      this.$nextTick(() => {
        this.$refs.loadingSpinner.open()
      })
      this.getUsersData(this.search)
    })
  },
  methods: {
    ...mapGetters({
      perPageGetter: 'paginationSettings/getPerPage'
    }),
    async getUsersData(search: string = null) {
      this.search = search
      if (search) {
        this.$refs.loadingSpinner.open()
        await this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            search: search
          }
        })
      }

      const perPageSettings = this.perPageGetter() ?? this.perPage
      const page = this.$route.query.page ?? this.page
      const sortBy = this.$route.query.sortBy ?? this.sortBy
      const sortDirection =
        this.$route.query.sortDirection ?? this.sortDirection
      const statusValue = this.$route.query.status ?? this.status
      this.perPageSelected = parseInt(perPageSettings)
      this.userData = null
      await usersService
        .getUsersData(
          parseInt(perPageSettings),
          page,
          search,
          sortBy,
          sortDirection,
          statusValue
        )
        .then(response => {
          this.userData = response.data.data
          this.paginationData = response.data
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
        })
    },
    async deleteUser(id: string) {
      if (id) {
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Delete User',
          message:
            'Are you sure you want to delete this user? It cannot be undone.',
          okButton: 'Delete'
        })
        // If you throw an error, the method will terminate here unless you surround it wil try/catch
        if (ok) {
          await usersService
            .deleteUser(id)
            .then(response => {
              this.$refs.loadingSpinner.close()
              this.showSuccessAlert('User wurde gelöscht!')
              return response.data
            })
            .catch(error => {
              this.$refs.loadingSpinner.close()
              let message: string
              if (error.response.data.message) {
                message = error.response.data.message
              }
              if (error.response.data.error) {
                message = error.response.data.error
              }
              this.showErrorAlert(message)
            })
          await this.getUsersData(this.search)
        }
      }
    },

    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>
