import * as yup from 'yup'

export const staticSiteSchema = yup.object({
  language: yup
    .string()
    .required()
    .min(2),
  title: yup
    .string()
    .required()
    .min(3),
  content: yup
    .string()
    .required()
    .min(50)
})
