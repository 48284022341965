
import { defineComponent } from 'vue'
import AppSeriesLogoUploader from '../components/AppSeriesLogoUploader.vue'
import GoBackButton from '@/components/GoBackButton.vue'

export default defineComponent({
  name: 'SeriesLogoUpload',
  components: { GoBackButton, AppSeriesLogoUploader },
  data() {
    return {
      series_id: this.$route.params.series_id
    }
  }
})
