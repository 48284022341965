<template>
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="bg-indigo-100">
      <tr>
        <th
          v-for="(column, index) in columns"
          :key="index"
          scope="col"
          class=" px-6 py-3 text-left text-xs font-medium text-indigo-700 uppercase tracking-wider"
          :class="[
            column.sortable ? 'cursor-pointer hover:bg-gray-200' : '',
            sortBy === column.key && column.sortable ? 'columnActive' : ''
          ]"
          @click="column.sortable ? sort(column.key) : ''"
        >
          <div class="inline-flex flex-wrap w-full">
            <div class="inline-block text-right mr-3 mt-1 left-0">
              {{ column.label }}
            </div>
            <div v-if="column.sortable" class="text-right right-0">
              <svg
                class="w-3 h-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                v-show="sortBy === column.key && sortDirection === 'ASC'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                ></path>
              </svg>
              <svg
                class="w-3 h-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                v-show="sortBy === column.key && sortDirection === 'DESC'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
          </div>
        </th>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium text-indigo-700 uppercase tracking-wider hover:bg-indigo-200"
        >
          <div class="text-right mr-3 mt-1 left-0">
            Edit
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <tr
        v-for="(row, index) in tableData"
        :key="index"
        class="hover:bg-indigo-200"
      >
        <td class="px-2 py-4 break-all">
          <div class="flex flex-row items-center text-sm">
            {{ row.id }}
          </div>
        </td>
        <td class="px-6 py-4 break-all">
          <div class="flex items-center truncate">
            <div
              v-if="
                row.actors_preview_images &&
                  row.actors_preview_images.length > 0
              "
            >
              <router-link
                :to="{
                  name: 'modelPreviewImages',
                  params: { model_id: row.id }
                }"
                class="text-indigo-600 hover:text-indigo-900 mr-3"
              >
                <img
                  :src="
                    row.actors_preview_images[0].actor_preview_url +
                      '/' +
                      row.slug +
                      '/150x150/' +
                      row.actors_preview_images[0].image_name
                  "
                />
              </router-link>
            </div>
            <div v-else>
              <router-link
                :to="{
                  name: 'uploadModelPreviewImages',
                  params: { model_id: row.id }
                }"
                class="text-indigo-600 hover:text-indigo-900 mr-3"
              >
                <div
                  class="h-8 px-2 inline-flex text-xs  flex-wrap flex items-center justify-center font-semibold rounded-md m-2 bg-indigo-500 text-white"
                >
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                </div>
              </router-link>
            </div>
          </div>
        </td>
        <td class="px-6 py-4">
          <div class="flex items-center">
            {{ row.alias }}
          </div>
        </td>
        <td class="px-6 py-4">
          <div class="flex items-center">
            <div class="flex items-center">
              {{ row.firstname }}
            </div>
          </div>
        </td>
        <td class="px-6 py-4">
          <div class="flex items-center">
            {{ row.lastname }}
          </div>
        </td>

        <td class="px-6 py-4">
          <div class="flex items-center">
            <div
              v-if="
                row.actors_descriptions && row.actors_descriptions.length > 0
              "
            >
              <div
                v-for="(description, index) in row.actors_descriptions"
                :key="index"
                class="h-6 px-2 inline-flex text-xs flex items-center justify-center font-semibold rounded-md m-2 bg-green-100 text-green-800"
              >
                <router-link
                  :to="{
                    name: 'editModelDescription',
                    params: { model_description_id: description.id }
                  }"
                >
                  {{ description.language }}
                </router-link>
              </div>
            </div>
            <div v-else>
              <span
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-900 text-white"
              >
                N.A</span
              >
            </div>
          </div>
        </td>
        <td
          class="flex-col px-6 py-4 text-right text-sm font-medium whitespace-pre"
        >
          <div>
            <router-link
              :to="{ name: 'editModelData', params: { model_id: row.id } }"
              class="text-indigo-600 hover:text-indigo-900 mr-3"
              >Bearbeiten
            </router-link>
          </div>
          <div>
            <a
              href="#"
              class="text-red-600 hover:text-indigo-900 mr-3"
              @click.prevent="this.$parent.deleteModelData(row.id)"
              >Löschen</a
            >
          </div>
          <div>
            <router-link
              :to="{
                name: 'addModelDescription',
                params: { model_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900 mr-3"
              >Beschreibung
            </router-link>
          </div>
          <div>
            <router-link
              :to="{
                name: 'uploadModelPreviewImages',
                params: { model_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900 mr-3"
              >Vorschaubilder hochladen
            </router-link>
          </div>
          <div>
            <router-link
              :to="{
                name: 'modelPreviewImages',
                params: { model_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900 mr-3"
              >Vorschaubilder
            </router-link>
          </div>
          <div>
            <router-link
              :to="{
                name: 'modelContent',
                params: { model_id: row.id }
              }"
              class="text-indigo-600 hover:text-indigo-900 mr-3"
              >Content
            </router-link>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import truncateTableString from '@/filters/truncateTableString'
import dateTimeFormatter from '@/filters/dateTimeFormatter'

export default defineComponent({
  name: 'ModelsDataTable',
  props: {
    tableData: Object,
    columns: Array
  },
  data() {
    const sortBy = 'id'
    const sortDirection = 'DESC'
    return {
      sortBy: sortBy,
      sortDirection: sortDirection
    }
  },
  methods: {
    truncateTableString,
    dateTimeFormatter,
    sort(column) {
      if (column === this.sortBy) {
        if (this.sortDirection == 'ASC') {
          this.sortDirection = 'DESC'
        } else {
          this.sortDirection = 'ASC'
        }
      }
      if (column != this.sortBy) {
        this.sortDirection = 'ASC'
        this.sortBy = column
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          sortDirection: this.sortDirection,
          sortBy: this.sortBy,
          page: 1
        }
      })
      this.$parent.sortBy = this.sortBy
      this.$parent.sortDirection = this.sortDirection
    }
  }
})
</script>

<style scoped></style>
