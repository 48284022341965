
import { defineComponent } from 'vue'
import SiteDescriptionForm from '../components/SiteDescriptionForm.vue'

export default defineComponent({
  name: 'SiteDescriptionData',
  components: {
    SiteDescriptionForm
  }
})
