
import { defineComponent, watchEffect } from 'vue'
import LoadingSpinner from '@/components/lib/LoadingSpinner.vue'
import LightBox from '@/components/LightBox.vue'
import { imagesSetsContentService } from '@/services/ImagesSetsContent.service'
import { imagesSetsService } from '@/services/ImagesSets.service'

import GoBackButton from '@/components/GoBackButton.vue'

import VLazyImage from 'v-lazy-image'

export default defineComponent({
  name: 'ImageSetContent',
  components: {
    LoadingSpinner,
    LightBox,
    GoBackButton,
    VLazyImage
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    watchEffect(() => {
      this.getImageSetContent(parseInt(this.image_set_id))
    })
  },
  data() {
    return {
      imagesSetContent: null,
      image_set_id: this.$route.params.image_set_id,
      slug: null
    }
  },
  methods: {
    async getImageSetContent(image_set_id: number) {
      await imagesSetsContentService
        .getImagesSetsContent(image_set_id)
        .then(response => {
          console.log(response.data)
          this.imagesSetContent = response.data.data
          this.getImageSetDataForSlug(image_set_id)
          this.$refs.loadingSpinner.close()
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getImageSetDataForSlug(image_set_id: number) {
      await imagesSetsService.getImageSetData(image_set_id).then(response => {
        this.slug = response.data.data.slug
      })
    },
    async openLightBox(
      imagePreviewUrl: string,
      slug: string,
      imageName: string
    ) {
      await this.$refs.lightBox.show({ imagePreviewUrl, slug, imageName })
    },
    async isPreview(imagesSetsContent: number, is_preview: boolean) {
      this.$refs.loadingSpinner.open()
      await imagesSetsContentService
        .setIsPreviewImage(imagesSetsContent, is_preview)
        .then(response => {
          console.log(response.data)
          this.getImageSetContent(this.image_set_id)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async moveToTrash(imagesSetsContent: number) {
      this.$refs.loadingSpinner.open()
      await imagesSetsContentService
        .deleteImage(imagesSetsContent)
        .then(response => {
          console.log(response.data)
          this.getImageSetContent(this.image_set_id)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
})
