<template>
  <ImageSettingsForm />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ImageSettingsForm from '@/components/ImageSettingsForm.vue'
export default defineComponent({
  name: 'ImageSettings',
  components: {
    ImageSettingsForm
  }
})
</script>

<style scoped></style>
