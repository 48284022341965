<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>

  <div class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg text-gray-700 font-semibold capitalize">Serie Daten</h2>
    <form @submit.prevent="updateSeriesData">
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="title"
          >Tietel</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="title"
          v-model="seriesFormData.title"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="slug"
          >Slug</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="slug"
          v-model="seriesFormData.slug"
          disabled
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="description"
          >Beschreibung</label
        >
        <textarea
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          id="description"
          v-model="seriesFormData.description"
        />
      </div>
      <div class="mt-2">
        <label
          class="inline-block text-sm mb-2 text-gray-700"
          for="content_resolution"
          >Content resolution</label
        >
        <select
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="content_resolution"
          v-model="seriesFormData.content_resolution"
        >
          <option value="">Content Resolution</option>
          <option
            v-for="(resolution, index) in contentResolution"
            :key="index"
            :value="resolution"
            >{{ resolution }}
          </option>
        </select>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="sort_order"
          >Reienfolge</label
        >
        <input
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          type="text"
          id="sort_order"
          v-model="seriesFormData.sort_order"
        />
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="sort_order"
          >Orginal Sprache</label
        >
        <select
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          id="language"
          name="language"
          v-model="seriesFormData.content_origin"
        >
          <option value="">Orginal Sprache</option>
          <option
            v-for="(language, index) in languageSelect"
            :key="index"
            :value="language.code"
            >{{ language.name }}
          </option>
        </select>
      </div>
      <div class="mt-2">
        <label class="inline-block text-sm mb-2 text-gray-700" for="type"
          >Type</label
        >
        <select
          class="w-full border-2 border-gray-200 h-10 px-3 rounded-md focus:border-indigo-600"
          id="type"
          name="type"
          v-model="seriesFormData.type"
        >
          <option value="">kein Type</option>
          <option
            v-for="(type, index) in contentType"
            :key="index"
            :value="type"
            >{{ type }}
          </option>
        </select>
      </div>
      <div class="flex justify-between mt-6">
        <GoBackButton />
        <button
          type="submit"
          class="px-4 py-2 bg-gray-800 text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
        >
          Update !
        </button>
      </div>
    </form>
  </div>
  <Alerts ref="alert" :alert-type="this.alertType" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import { languageSelect } from '@/use/languageSelectData'
import { seriesService } from '@/services/Series.service'
import { contentType } from '@/use/contentType'
import { contentResolution } from '@/use/contentResolution'
import { Series } from '@/interfaces/Series'
import GoBackButton from './GoBackButton.vue'
import LoadingSpinner from './lib/LoadingSpinner.vue'
import Alerts from '../components/lib/Alerts.vue'

export default defineComponent({
  name: 'SeriesDataForm',
  components: {
    LoadingSpinner,
    GoBackButton,
    Alerts
  },
  data() {
    const series_id: number = null
    return {
      series_id,
      seriesFormData: {} as Series,
      languageSelect,
      contentType,
      contentResolution
    }
  },
  mounted() {
    this.$refs.loadingSpinner.open()
  },
  created() {
    this.series_id = this.$route.params.series_id
    this.getASeriesData(this.series_id)
  },
  methods: {
    async getASeriesData(series_id: number) {
      console.log(series_id)
      if (series_id) {
        await seriesService
          .getASeriesData(series_id)
          .then(response => {
            this.seriesFormData = ref<Series>({
              title: response.data.title,
              slug: response.data.slug,
              description: response.data.description,
              content_resolution: response.data.content_resolution,
              sort_order: response.data.sort_order,
              content_origin: response.data.content_origin,
              type: response.data.type
            })
            this.$refs.loadingSpinner.close()
            return this.seriesFormData
          })
          .catch(error => {
            this.showErrorAlert(error)
            console.log(error)
          })
      }
    },
    async updateSeriesData() {
      this.$refs.loadingSpinner.open()
      await seriesService
        .updateSeriesData(this.series_id, this.seriesFormData)
        .then(response => {
          this.seriesFormData = ref<Series>({
            title: response.data.title,
            slug: response.data.slug,
            description: response.data.description,
            content_resolution: response.data.content_resolution,
            sort_order: response.data.sort_order,
            content_origin: response.data.content_origin,
            type: response.data.type
          })
          this.$refs.loadingSpinner.close()
          this.showSuccessAlert('Serie wurde upgedatet')
          return this.seriesFormData
        })
        .catch(error => {
          console.log(error)
          this.showErrorAlert(error)
        })
    },
    showSuccessAlert(message: string) {
      this.alertType = 'success'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    },

    showErrorAlert(message: string) {
      this.alertType = 'error'
      this.$refs.alert.open(message)
      setTimeout(() => {
        this.$refs.alert.close()
        this.alertType = null
      }, 5000)
    }
  }
})
</script>

<style scoped></style>
